import React, { useState } from 'react';
import './BlogPage.css';
import LandingHeader from './header';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
// import pdf from '../../assets/pdf/CreativeBriefDoc.pdf'
import pdf1 from '../../assets/pdf/1809BLOGS_01.pdf';
import pdf2 from '../../assets/pdf/1809BLOGS_02.pdf';
import { Viewer, Worker } from '@react-pdf-viewer/core';

const Blogs = () => {
    const [activePdf, setActivePdf] = useState(pdf1);
    return (
        <div className="main-width">
            <LandingHeader className="main-width" />
            <div className="blog-page " style={{ position: 'absolute', top: '68px', width: '100%', fontFamily: 'Futura PT Book', display: 'flex' }}>
                <div className=' flex justify-end'>
                    <div style={{ fontSize: '16px', fontWeight: '600' }}>
                        <h1>Previous Blogs</h1>
                        <p style={{ color: 'blue', textDecoration: 'underline', display: 'grid', marginRight: '25px' }}>
                            <Link to="/10092024" style={{}}>Blogs - (10-09-2024)</Link>
                            <Link to="/11092024" style={{}}>Blogs - (11-09-2024)</Link>
                            <Link to="/12092024" style={{}}>Blogs - (12-09-2024)</Link>
                            <Link to="/12092024" style={{}}>Blogs - (12-09-2024)</Link>
                            <Link to="/13092024" style={{}}>Blogs - (13-09-2024)</Link>
                            <Link to="/16092024" style={{}}>Blogs - (16-09-2024)</Link>
                            <Link to="/17092024" style={{}}>Blogs - (17-09-2024)</Link>
                        </p>
                    </div>
                </div>
                <div className='pdf-viewer'>
                    <p style={{ fontSize: '26px', fontWeight: '700' }}>Updated Blog</p>
                    <div className='button-group'>
                        <button className={`toggle-button ${activePdf === pdf1 ? 'active' : ''}`} onClick={() => setActivePdf(pdf1)}>Blog 1</button>
                        <button className={`toggle-button ${activePdf === pdf2 ? 'active' : ''}`}  onClick={() => setActivePdf(pdf2)}>Blog 2</button>
                    </div>
                    <Worker style={{ width: '100%' }} workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                        <Viewer fileUrl={activePdf} />
                        {/* <Viewer fileUrl={pdf} /> */}
                    </Worker>
                </div>

            </div>
        </div>


    );
};

export default Blogs;
