import { Grid } from "@material-ui/core";
import React from "react";
import './AppHeader.css'
import { ArrowDownward, ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

function HeaderTicker() {
    const companyList = [
        {
            id:1,
            companyShare: 14312314326.2674,
            companyName: "Company Name",
            percent: 25
        },
        {
            id:2,
            companyShare: 5465142.7524,
            companyName: "Company Name",
            percent: 0.5
        },
        {
            id:3,
            companyShare: 56342.7524,
            companyName: "Company Name",
            percent: 7.5
        }, 
        {
            id:4,
            companyShare: 565442.74,
            companyName: "Company Name",
            percent: 2.5
        },
        {
            id:5,
            companyShare: 56342.7524,
            companyName: "Company Name",
            percent: 7.5
        }, 
        {
            id:6,
            companyShare: 565442.74,
            companyName: "Company Name",
            percent: 2.5
        },
        {
            id:7,
            companyShare: 56342.7524,
            companyName: "Company Name",
            percent: 7.5
        }, 
        {
            id:8,
            companyShare: 565442.74,
            companyName: "Company Name",
            percent: 2.5
        },
    ]
    return (
        <Grid className="header-ticker">
            {companyList.map((item) => {
                return (
                    <Grid className="company-details" key={item.id}>
                        <Grid className="company-name">{item.companyName}</Grid>
                        <Grid className="company-symbol">{item.companyShare}</Grid>
                        {/* <Grid className={item.percent > 5 ? 'percent-green ' : 'percent-red'}>
                            {item.percent}%
                            </Grid> */}
                        {item.percent > 5 ?
                            <Grid className="percent-green ">
                                {item.percent}%<ArrowDropUp/>
                            </Grid> :
                            <Grid className="percent-red ">
                                {item.percent}%<ArrowDropDown />
                            </Grid>}
                    </Grid>
                )
            })}
        </Grid>
    )
}
export default HeaderTicker;