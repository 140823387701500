import React from 'react';
import fbIcon from '../../assets/images/facebook.svg';
import linkedInIcon from '../../assets/images/linkedin.svg';
import twitterIcon from '../../assets/images/twitter.svg';
import './footer.css';
import { CallIcon, MessageIcon } from '../../assets/svgIcons/svgIcons';
import { HydIcon } from '../icons/AppIcon';
import { Facebook, Instagram, LinkedIn, Twitter, YouTube } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

export default function SiteFooter() {
    // const handleIconClick = () => {
    //     const url = 'https://www.youtube.com/@marketmaths1113'; // Replace with your desired link
    //     window.open(url, '_blank'); // '_blank' opens the link in a new tab
    // };
    const handleIconClick = (url) => {
        window.open(url, '_blank'); // Opens the URL in a new tab
    };
    return (
        <div className="footer " id='Contact'>
            <div className="footer__top">
                {/* <div className=""> */}
                <div className="footer-details">
                    <ul className="footer__list list-unstyled">
                        <li className=" footer__link--header">Company</li>
                        <li className="footer__link"> Careers </li>
                        <li className="footer__link"> Privacy Policy</li>
                        <li className="footer__link"> Terms & Conditions</li>
                        <li className="footer__link"> Press Enquiries</li>
                    </ul>
                    <div className="footer__list list-unstyled" style={{ maxWidth: '27%' }}>
                        <div className=" footer__link--header">Location</div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '1%' }}>
                            <HydIcon />
                            <ul>
                                <li className="footer__link"> Capital Park </li>
                                <li className="footer__link" > 607, Capital Park, Image Gardens, Madhapur, Hyderabad, Telangana 500081</li>
                            </ul>
                        </div>
                        <div className='join-now'>
                            Join Now
                            <div>
                                <IconButton onClick={() => handleIconClick('https://www.youtube.com/@TradaBullscom')} aria-label="open link" >
                                    <YouTube />
                                </IconButton>
                                <IconButton onClick={() => handleIconClick('https://www.instagram.com/tradabulls/')} aria-label="open link" >
                                    <Instagram />
                                </IconButton>
                                <IconButton onClick={() => handleIconClick('https://www.facebook.com/profile.php?id=61564836036000')} aria-label="open link" >
                                    <Facebook />
                                </IconButton>
                                <IconButton onClick={() => handleIconClick('https://www.linkedin.com/company/tradabulls')} aria-label="open link" >
                                    <LinkedIn />
                                </IconButton>
                                <IconButton onClick={() => handleIconClick('https://x.com/tradabulls')} aria-label="open link" >
                                    <Twitter />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    <ul className="footer__list list-unstyled">
                        <li className=" footer__link--header">Contact Us</li>
                        <li className="footer__link"><span style={{ marginRight: '2px' }}><CallIcon /></span> +91 040-44724242 </li>
                        <li className="footer__link"> <span style={{ marginRight: '4px' }}><MessageIcon /></span>info@tradabulls.com</li>
                    </ul>
                    <div className="footer__list list-unstyled">
                        <div className=" footer__link--header">Get In Touch With Us</div>
                        <div className="touch-withus">
                            <input placeholder='Your Name' className="form-control" id="exampleInputPassword1" />
                            <input placeholder='Mobile Number' className="form-control" id="exampleInputPassword1" />
                        </div>
                        <div className="touch-withus">
                            <textarea placeholder='Your Message' className="form-control" id="exampleFormControlTextarea1" rows="3" />
                        </div>
                        <div>
                            <button type="button" class="submit-button mt-3">Submit Button</button>
                        </div>
                    </div>
                    {/* <ul className="footer__list list-unstyled">
                            <li className=" footer__link--header">CUSTOMER SERVICE</li>
                            <li className="footer__link">
                                <a href="#">Contact Us</a>
                            </li>
                            <li className="footer__link">
                                <a href="#">Ordering & Payment</a>
                            </li>
                            <li className="footer__link">
                                <a href="#">Shipping</a>
                            </li>
                            <li className="footer__link">
                                <a href="#">Returns</a>
                            </li>
                            <li className="footer__link">
                                <a href="#FAQ">FAQ</a>
                            </li>
                        </ul> */}
                    {/* <ul className="footer__list list-unstyled">
                            <li className="footer__link footer__link--header">INFORMATION</li>
                            <li className="footer__link">
                                <a href="#">Work With Us</a>
                            </li>
                            <li className="footer__link">
                                <a href="#">Privacy Policy</a>
                            </li>
                            <li className="footer__link">
                                <a href="#">Terms & Conditions</a>
                            </li>
                            <li className="footer__link">
                                <a href="#">Press Enquiries</a>
                            </li>
                        </ul> */}
                </div>
                {/* <div className="flex">
                    <a>
                        <img className="footer__social-icon" src={linkedInIcon} />
                    </a>
                    <a>
                        <img className="footer__social-icon" src={twitterIcon} />
                    </a>
                    <a>
                        <img className="footer__social-icon" src={fbIcon} />
                    </a>
                </div> */}
                {/* </div> */}
                <div className="footer__bottom ">
                    <div className="w-full flex" style={{justifyContent:'center'}}>
                        <div>
                            {/* Copyright © 2024, Tradabulls */}
                            © 2024 Tradabulls - All Rights Reserved
                        </div>
                        {/* <div className="footer__bottom-links md:flex">
                        <a href="#">Our Terms & conditions</a>
                        <a href="#">Our Privacy Policy</a>
                    </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}