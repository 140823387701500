import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts';
import { Grid, Typography } from '@material-ui/core';

const GaugeChartTF = (props) => {
    const { value, tf, viewPercentages } = props
    const [tfs, setTfs] = useState(props?.tf.length > 0 ? tf : [])
    const [precentages, setPrecentages] = useState(props?.viewPercentages.length > 0 ? viewPercentages : [])
    const chartRef = useRef(null);
    useEffect(() => {
        const calculateGaugeData = () => {
            const meanValue = getMean(viewPercentages);
    
            return [
                {
                    value: viewPercentages[0],
                    name: `${tfs[0]}`,
                    title: {
                        offsetCenter: ['-103%', '90%'],
                        fontSize: "14px",
                        fontFamily: "Futura PT Demi",
                        color: "#000000"
                    },
                    detail: {
                        offsetCenter: ['-103%', '110%'],
                        fontSize: "11px",
                        formatter: () => `${viewPercentages?.length > 0 ? viewPercentages[0] : 0}%`,
                        fontFamily: "Futura PT Demi",
                    },
                    pointer: {
                        length: '50%',
                        width: 7,
                        itemStyle: {
                            color: 'auto',
                            borderWidth: 1,
                            borderColor: '#D3D3D3',
                        },
                    },
                },
                {
                    value: viewPercentages[1],
                    name: `${tfs[1]}`,
                    title: {
                        offsetCenter: ['3%', '90%'],
                        fontSize: "14px",
                        fontFamily: "Futura PT Demi",
                        color: "#000000"
                    },
                    detail: {
                        offsetCenter: ['3%', '110%'],
                        fontSize: "11px",
                        formatter: () => `${viewPercentages?.length > 0 ? viewPercentages[1] : 0}%`,
                        fontFamily: "Futura PT Demi",
                    },
                    pointer: {
                        length: '70%',
                        width: 5,
                        itemStyle: {
                            color: 'auto',
                            borderWidth: 1,
                            borderColor: '#D3D3D3',
                        }
                    }
                },
                {
                    value: viewPercentages[2],
                    name: `${tfs[2]}`,
                    title: {
                        offsetCenter: ['110%', '90%'],
                        fontSize: "14px",
                        fontFamily: "Futura PT Demi",
                        color: "#000000"
                    },
                    detail: {
                        offsetCenter: ['110%', '110%'],
                        fontSize: "11px",
                        formatter: () => `${viewPercentages?.length > 0 ? viewPercentages[2] : 0}%`,
                        fontFamily: "Futura PT Demi",
                    },
                    pointer: {
                        length: '90%',
                        width: 3,
                        itemStyle: {
                            color: 'auto',
                            borderWidth: 1,
                            borderColor: '#D3D3D3',
                        }
                    }
                },
                {
                    value: meanValue,
                    name: getHold(meanValue),
                    title: {
                        offsetCenter: ['0%', '60%'],
                        fontSize: "14px",
                        fontFamily: "Futura PT Demi",
                        color: getColor(meanValue)
                    },
                    detail: {
                        offsetCenter: ['0%', '40%'],
                        fontSize: "11px",
                        formatter: `${meanValue > 0 ? meanValue : 0}%`,
                        fontFamily: "Futura PT Demi",
                    },
                    pointer: {
                        length: '0%',
                        width: 0,
                    }
                }
            ];
        };
    
        const updateChart = () => {
            const dom = chartRef.current;
            const myChart = echarts.init(dom, null, {
                renderer: 'canvas',
                useDirtyRect: false
            });
    
            const gaugeData = calculateGaugeData();
    
            const option = {
                series: [
                    {
                        type: 'gauge',
                        axisLine: {
                            lineStyle: {
                                width: 10,
                                color: [
                                    [0.33, '#da2424'],
                                    [0.67, '#ffb200e3'],
                                    [1, '#339249']
                                ]
                            }
                        },
                        pointer: {
                            itemStyle: {
                                color: 'auto'
                            }
                        },
                        axisTick: {
                            distance: 0,
                            length: 8,
                            lineStyle: {
                                color: '#fff',
                                width: 2
                            }
                        },
                        splitLine: {
                            distance: 0,
                            length: 30,
                            lineStyle: {
                                color: '#fff',
                                width: 4
                            }
                        },
                        axisLabel: {
                            color: 'inherit',
                            distance: 32,
                            fontSize: 0
                        },
                        detail: {
                            valueAnimation: true,
                            formatter: () => `${props?.value ? props?.value : 0}%`,
                            color: 'inherit',
                            fontSize: 8
                        },
                        data: gaugeData
                    }
                ]
            };
    
            myChart.setOption(option);
    
            // Cleanup when component unmounts
            return () => {
                window.removeEventListener('resize', myChart.resize);
                myChart.dispose();
            };
        };
    
        updateChart();
    
        window.addEventListener('resize', chartRef.current?.resize);
    
        return () => {
            window.removeEventListener('resize', chartRef.current?.resize);
        };
    }, [value, viewPercentages]); // Only re-run the effect when value or viewPercentages change
    

  
    const getHold = (v) => {
        if (v <= 16.65) return "Strong Sell"
        else if (v <= 33.33) return "Sell"
        else if (v <= 66.66) return "Hold"
        else if (v <= 83.31) return "Buy"
        else if (v <= 100) return "Strong Buy"
    }

    const getColor = (v) => {
        if (v <= 33.3) return '#da2424'
        else if (v >= 66.6) return '#339249'
        else return '#ffa100e3'
    }

    const getMean = (arr) => {
        let v = (Number(arr[0]) + Number(arr[1]) + Number(arr[2])) / 3
        return Number.isFinite(v) ? v?.toFixed(2) : 0
    }

    return (
        <div>
            <div ref={chartRef} className='-mt-4 -mb-10' style={{ width: '100%', height: '180px' }}></div>
            <Grid className='flex mt-7 w-full'>
                <Typography style={{ color: getColor(viewPercentages?.length > 0 ? viewPercentages[0] : 0) }} className='text-sm font-black font-futura w-1/3' align='center' >{getHold(viewPercentages?.length > 0 ? viewPercentages[0] : 0)}</Typography >
                <Typography style={{ color: getColor(viewPercentages?.length > 0 ? viewPercentages[1] : 0) }} className='text-sm font-black font-futura w-1/3' align='center'>{getHold(viewPercentages?.length > 0 ? viewPercentages[1] : 0)}</Typography >
                <Typography style={{ color: getColor(viewPercentages?.length > 0 ? viewPercentages[2] : 0) }} className='text-sm font-black font-futura w-1/3' align='center'>{getHold(viewPercentages?.length > 0 ? viewPercentages[2] : 0)}</Typography >
            </Grid>
        </div>
    );
};

export default GaugeChartTF;
