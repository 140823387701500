export const baseURL = "https://api.tradabulls.com/userService"
export const tradaAPI = "https://api.tradabulls.com/tradaApi"
export const tickerAPI = "https://ticker.tradabulls.com"

// export const baseURL = "https://dev-api.tradabulls.com/userService"
// export const tradaAPI = "https://dev-api.tradabulls.com/tradaApi"
// export const tickerAPI = "https://ticker.tradabulls.com"

// export const baseURL = "http://localhost:8080/"
// export const tradaAPI = "http://localhost:1045/"
// export const tickerAPI = "http://localhost:4200/"
