import { Button, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SimpleCaptcha, { LoadCanvasTemplate, loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha';
import '../Login/login.css';

const Captcha1 = ({ onChange, ...props }) => {
    const { errors, userForm } = props;
    const [captchaValue, setCaptchaValue] = useState('');
    const [isCaptchaValid, setIsCaptchaValid] = useState(true);

    useEffect(() => {
        loadCaptchaEnginge(4); // Initialize the captcha engine with a length of 6

        return () => {
            userForm.captcha = ""
        }
    }, []);

    const handleCaptchaChange = (e) => {
        setCaptchaValue(e.target.value);
        userForm.captcha = e.target.value
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            props.handleSubmit()
        }
    };
    return (
        <Grid>
            <Grid className="mt-2" style={{display:'flex', justifyContent: 'space-between', alignItems:'center'}}>
                <div
                 style={{ width: '100px !important', height: '30px !important' }}>
                    <LoadCanvasTemplate />
                </div>
                <div style={{display:'grid'}}>
                    <input
                        style={{
                            width: '95px',
                            height: 27,
                            paddingLeft: '10px',
                            border: '1px solid',
                            borderRadius: '4px'
                        }}
                        onKeyDown={handleKeyDown}
                        placeholder='Enter Captcha'
                        id="user_captcha_input" type="text" value={userForm?.captcha}
                        onChange={handleCaptchaChange} />
                    {errors?.captcha?.message ? <span className="mx-2 text-red-600">{errors?.captcha?.message}</span> : null}
                </div>  
                 </Grid>
        </Grid>
    );
};
function Captcha({ onChange, ...props }) {
    const [renderCaptcha, SetRenderCaptcha] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            SetRenderCaptcha(true)
        }, 300);
    }, [])

    return (
        <React.Suspense>
            {renderCaptcha ? <Captcha1 {...props} /> : <span>loading</span>}
        </React.Suspense>
    )
}
export default Captcha;
