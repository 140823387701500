import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const withAuth = (WrappedComponent) => {
    const AuthenticatedComponent = (props) => {
      const history = useHistory();

      const verifyToken = () => {
        const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
        if (token) {
          try {
            const decodedToken = JSON.parse(atob(token.split('.')[1])); // Example decoding, adjust as per your JWT library
            const { exp } = decodedToken;
            if (exp * 1000 > Date.now()) {
              return true; // Token is valid
            } else {
              return false; // Token expired
            }
          } catch (error) {
            return false; // Token decoding failed
          }
        } else {
          return false; // No token found
        }
    }
  
      useEffect(() => {
        if (!verifyToken()) {
          history.push('/');
        }
      }, [history]);
  
      return verifyToken() ? <WrappedComponent {...props} /> : null; // Render the wrapped component if authenticated
    };
  
    return AuthenticatedComponent;
}

export default withAuth