import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { GetHeatmapsObservations } from '../apiService/tickerApiService'
import "./Heatmaps.css"
import { ImportExport } from '@material-ui/icons'
import { getTooltip } from '../../utils'

const objHead = [
    { duration: "Daily", value: "D" },
    { duration: "Weekly", value: "W" },
    { duration: "Monthly", value: "M" },
    { duration: "Quarterly", value: "Q" },
    { duration: "Half Yearly", value: "H" },
    { duration: "Yearly", value: "Y" }
]

function PointsView(props) {
    const { value, h_m_keyForApi, api_res_key } = props

    const [cp_hov, setCp_hov] = useState([]);
    const [diff, setDiff] = useState([]);
    const [data, setData] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [sortDirection, setSortDirection] = useState("desc");
    const [sortby_tf, setSortBy_tf] = useState("");
    const [headValue, setHeadValue] = useState("")

    // const filterValue = (symbol, tf, data) => {
    //     let s = data[tf].filter((e, i) => {
    //         if (e.companySymbol === symbol) {
    //             return e[api_res_key]
    //         }
    //     })
    //     return `${s[0][api_res_key]?.toFixed(2)}%`
    // }

    const filterValue = (symbol, timeFrame, data) => {
        const filteredData = data[timeFrame]?.find(item => item.symbol === symbol);
        return filteredData ? filteredData[api_res_key] : null; // Check for undefined
    };

    const handleHeaderClick = (value) => {
        setHeadValue(value)
        setSortBy_tf(value)
        if (value === sortby_tf) {
            setSortDirection(prevDirection => prevDirection === "asc" ? "desc" : "asc");
        } else {
            setSortDirection("desc");
        }
    };

    const filterCp = (i) => {
        const company = companyList[i]
        const c_m_p = cp_hov[i]
        const differ = diff[i]
        return `${company} ${c_m_p}` //(${differ?.toFixed(2)})
    }

    const setData_hover = (data, tf) => {
        let x = data[tf].map((e, i) => {
            if (e.symbol) {
                return e.symbol
            }
        })
        return x
    }

    const quickSort = (arr, key, direction = "asc") => {

        if (arr.length <= 1) {
            return arr;
        }

        const pivot = arr[Math.floor(arr.length / 2)];
        const left = [];
        const right = [];

        for (let i = 0; i < arr.length; i++) {
            if (i === Math.floor(arr.length / 2)) continue; // Skip the pivot element

            if (direction === "asc") {
                if (arr[i][key] < pivot[key]) {
                    left.push(arr[i]);
                } else {
                    right.push(arr[i]);
                }
            } else {
                if (arr[i][key] > pivot[key]) {
                    left.push(arr[i]);
                } else {
                    right.push(arr[i]);
                }
            }
        }

        return [...quickSort(left, key, direction), pivot, ...quickSort(right, key, direction)];
    };

    const sortingData = (data, tf, direction) => {
        // let sortedData = data[tf].sort((a, b) => {
        //     if (direction === "asc") {
        //         return a[api_res_key] - b[api_res_key];
        //     } else {
        //         return b[api_res_key] - a[api_res_key];
        //     }
        // })

        const sortedData = quickSort(data[tf], api_res_key, direction)
            .map((e, i) => {
                switch (tf) {
                    case 'D':
                        return {
                            companySymbol: e.symbol, daily: e[api_res_key], weekly: filterValue(e.symbol, "W", data), monthly: filterValue(e.symbol, "M", data),
                            quarterly: filterValue(e.symbol, "Q", data), halfYearly: filterValue(e.symbol, "H", data), yearly: filterValue(e.symbol, "Y", data)
                        };
                    case 'W':
                        return {
                            companySymbol: e.symbol, daily: filterValue(e.symbol, "D", data), weekly: `${e[api_res_key]}`, monthly: filterValue(e.symbol, "M", data),
                            quarterly: filterValue(e.symbol, "Q", data), halfYearly: filterValue(e.symbol, "H", data), yearly: filterValue(e.symbol, "Y", data)
                        };
                    case 'M':
                        return {
                            companySymbol: e.symbol, daily: filterValue(e.symbol, "D", data), weekly: filterValue(e.symbol, "W", data), monthly: `${e[api_res_key]}`,
                            quarterly: filterValue(e.symbol, "Q", data), halfYearly: filterValue(e.symbol, "H", data), yearly: filterValue(e.symbol, "Y", data)
                        };
                    case 'Q':
                        return {
                            companySymbol: e.symbol, daily: filterValue(e.symbol, "D", data), weekly: filterValue(e.symbol, "W", data), monthly: filterValue(e.symbol, "M", data),
                            quarterly: `${e[api_res_key]}`, halfYearly: filterValue(e.symbol, "H", data), yearly: filterValue(e.symbol, "Y", data)
                        };
                    case 'H':
                        return {
                            companySymbol: e.symbol, daily: filterValue(e.symbol, "D", data), weekly: filterValue(e.symbol, "W", data), monthly: filterValue(e.symbol, "M", data),
                            quarterly: filterValue(e.symbol, "Q", data), halfYearly: `${e[api_res_key]}`, yearly: filterValue(e.symbol, "Y", data)
                        };
                    case 'Y':
                        return {
                            companySymbol: e.symbol, daily: filterValue(e.symbol, "D", data), weekly: filterValue(e.symbol, "W", data), monthly: filterValue(e.symbol, "M", data),
                            quarterly: filterValue(e.symbol, "Q", data), halfYearly: filterValue(e.symbol, "H", data), yearly: `${e[api_res_key]}`
                        };
                    default:
                        return data;
                }
            });

        return sortedData;
    };

    const fetchPercentageValue = async () => {
        const fetchApiGC = await GetHeatmapsObservations(value, h_m_keyForApi)

        if (fetchApiGC?.data?.success) {
            const data = fetchApiGC?.data?.data;
            let sortedData, companyListData, diffData, cpData;

            const handleSorting = (timeframe) => {
                sortedData = sortingData(data, timeframe, sortDirection);
                companyListData = setData_hover(data, timeframe);
                // diffData = data[timeframe].map(e => e[api_res_key]);
                cpData = data[timeframe].map(e => e.currentMarketPrice?.toFixed(2));
            };
            switch (sortby_tf) {
                case "D": handleSorting("D");
                    break;
                case "W": handleSorting("W");
                    break;
                case "M": handleSorting("M");
                    break;
                case "Q": handleSorting("Q");
                    break;
                case "H": handleSorting("H");
                    break;
                case "Y": handleSorting("Y");
                    break;
                default:
                    companyListData = data?.D.map(e => e.symbol);
                    cpData = data?.D.map(e => e.currentMarketPrice);
                    // diffData = data?.D.map(e => e.current_range);

                    sortedData = [];
                    const formatData = (arr) => arr?.map(e =>
                        Object.values(e).length ? `${(e[api_res_key])}` : null
                    );

                    const datasets = ['D', 'W', 'M', 'Q', 'H', 'Y'].map(period => formatData(data?.[period]));
                    datasets[0].forEach((_, i) => {
                        sortedData.push({
                            companySymbol: companyListData[i], daily: datasets[0][i], weekly: datasets[1][i], monthly: datasets[2][i],
                            quarterly: datasets[3][i], halfYearly: datasets[4][i], yearly: datasets[5][i]
                        });
                    });
                    break;
            }

            setCp_hov(cpData);
            // setDiff(diffData);
            setCompanyList(companyListData);
            setData(sortedData);

        }
    }

    useEffect(() => {
        let intervalId;
        if (value) {
            intervalId = setInterval(() => {
                fetchPercentageValue(value)
            }, 2000)
        }
        return () => {
            setData([])
            clearInterval(intervalId)
        }
    }, [value, h_m_keyForApi, sortby_tf, sortDirection])

    useMemo(() => {
        if (value) {
            fetchPercentageValue(value)
        }
    }, [value])

    return (
        <>
            <Box className='px-2'>
                <TableContainer style={{ borderRadius: "10px", height: `calc(100vh - 120px)`, padding: '0px 10px' }}>
                    <Table className="table" stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell className="headerCell p-0" >
                                    <Grid className="flex justify-center top-container">
                                        <Typography align='center font-black' style={{ color: "#ffffff" }}>Counter</Typography>
                                    </Grid>
                                </TableCell>
                                {objHead.map((e, i) => {
                                    return (<>
                                        <TableCell key={i} className="headerCell p-0">
                                            <Grid container direction="column">
                                                <Grid className="flex top-container justify-around" style={{
                                                    background: `${headValue === e.value ? sortDirection == "desc" ? `linear-gradient(45deg, #339249, transparent)` : `linear-gradient(45deg, #e26464, transparent)` : '#154A72'}`,
                                                    color: `${headValue === e.value ? sortDirection == "desc" ? 'black' : 'black' : '#fff'}`,
                                                }} onClick={() => handleHeaderClick(e.value)} >
                                                    <Tooltip title={getTooltip(e.duration)} arrow placement="top">
                                                        <Typography align='center' className="timeDur">{e.duration}</Typography>
                                                    </Tooltip>
                                                    <Grid className='flex flex-col'>
                                                        <ImportExport style={{
                                                            color: `${headValue === e.value ? sortDirection == "desc" ? 'green' : 'red' : '#fff'}`
                                                        }} />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.length ? data.map((item, index) => {
                                return (
                                    <TableRow key={index} className='tableCell'>
                                        <Tooltip title={filterCp(index)} arrow placement="top">
                                            <TableCell className='tableCell text-center font-black p-1' >
                                                <Typography style={{ color: "#154A72" }} align='center'>{item?.companySymbol}</Typography>
                                            </TableCell>
                                        </Tooltip>
                                        <Tooltip title={filterCp(index)} arrow placement="top">
                                            <TableCell className='tableCell text-center font-black p-1' >
                                                <Typography style={{ color: item?.daily > 0 ? "#339249" : item?.daily == 0 ? "#4D8FCC" : "#DA2424", }} align='center'>{item?.daily > 0 ? `+${item?.daily}` : item?.daily}</Typography>
                                            </TableCell>
                                        </Tooltip>
                                        <Tooltip title={filterCp(index)} arrow placement="top">
                                            <TableCell className='tableCell text-center font-black p-1' >
                                                <Typography style={{ color: item?.weekly > 0 ? "#339249" : item?.weekly == 0 ? "#4D8FCC" : "#DA2424", }} align='center'>{item?.weekly > 0 ? `+${item?.weekly}` : item?.weekly}</Typography>
                                            </TableCell>
                                        </Tooltip>
                                        <Tooltip title={filterCp(index)} arrow placement="top">
                                            <TableCell className='tableCell text-center font-black p-1' >
                                                <Typography style={{ color: item?.monthly > 0 ? "#339249" : item?.monthly == 0 ? "#4D8FCC" : "#DA2424", }} align='center'>{item?.monthly > 0 ? `+${item?.monthly}` : item?.monthly}</Typography>
                                            </TableCell>
                                        </Tooltip>
                                        <Tooltip title={filterCp(index)} arrow placement="top">
                                            <TableCell className='tableCell text-center font-black p-1' >
                                                <Typography style={{ color: item?.quarterly > 0 ? "#339249" : item?.quarterly == 0 ? "#4D8FCC" : "#DA2424", }} align='center'>{item?.quarterly > 0 ? `+${item?.quarterly}` : item?.quarterly}</Typography>
                                            </TableCell>
                                        </Tooltip>
                                        <Tooltip title={filterCp(index)} arrow placement="top">
                                            <TableCell className='tableCell text-center font-black p-1' >
                                                <Typography style={{ color: item?.halfYearly > 0 ? "#339249" : item?.halfYearly == 0 ? "#4D8FCC" : "#DA2424", }} align='center'>{item?.halfYearly > 0 ? `+${item?.halfYearly} ` : item?.halfYearly}</Typography>
                                            </TableCell>
                                        </Tooltip>
                                        <Tooltip title={filterCp(index)} arrow placement="top">
                                            <TableCell className='tableCell text-center font-black p-1' >
                                                <Typography style={{ color: item?.yearly > 0 ? "#339249" : item?.yearly == 0 ? "#4D8FCC" : "#DA2424", }} align='center'>{item?.yearly > 0 ? `+${item?.yearly}` : item?.yearly}</Typography>
                                            </TableCell>
                                        </Tooltip>
                                    </TableRow>
                                );
                            }) : <p>0</p>}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
}

export default PointsView