import dayjs from 'dayjs';
import React, { useRef, useState } from 'react';
import Chart from 'react-apexcharts';

const hregex = /H\d+/;
const lregex = /L\d+/;

const CandleStickChart30min = (props) => {
    const { yAxisValues, graphData, keys, data } = props;
    const [darkMode, setDarkMode] = useState(false);
    const containerRef = useRef(null);

    const getColor = (index, k_i) => {
        let color = hregex.test(k_i) ? "#339249" : lregex.test(k_i) ? "#DA2424" : "#4D8FCC";
        return color;
    }

    function getValuesByPattern(data, pattern) {
        const result = Object.keys(data).filter(key => key.startsWith(pattern)).reduce((acc, key) => {
                acc[key] = data[key];
                return data[key];
            }, {});
        return result;
    }

    const getBorderWidth = (d,v) => {
        let r2 = Math.max(getValuesByPattern(d,"SPH1"),getValuesByPattern(d,"FPH2"))
        let r1 = Math.min(getValuesByPattern(d,"SPH1"),getValuesByPattern(d,"FPH2"))
        let s1 = Math.max(getValuesByPattern(d,"SPL1"),getValuesByPattern(d,"FPL2"))
        let s2 = Math.min(getValuesByPattern(d,"SPL1"),getValuesByPattern(d,"FPL2"))
        if(r2 == v || r1 == v || s1 == v || s2 == v) return "5px"
        return "2px"
    }

    const getYaxisArray = (a, k,d) => {
        const containerWidth = containerRef.current ? containerRef.current.offsetWidth : 250; // Default width if container is not measured
        const percentageOffsetX = 0.2 * containerWidth; // e.g., 10% of the container width
        return a?.map((value, i) => ({
            y: value,
            borderWidth: getBorderWidth(d,value),
            borderColor: getColor(i, k[i]),
            strokeDashArray: 0,
            label: {
                borderColor: '#00E396',
                borderWidth: "0px",
                style: {
                    display: "none",
                    fontSize: '10px',
                    fontWeight : "bold",
                    color: getColor(i, k[i]),
                    background: 'transparent', // Ensure background is transparent
                },
                orientation: 'horizontal',
                offsetX: percentageOffsetX,
                offsetY: 7,
                text: value?.toFixed(1)
            }
        }));
    }

    const options = {
        chart: {
            type: 'candlestick',
            height: 350,
            background: darkMode ? '#1e1e1e' : '#000',
            toolbar: {
                show: false
            }
        },
        title: {
            text: '30 Mins',
            align: 'center',
            style: {
                color: darkMode ? '#fff' : '#fff', // Title color
                fontFamily: "Futura PT Book",
            },
        },
        xaxis: {
            type: 'datetime',
            labels: {
                style: {
                    colors: darkMode ? '#fff' : '#fff', // X-axis label color
                },
                formatter: function (val) {
                    return dayjs(val).format('HH:mm');
                }
            },
        },
        yaxis: {
            labels: {
                show: true, // Ensure y-axis labels are visible
                style: {
                    colors: darkMode ? '#fff' : '#000', // Y-axis label color
                    background: 'transparent', // Ensure background is transparent
                },
            },
            axisBorder: {
                show: false  // Hide y-axis border
            },
            axisTicks: {
                show: false  // Hide y-axis ticks
            },
            tooltip: {
                enabled: true,
            },
            lines: {
                show: false
            },
        },
        grid: {
            show: false,
            padding: {
                left: -60, // Adjust left padding if needed
                right: 50 // Adjust right padding if needed
            }
        },
        annotations: {
            yaxis: getYaxisArray(yAxisValues, keys,data)
        },
        tooltip: {
            enabled: true,
            shared: true,
            intersect: false,
            followCursor: true,
            theme: darkMode ? 'dark' : 'light',
            x: {
                show: true,
            },
            y: {
                show: true,
            },
        },
    };

    const series = [
        {
            data: graphData
        },
    ];

    return (
        <>
            <div>
                {graphData?.length > 0 &&
                    <Chart options={options} series={series} type="candlestick" height={415} />
                }
            </div>
        </>
    );
};

export default CandleStickChart30min;
