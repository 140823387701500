import { validateCaptcha } from "react-simple-captcha";
import { emailRegex, passwordRegex, phoneNumberRegex } from "../../utils/index";

export default function  validateSignUp(values) {
  let errors = {
    firstName: {},
    lastName: {},
    email: {},
    phoneNumber: {},
    password: {},
    reEnterPassword : {},
    captcha:{}
  };
  if (!values.firstName) {
    errors.firstName.message = "Enter your firstname";
  } 
  // else if(!nameRegex.test(values.firstName)){
  //   errors.firstName.message = "First Name is invalid";
  // }
  if (!values.lastName) {
    errors.lastName.message = "Enter your lastname";
  } 
  // else if(!nameRegex.test(values.firstName)){
  //   errors.lastName.message = "last Name is invalid";
  // }
  if (!values.email) {
    errors.email.message = "Enter your email address";
  } else if (!emailRegex.test(values.email)) {
    errors.email.message = "Email address is invalid";
  }
  if (!values.phoneNumber) {
    errors.phoneNumber.message = "Enter your phone number";
  } else if (!phoneNumberRegex.test(values.phoneNumber)) {
    errors.phoneNumber.message = "Phone number is invalid";
  }
  if (!values.password) {
    errors.password.message = "Enter your password";
  } else if (!passwordRegex.test(values.password)) {
    errors.password.message = "Password should be min 8 characters having one number,one capital letter and one special character";
  }
  if (!values.reEnterPassword) {
    errors.reEnterPassword.message = "Confirm your password";
  } else if (values.reEnterPassword !== values.password) {
    errors.reEnterPassword.message = "Password does not matched";
  }
  if (!values.captcha) {
    errors.captcha.message = "Captcha is required";
    values.captcha = ""
  }
  else if (!validateCaptcha(values.captcha)) {
    errors.captcha.message = "Invalid Captcha Code";
    values.captcha = ""
  }

  return errors;
}
