import React from 'react';
import { Comming } from '../icons/AppIcon';
import { Typography } from '@material-ui/core';

function CommingSoon() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
            <div>
                <Comming />
                <Typography align='center' variant='h4' style={{fontWeight:'bold'}}>COMING SOON</Typography>
                <p style={{fontWeight:'bold'}}>We're working hard to bring you an amazing new site. Stay tuned for updates!</p>
            </div>
        </div>
    )
}

export default CommingSoon;
