import { Box, Grid, Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import React, { useState } from 'react'
import GraphAndPieChart from './GraphAndPieChart';
import IndexMoversFutures from './IndexMoversFutures';
import NiftyPlus from './NiftyPlus';
import NSEPlus from './NsePlus';
import NSEPlusPlus from './NSEPlusPlus';

function Indeices(props) {
    const { tabsList } = props
    const [value, setValue] = useState('9');
    const [label, setLabel] = useState(tabsList[0].label);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        const selectedTab = tabsList.find(e => e.value === newValue);
        setLabel(selectedTab ? selectedTab.label : tabsList[0].label);
        // fetchData(filterToken(newValue), indexTime); // Fetch data on tab change
    };

    const getIndice = (v) => {
        switch (v) {
            case "0": return "nifty50"
            case "1": return "banknifty"
            case "2": return "itnifty"
            case "3": return "next50"
            case "4": return "finnifty"
            case "5": return "midcapselect"
            case "6": return "0"
            case "7": return "0"
            case "8": return "0"
            case "9": return "0"
            default: return "0";
        }
    }

    return (
        <Grid className="im-container">
            <TabContext value={value}>
                <Box className="im-tablist" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        {tabsList?.map((e, i) => (
                            <Tab label={e?.label} value={e?.value} style={{ minWidth: "fit-content" }} />
                        ))}
                    </TabList>
                </Box>
                <TabPanel value="0" className='px-4 w-full' style={{ paddingTop: "3.2rem" }}>
                    <GraphAndPieChart indice={getIndice("0")} label={label} />
                </TabPanel>
                <TabPanel value="1" className='px-4 w-full' style={{ paddingTop: "3.2rem" }}>
                    <GraphAndPieChart indice={getIndice("1")} label={label} />
                </TabPanel>
                <TabPanel value="2" className='px-4 w-full' style={{ paddingTop: "3.2rem" }}>
                    <GraphAndPieChart indice={getIndice("2")} label={label} />
                </TabPanel>
                <TabPanel value="3" className='px-4 w-full' style={{ paddingTop: "3.2rem" }}>
                    <GraphAndPieChart indice={getIndice("3")} label={label} />
                </TabPanel>
                <TabPanel value="4" className='px-4 w-full' style={{ paddingTop: "3.2rem" }}>
                    <GraphAndPieChart indice={getIndice("4")} label={label} />
                </TabPanel>
                <TabPanel value="5" className='px-4 w-full' style={{ paddingTop: "3.2rem" }}>
                    <GraphAndPieChart indice={getIndice("5")} label={label} />
                </TabPanel>
                <TabPanel value="6" className='px-4 w-full' style={{ paddingTop: "3.2rem" }}>
                    <IndexMoversFutures />
                </TabPanel>
                <TabPanel value="7" className='px-4 w-full' style={{ paddingTop: "3.2rem" }}>
                    <NiftyPlus />
                </TabPanel>
                <TabPanel value="8" className='px-4 w-full' style={{ paddingTop: "3.2rem" }}>
                    <NSEPlus />
                </TabPanel>
                <TabPanel value="9" className='px-4 w-full' style={{ paddingTop: "3.2rem" }}>
                    <NSEPlusPlus />
                </TabPanel>
            </TabContext>
        </Grid>
    )
}

export default Indeices