import {
    withStyles,
    createStyles,
    InputBase,
    Theme,
    FormControl,
    FormHelperText,
  } from '@material-ui/core';
  import React from 'react';
  import OutlinedInput from '@material-ui/core/OutlinedInput';
  
  import {  InputLabel } from '@material-ui/core';
  import StarIcon from '@material-ui/icons/Star';
  import { ErrorMessage } from '@hookform/error-message';
  
  export const BootstrapInput = withStyles((theme) =>
    createStyles({
      root: {
        'label + &': {
          marginTop: theme.spacing(3),
        },
      },
      input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        fontSize: 14,
        padding: '.6rem .7rem',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
        ].join(','),
      },
    })
  )(InputBase);

  // TODO: mutiselect need to be implemented
  function MatCustomInput(props) {
    const {
      labelText = null,
      errors = {},
      classes = {},
      isRequired = false,
      ...baseInputProps
    } = props;
  
    return (
      <div className={`${classes.root ?? 'mt-6'}`}>
        {labelText ? (
          <InputLabel
            className={`text-sm font-medium mb-2 ${classes?.inputLabel}`}
          >
            {labelText}
            {isRequired ? (
              <StarIcon
                className={`text-xs pb-1 text-red-600 ${classes.requiredIcon}`}
              />
            ) : null}
          </InputLabel>
        ) : null}
        <FormControl className={classes.formControl} size="small" fullWidth variant="outlined">
          <OutlinedInput
            classes={{
              input:classes.input
            }}
            {...baseInputProps}
            labelWidth={0}
          />
  
          <ErrorMessage
            render={({ message }) => (
              <FormHelperText className="mx-2 text-red-600">
                {message}
              </FormHelperText>
            )}
            errors={errors}
            name={props.name}
          />
        </FormControl>
      </div>
    );
  }
  export default React.memo(MatCustomInput);
  