import React, { useState } from 'react';
import {
  FormControlLabel,
  Checkbox,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { ErrorMessage } from '@hookform/error-message';
import {  useFormContext } from 'react-hook-form';


// TODO: mutiselect need to be implemented
function MatCheckbox(props) {
  const { register, errors, watch } = useFormContext();
  const {
    labelText = null,
    isRequired = false,
    checkboxesList,
    name,
    classes = {
      root: 'mt-6',
    },
    ...otherProps
  } = props;
  const watchValue = watch(name);

  return (
    <div className={`${classes.root ?? 'mt-6'}`}>
      {labelText ? (
        <InputLabel
          className={`text-sm font-medium mb-2 ${classes.inputLabel}`}
        >
          {labelText}
          {isRequired ? (
            <StarIcon
              className={`text-xs pb-1 text-red-600 ${classes.requiredIcon}`}
            />
          ) : null}
        </InputLabel>
      ) : null}
      {checkboxesList.map((option, ind) => (
        <FormControlLabel
          className={classes.formControl}
          key={`key${ind}`}
          name={`${name}[${ind}]`}
          inputRef={register}
          control={
            <Checkbox
              {...otherProps}
              checked={watchValue[ind] == option.value}
              value={option.value}
              color="primary"
            />
          }
          label={option.display}
        />
      ))}

      <ErrorMessage
        render={({ message }) => (
          <FormHelperText className="mx-0 text-red-600">
            {message}
          </FormHelperText>
        )}
        errors={errors}
        name={name}
      />
    </div>
  );
}

export default React.memo(MatCheckbox);
