import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { BootstrapInput } from './InputField';
import StarIcon from '@material-ui/icons/Star';
import { ErrorMessage } from '@hookform/error-message';
import { FormHelperText } from '@material-ui/core';
import { useEffect } from 'react';


const MatSelect = React.forwardRef((props, ref) => {
  const {
    labelText = null,
    isRequired = false,
    optionsList,
    error = {},
    classes = {},
    cb,
    useKey,
    ...selectProps
  } = props;

  useEffect(() => {
    if ('multiple' in props && !selectProps.defaultValue) {
      selectProps.defaultValue = [];
    }
    if (!('placeholder' in selectProps)) {
      selectProps.placeholder = '--Select option--';
    }
  }, []);

  return (
    <div className={classes?.root || 'mt-6'}>
      {labelText ? (
        <InputLabel
          className={`text-sm font-medium mb-2 ${classes?.inputLabel}`}
        >
          {labelText}
          {isRequired ? (
            <StarIcon
              className={`text-xs pb-1 text-red-600 ${classes.requiredIcon}`}
            />
          ) : null}
        </InputLabel>
      ) : null}
      <FormControl fullWidth className={`input-border ${classes.formControl}`}>
        <Select
          className={classes.select}
          {...selectProps}
          ref={ref}
          inputRef={ref}
          input={
            <BootstrapInput
              placeholder={props.placeholder}
              classes={{
                input:classes.input
              }}
            />
          }
        >
          {optionsList.map((opt, index) => (
            <MenuItem
              itemType={selectProps.type}
              key={index}
              value={opt.value}
            >
              {opt.display}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <ErrorMessage
        render={({ message }) => (
          <FormHelperText className="mx-0 text-red-600">
            {message}
          </FormHelperText>
        )}
        errors={error}
        name={props.name}
      />
    </div>
  );
});

export default MatSelect;
