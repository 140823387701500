import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { InputLabel, FormHelperText } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { ErrorMessage } from '@hookform/error-message';


const MatRadioButton = React.forwardRef((props, ref) => {
  const {
    labelText,
    isRequired = false,
    error = {},
    classes = {},
    options,
    disabled = false,
    ...radioGroupProps
  } = props;

  return (
    <div className={classes.root || 'mt-6'}>
      {labelText ? (
        <InputLabel
          id={props.name}
          className={`text-sm font-medium ${classes?.inputLabel}`}
        >
          {labelText}
          {isRequired ? (
            <StarIcon
              className={`text-xs pb-1 text-red-600 ${classes.requiredIcon}`}
            />
          ) : null}
        </InputLabel>
      ) : null}
      <FormControl fullWidth className={`${classes.formControl}`} disabled={disabled}>
        <RadioGroup
          {...radioGroupProps}
          ref={ref}
          row
          className={classes.radioGroup}
        >
          {options.map((opt) => (
            <FormControlLabel
              color="primary"
              value={String(opt.value)}
              key={String(opt.value)}
              control={<Radio size="small" color="primary" />}
              label={opt.display}
            />
          ))}
        </RadioGroup>
        <ErrorMessage
          render={({ message }) => (
            <FormHelperText className="mx-0 text-red-600">
              {message}
            </FormHelperText>
          )}
          errors={error}
          name={props.name}
        />
      </FormControl>
    </div>
  );
});
export default React.memo(MatRadioButton);
