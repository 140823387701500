import { Grid } from "@material-ui/core";
import React from "react";
import "./Homepage.css"

function TableTicker() {

    return (
        <Grid>
            <Grid className="table-ticker">
                <Grid className="details">
                    <Grid className="details-heading mb-1">Reliance Industries Ltd</Grid>
                    <Grid className="details-context">RELIANCE</Grid>
                </Grid>
                <Grid>
                    <Grid className="details-heading mb-1 mb-1">Current Price</Grid>
                    <Grid className="pricing">2,395.62</Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TableTicker;