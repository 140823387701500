import React from 'react';
import './BlogPage.css';
import image1 from '../../assets/images/n5.png';
import image2 from '../../assets/images/meter.png';
import image3 from '../../assets/images/daily.png';
import image4 from '../../assets/images/itmeter.png';
import image5 from '../../assets/images/banknifty.png';
import LandingHeader from './header';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Day1Blog = () => {
    const history = useHistory();
    const handleNavigate = (path) => {
        history.push(path);
        window.scrollTo({ top: 0, behavior: 'smooth' });  // Scroll to top with smooth behavior
        // setShowSidebar(false);  // Optionally close the sidebar after navigation
        // setActiveTab(path);
      };
    return (
        <div>
            {/* <LandingHeader /> */}
            <button onClick={() => handleNavigate("/blogs")} style={{
                marginTop: '20px',
                padding: '10px 20px',
                backgroundColor: '#007bff',
                color: '#fff',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                fontSize: '16px'
            }}>
                Back to Today's Blog
            </button>
            <div className="blog-page" style={{ position: 'absolute', top: '68px', width: '100%', fontFamily: 'Futura PT Book' }}>
            <p style={{ fontSize: '16px', fontWeight: '700' }}>10-09-2021 Blog</p>
                <header className="blog-header">
                    <h1>Nifty Closes  above 25,000 Mark : IT Sector Surge and Market Trends to Watch</h1>
                </header>

                {/* First Section - Image on the right */}
                <section className="blog-section reverse">
                    <img src={image1} alt="Nifty 50" className="blog-image" style={{ width: '300px' }} />
                    <div className="blog-content">
                        <h2>Nifty 50 Resilient Despite Sector Challenges</h2>
                        <p>
                            Nifty opened 63 points gap up and broke the important level 25,000 and faced daily resistance around the level of 25,011 in the morning session.
                            From there Nifty dipped till 24,900.
                        </p>
                        <p>
                            The strong support from Nifty-IT and Nifty-Bank lead the index to bounce back from 24,900 to 25,130 level, i.e 61.8% of last week's Fibonacci Retracement & Extension value as mentioned in the TradaBulls analysis.
                        </p>
                        <p>
                            Based on Today's close, Nifty may retest All Time High - 25,333 once again.Major Contributors for Nifty are Bharti Airtel, INFY, TCS and Axis Bank.
                        </p>


                    </div>
                </section>

                {/* Second Section - Image on the left */}
                <section className="blog-section">
                    <img src={image2} alt="IT Nifty" className="blog-image" style={{ width: '300px' }} />
                    <div className="blog-content">
                        <h2>BANK NIFTY</h2>
                        <p>
                            None of the 30 mins candle closed below the 51000, which is daily CPR level and strong support for Nifty-Bank.
                            Out of 22 observations as per the Trade Bulls analysis,15 are towards positive side for both daily and weekly, that indicates the positive trend for Nifty-Bank.

                            Major Contributor from the Nifty-Bank was Axis
                        </p>
                    </div>
                </section>

                {/* Third Section - Image on the right */}
                <section className="blog-section reverse">
                    <img src={image3} alt="Bank Nifty" className="blog-image" style={{ width: '420px' }} />
                    <div className="blog-content">
                        <h2> NIFTY IT</h2>
                        <p>
                            IT Nifty opened with a Gap up of 209 points and above 50% daily Fibonacci level.
                            Took resistance from weekly pivot point  42345,42373 And support from daily pivot point.
                            Nifty IT closed above weekly 23.6% fibonacci and monthly pivot point from there nifty IT took positive trend and formed continuous game changer candles.
                            MOVER→SEBI lifted restrictions on INFY insider trading on 16 entities led to a boom in the IT sector.IT which ended on a negative note on Monday can be seen the contrary where all 10/10 counters are trading on a positive note. INFY being the highest contributor is shown as a strong buy in our application for both day and week time platforms.
                        </p>
                    </div>
                </section>

                {/* Fourth Section - Image on the left */}
                <section className="blog-section">
                    <img src={image4} alt="GST Council" className="blog-image" style={{ width: '420px' }} />
                    <div className="blog-content">
                        <h2>  MIDCAP</h2>
                        <p>

                            Mid cap opened-96 points-gap up, traded in between Weekly support and resistance values and candles are closing within the CPR.resistance from weekly +1 zone & weekly pivot,support from monthly pivot.

                        </p>
                    </div>
                </section>

                {/* Fifth Section - Image on the right */}
                <section className="blog-section reverse">
                    {/* <img src={image5} alt="SBI Downgrade" className="blog-image" /> */}
                    <div className="blog-content">
                        <h3>SECTOR TO WATCH
                        </h3>
                        <ul>
                            <li>The auto sector in the NIFTY index is underperforming, largely due to the impact of festival promotions and discounts. This has led to lower profitability and a temporary dip in stock prices. Major companies like Tata Motors, Hero MotoCorp, and Mahindra & Mahindra (M&M) are notably affected, possibly due to decreased demand or increased competition.
                            </li>
                            <li>The Fin Nifty index, which includes financial stocks, had positive contributions primarily from the banking sector. This suggests that banks are performing well and boosting the overall financial sector index.</li>
                        </ul>
                    </div>
                </section>

                <footer className="blog-footer">
                    <h3>OVERVIEW
                    </h3>
                    {/* <ul> */}
                    <p>  Nifty closed (25040) above the Monthly CPR top level (25018), and today's high was 25130, which is at 61.8% of the weekly Fibonacci retracement. If tomorrow Nifty maintains any 30-minute candle close above this level(25130), it could re-test its all-time high of 25,333.
                    </p>
                    {/* </ul> */}
                </footer>
            </div>
        </div>


    );
};

export default Day1Blog;
