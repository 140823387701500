import { Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { getColorPr, getCurrentDate, getCurrentMonth, getCurrentWeek, getDateDurationFromQuarterOfYear, getDateDurationFromYearStartToEnd, getDateDurationFromYearStartToHalf } from '../../utils'
import { toast } from 'react-toastify';
import { companyApiData } from '../apiService/tradaApiService';
import { BorderTop } from '@material-ui/icons';
import SingleCandleStick from '../common/SingleCandleStick';
import { GetObservationsForCandle } from '../apiService/tickerApiService';
import MetricCandleStick from '../common/MetricCandleStick';
import GaugeChartForDatatable from '../common/GaugeChartForDatatable';


const timeFrames = [
  { frame: "Daily" },
  { frame: "Weekly" },
  { frame: "Monthly" },
  { frame: "Quarterly" },
  { frame: "Half Yearly" },
  { frame: "Yearly" },
]

const hregex = /H\d+/;
const lregex = /L\d+/;

const notify = (sev, str) => toast[sev ? sev : 'info'](str);

const getTooltip = (e) => {
  switch (e) {
    case "Yearly":
      let stry = getDateDurationFromYearStartToEnd()
      return stry
    case "Half Yearly":
      let strhy = getDateDurationFromYearStartToHalf()
      return strhy
    case "Quarterly":
      let strq = getDateDurationFromQuarterOfYear()
      return strq
    case "Monthly":
      let strm = getCurrentMonth()
      return strm
    case "Weekly":
      let strw = getCurrentWeek()
      return strw
    case "Daily":
      let strd = getCurrentDate()
      return strd
    default:
      return e
  }
}

const useStyles = makeStyles((theme) => ({
  headerCell: {
    border: '1px solid white',
    color: '#333333',
    textAlign: 'start',
    // width : "200px",
    padding: "0px",
    paddingBottom: "0px"
  },
  tableCell: {
    border: '1px solid white',
    textAlign: 'center',
    color: "#ffffff",
    fontWeight: "600",
    padding: "4px"
  },
  table: {
    border: '0px solid',
    width: '100%',
    borderCollapse: 'collapse',
  },
  topContainer: {
    // display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#154A72",
    padding: "6px 5px"
  },
  timeDur: {
    fontFamily: 'Futura PT Book',
    // height: "30px",
    color: "#ffffff",
    fontWeight: 'bold',
    fontSize: "15px",
    // display: "flex",
    // width:"100%",
    alignItems: "center",
    transform: "all 1s ease-in-out",
    '&:hover': {
      transform: "scale(1.15)"
    },
  },
  greenPerc: {
    color: '#03781E',
    fontWeight: "bold",
    fontSize: "12px",
    backgroundColor: '#fff',
    borderRadius: "5px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    height: "17px",
    padding: '0px 5px'
  },
  redPerc: {
    color: '#C33E3E',
    fontWeight: "bold",
    fontSize: "12px",
    backgroundColor: '#fff',
    borderRadius: "5px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    height: "fit-content",
    padding: '0px 5px'
  },
  high: {
    backgroundColor: "#DCE9F3",
    width: "100%",
    borderRight: "1px solid white",
    paddingTop: "5px",
    borderTop: '1px solid #fff'
  },
  highFont: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#339249",
    fontFamily: 'Futura PT Book',
  },
  low: {
    backgroundColor: "#DCE9F3",
    borderTop: '1px solid #fff',
    width: "100%",
    borderRight: "1px solid white",
    paddingBottom: "3px",
    paddingTop: "3px"
  },
  lowFont: {
    fontFamily: 'Futura PT Book',
    fontSize: "12px",
    fontWeight: "bold",
    color: "#DA2424"
  },
  prediction: {
    fontFamily: 'Futura PT Book',
    backgroundColor: "#DCE9F3",
    fontSize: "12px",
    fontWeight: "bold",
    color: "#444444",
    display: 'flex',
    justifyContent: 'center',
    borderBottom: '6px solid #154A72 !important'
  },
  num: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#000000"
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center"
  },
  lastValuesInHeader: {
    backgroundColor: "#DCE9F3",
    fontFamily: 'Futura PT Book',
    fontSize: "12px",
    fontWeight: "bold",
    color: "#444444",
    display: 'flex',
    justifyContent: 'center',
    borderBottom: '6px solid #154A72 !important'
  },
}));

const getColor = (e, i, _keys, t) => {
  let color = hregex.test(_keys[t][i]) ? "#339249" : lregex.test(_keys[t][i]) ? "#DA2424" : "#4D8FCC"
  return color
}

function EtfsStandartView(props) {
  const { value, tickerResponse, label, ETFvalue, token } = props
  const classes = useStyles();
  const [ticker, setTicker] = useState(0);
  // const [tickerResponse, setTickerResponse] = useState(0);
  const [closePrice, setClosePrice] = React.useState(0);
  const [curr_perc, setCurr_perc] = React.useState(0);
  const [Response, setResponse] = useState([]);
  const [data, setData] = useState([]);
  const [prevDC, setPrevDC] = useState(0);
  const [prevWC, setPrevWC] = useState(0);
  const [prevMC, setPrevMC] = useState(0);
  const [prevQC, setPrecQC] = useState(0);
  const [prevHYC, setPrevHYC] = useState(0);
  const [prevYC, setPrevYC] = useState(0);

  const [cprtlDaily, setCprtlDaily] = React.useState(0);
  const [cprtlWeekly, setCprtlWeekly] = React.useState(0);
  const [cprtlMonthly, setCprtlMonthly] = React.useState(0);
  const [cprtl_QY, setCprtl_QY] = React.useState(0);
  const [cprtl_HY, setCprtl_HY] = React.useState(0);
  const [cprtl_Y, setCprtl_Y] = React.useState(0);

  const [cprblDaily, setCprblDaily] = React.useState(0);
  const [cprblWeekly, setCprblWeekly] = React.useState(0);
  const [cprblMonthly, setCprblMonthly] = React.useState(0);
  const [cprbl_QY, setCprbl_QY] = React.useState(0);
  const [cprbl_HY, setCprbl_HY] = React.useState(0);
  const [cprbl_Y, setCprbl_Y] = React.useState(0);

  const [keys, setKeys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], quarterlyKeys: [], halfYearlyKeys: [], yearlyKeys: [] })

  const [obj_observation_d_w, setObj_Observations_d_w] = useState({
    observe_dailyData: [],
    observe_weeklyData: [],
    observe_monthlyData: [],
  })
  const [obj_observation_rest, setObj_Observations_rest] = useState({
    observe_quaerterlyData: [],
    observe_halfYearlylyData: [],
    observe_yearlyData: []
  })

  const fetchBees = async (b) => {
    const response = await companyApiData(b)
    if (response?.data?.success) {
      setResponse(response?.data)
      const a1 = []

      let data = response?.data

      let dailyArr = Object.values(data.dailyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
      let _dailyKeys = Object.keys(data.dailyData)

      let weeklyArr = Object.values(data.weeklyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
      let _weeklyKeys = Object.keys(data.weeklyData)

      let monthlyArr = Object.values(data.monthlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
      let _monthlyKeys = Object.keys(data.monthlyData)

      let quarterlyArr = Object.values(data.quarterlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
      let _quarterlyKeys = Object.keys(data.quarterlyData)

      let halfYearlyArr = Object.values(data.halfYearlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
      let _halfYearlyKeys = Object.keys(data.halfYearlyData)

      let yearlyArr = Object.values(data.yearlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
      let _yearlyKeys = Object.keys(data.yearlyData)

      dailyArr.map((e, i) => {
        a1.push({ daily: e, weekly: weeklyArr[i], monthly: monthlyArr[i], quarterly: quarterlyArr[i], halfyearly: halfYearlyArr[i], yearly: yearlyArr[i] })
      })

      setPrevDC(data?.pre_CLOSE_DAY[0]?.PRE_CLOSE_DAY)
      setPrevWC(data?.pre_CLOSE_WEEK[0]?.PRE_CLOSE_WEEK)
      setPrevMC(data?.pre_CLOSE_MONTH[0]?.PRE_CLOSE_MONTH)
      setPrecQC(data?.pre_CLOSE_QY[0]?.PRE_CLOSE_QY)
      setPrevHYC(data?.pre_CLOSE_HY[0]?.PRE_CLOSE_HY)
      setPrevYC(data?.pre_CLOSE_Y[0]?.PRE_CLOSE_Y)

      setCprtlDaily(data?.dailyData?.CPRTL_D)
      setCprtlWeekly(data?.weeklyData?.CPRTL_W)
      setCprtlMonthly(data?.monthlyData?.CPRTL_M)
      setCprtl_QY(data?.quarterlyData?.CPRTL_Q)
      setCprtl_HY(data?.halfYearlyData?.CPRTL_HY)
      setCprtl_Y(data?.yearlyData?.CPRTL_Y)

      setCprblDaily(data?.dailyData?.CPRBL_D)
      setCprblWeekly(data?.weeklyData?.CPRBL_W)
      setCprblMonthly(data?.monthlyData?.CPRBL_M)
      setCprbl_QY(data?.quarterlyData?.CPRBL_Q)
      setCprbl_HY(data?.halfYearlyData?.CPRBL_HY)
      setCprbl_Y(data?.yearlyData?.CPRBL_Y)

      setData(a1)
      setKeys({
        dailyKeys: _dailyKeys, weeklyKeys: _weeklyKeys, monthlyKeys: _monthlyKeys,
        quarterlyKeys: _quarterlyKeys, halfYearlyKeys: _halfYearlyKeys, yearlyKeys: _yearlyKeys
      })
      // setLoading(false)
    } else if (response?.data?.message) {
      // setLoading(false)
      setData([])
      notify("warning", `${response?.data?.message}`)
    }
  }

  const _getObservations_d_w = async (companySymbol, t) => {
    let _dailyData = await GetObservationsForCandle("D", companySymbol, t)
    let _weeklyData = await GetObservationsForCandle("W", companySymbol, t)
    let _monthlyData = await GetObservationsForCandle("M", companySymbol, t)
    setObj_Observations_d_w({
      observe_dailyData: _dailyData?.data,
      observe_weeklyData: _weeklyData?.data,
      observe_monthlyData: _monthlyData?.data,
    })
  }

  const _getObservations_rest = async (companySymbol, t) => {
    let _quaerterlyData = await GetObservationsForCandle("Q", companySymbol, t)
    let _halfYearlylyData = await GetObservationsForCandle("H", companySymbol, t)
    let _yearlyData = await GetObservationsForCandle("Y", companySymbol, t)

    setObj_Observations_rest({
      observe_quaerterlyData: _quaerterlyData?.data,
      observe_halfYearlylyData: _halfYearlylyData?.data,
      observe_yearlyData: _yearlyData?.data
    })
  }
  useEffect(() => {
    fetchBees(ETFvalue)
    const observationsInterVal_d_w = setInterval(() => {
      if (ETFvalue && token) {
        _getObservations_d_w(ETFvalue, token)
      }
    }, 30000);
    const observationsInterVal_rest = setInterval(() => {
      if (ETFvalue && token) {
        _getObservations_rest(ETFvalue, token)
      }
    }, 300000);
    return () => {
      clearInterval(observationsInterVal_d_w)
      clearInterval(observationsInterVal_rest)
    };
  }, [ETFvalue, token])

  useMemo(() => {
    if (ETFvalue && token) {
      _getObservations_d_w(ETFvalue, token)
      _getObservations_rest(ETFvalue, token)
    }
  }, [ETFvalue, token])

  useEffect(() => {
    if (tickerResponse) {
      setTicker(tickerResponse.lastTradedPrice)
      setClosePrice(tickerResponse?.closePrice)
      setCurr_perc(tickerResponse?.change)
    }
  }, [tickerResponse])

  const getPercentage = (i) => {
    switch (i) {
      case 0:
        let prec_D = ((ticker - prevDC) / prevDC) * 100
        let D_points = (ticker - closePrice)?.toFixed(2)
        return <>
          <p style={{ marginRight: "6px", fontSize: '11px', color: `${D_points >= 0 ? "#339249" : "#DA2424"}` }}>DTD :</p>
          <p style={{ fontSize: '11px', color: `${D_points >= 0 ? "#339249" : "#DA2424"}` }}>{D_points ? D_points : 0}</p>
          <p style={{ fontSize: '11px', color: `${D_points >= 0 ? "#339249" : "#DA2424"}` }}>{`(${curr_perc ? curr_perc?.toFixed(2) : 0}%)`}</p>
        </>
      case 1:
        let prec_W = ((ticker - prevWC) / prevWC) * 100
        let W_points = (ticker - prevWC)?.toFixed(2)
        return <>
          <p style={{ marginRight: "6px", fontSize: '11px', color: `${W_points >= 0 ? "#339249" : "#DA2424"}` }}>WTD :</p>
          <p style={{ fontSize: '11px', color: `${W_points >= 0 ? "#339249" : "#DA2424"}` }}>{W_points ? W_points : 0}</p>
          <p style={{ fontSize: '11px', color: `${prec_W >= 0 ? "#339249" : "#DA2424"}` }}>{`(${prec_W ? prec_W.toFixed(2) : 0}%)`}</p>
        </>
      case 2:
        let prec_M = ((ticker - prevMC) / prevMC) * 100
        let M_points = (ticker - prevMC)?.toFixed(2)
        return <>
          <p style={{ marginRight: "6px", fontSize: '11px', color: `${M_points >= 0 ? "#339249" : "#DA2424"}` }}>MTD :</p>
          <p style={{ fontSize: '11px', color: `${M_points >= 0 ? "#339249" : "#DA2424"}` }}>{M_points ? M_points : 0}</p>
          <p style={{ fontSize: '11px', color: `${prec_M >= 0 ? "#339249" : "#DA2424"}` }}>{`(${prec_M ? prec_M.toFixed(2) : 0}%)`}</p>
        </>
      case 3:
        let prec_Q = ((ticker - prevQC) / prevQC) * 100
        let Q_points = (ticker - prevQC)?.toFixed(2)
        return <>
          <p style={{ marginRight: "6px", fontSize: '11px', color: `${Q_points >= 0 ? "#339249" : "#DA2424"}` }}>QTD :</p>
          <p style={{ fontSize: '11px', color: `${Q_points >= 0 ? "#339249" : "#DA2424"}` }}>{Q_points ? Q_points : 0}</p>
          <p style={{ fontSize: '11px', color: `${prec_Q >= 0 ? "#339249" : "#DA2424"}` }}>{`(${prec_Q ? prec_Q.toFixed(2) : 0}%)`}</p>
        </>
      case 4:
        let prec_HY = ((ticker - prevHYC) / prevHYC) * 100
        let HY_points = (ticker - prevHYC)?.toFixed(2)
        return <>
          <p style={{ marginRight: "6px", fontSize: '11px', color: `${HY_points >= 0 ? "#339249" : "#DA2424"}` }}>HTD :</p>
          <p style={{ fontSize: '11px', color: `${HY_points >= 0 ? "#339249" : "#DA2424"}` }}>{HY_points ? HY_points : 0}</p>
          <p style={{ fontSize: '11px', color: `${prec_HY >= 0 ? "#339249" : "#DA2424"}` }}>{`(${prec_HY ? prec_HY.toFixed(2) : 0}%)`}</p>
        </>
      case 5:
        let prec_Y = ((ticker - prevYC) / prevYC) * 100
        let Y_points = (ticker - prevYC)?.toFixed(2)
        return <>
          <p style={{ marginRight: "6px", fontSize: '11px', color: `${Y_points >= 0 ? "#339249" : "#DA2424"}` }}>YTD :</p>
          <p style={{ fontSize: '11px', color: `${Y_points >= 0 ? "#339249" : "#DA2424"}` }}>{Y_points ? Y_points : 0}</p>
          <p style={{ fontSize: '11px', color: `${prec_Y >= 0 ? "#339249" : "#DA2424"}` }}>{`(${prec_Y ? prec_Y.toFixed(2) : 0}%)`}</p>
        </>
      default:
        return <p>0</p>
    }
  }

  const getObservations = (tf) => {
    switch (tf) {
      case "D":
        let d_obser = {
          arrow: obj_observation_d_w?.observe_dailyData[0]?.arrow, poscount: obj_observation_d_w?.observe_dailyData[0]?.poscount, negcount: obj_observation_d_w?.observe_dailyData[0]?.negcount,
          neutralcount: obj_observation_d_w?.observe_dailyData[0]?.neutralcount, totalMetrics: obj_observation_d_w?.observe_dailyData[0]?.totalMetrics,rest_data: obj_observation_d_w?.observe_dailyData[0]
        }
        return d_obser
      case "W":
        let w_obser = {
          arrow: obj_observation_d_w?.observe_weeklyData[0]?.arrow, poscount: obj_observation_d_w?.observe_weeklyData[0]?.poscount, negcount: obj_observation_d_w?.observe_weeklyData[0]?.negcount,
          neutralcount: obj_observation_d_w?.observe_weeklyData[0]?.neutralcount, totalMetrics: obj_observation_d_w?.observe_weeklyData[0]?.totalMetrics,rest_data: obj_observation_d_w?.observe_weeklyData[0]
        }
        return w_obser
      case "M":
        let m_obser = {
          arrow: obj_observation_d_w?.observe_monthlyData[0]?.arrow, poscount: obj_observation_d_w?.observe_monthlyData[0]?.poscount, negcount: obj_observation_d_w?.observe_monthlyData[0]?.negcount,
          neutralcount: obj_observation_d_w?.observe_monthlyData[0]?.neutralcount, totalMetrics: obj_observation_d_w?.observe_monthlyData[0]?.totalMetrics,rest_data: obj_observation_d_w?.observe_monthlyData[0]
        }
        return m_obser
      case "Q":
        let q_obser = {
          arrow: obj_observation_rest?.observe_quaerterlyData[0]?.arrow, poscount: obj_observation_rest?.observe_quaerterlyData[0]?.poscount, negcount: obj_observation_rest?.observe_quaerterlyData[0]?.negcount,
          neutralcount: obj_observation_rest?.observe_quaerterlyData[0]?.neutralcount, totalMetrics: obj_observation_rest?.observe_quaerterlyData[0]?.totalMetrics,rest_data: obj_observation_rest?.observe_quaerterlyData[0]
        }
        return q_obser
      case "H":
        let h_obser = {
          arrow: obj_observation_rest?.observe_halfYearlylyData[0]?.arrow, poscount: obj_observation_rest?.observe_halfYearlylyData[0]?.poscount, negcount: obj_observation_rest?.observe_halfYearlylyData[0]?.negcount,
          neutralcount: obj_observation_rest?.observe_halfYearlylyData[0]?.neutralcount, totalMetrics: obj_observation_rest?.observe_halfYearlylyData[0]?.totalMetrics,rest_data: obj_observation_rest?.observe_halfYearlylyData[0]
        }
        return h_obser
      case "Y":
        let y_obser = {
          arrow: obj_observation_rest?.observe_yearlyData[0]?.arrow, poscount: obj_observation_rest?.observe_yearlyData[0]?.poscount, negcount: obj_observation_rest?.observe_yearlyData[0]?.negcount,
          neutralcount: obj_observation_rest?.observe_yearlyData[0]?.neutralcount, totalMetrics: obj_observation_rest?.observe_yearlyData[0]?.totalMetrics,rest_data: obj_observation_rest?.observe_yearlyData[0]
        }
        return y_obser
      default:
        return {};
    }
  }

  const objHead = data.length && [
    {
      duration: "Daily",
      curr_open: tickerResponse.openPrice,
      curr_high: tickerResponse.highPrice,
      curr_low: tickerResponse.lowPrice,
      curr_range: tickerResponse.highPrice - tickerResponse.lowPrice,
      prev_Range_perc: ((ticker - Response?.dailyLowHigh[0]?.pre_low) / (Response?.dailyLowHigh[0]?.pre_high - Response?.dailyLowHigh[0]?.pre_low)) * 100,
      prev_high: Response?.dailyLowHigh[0]?.pre_high,
      prev_low: Response?.dailyLowHigh[0]?.pre_low,
      prev_close: Response?.pre_CLOSE_DAY[0]?.PRE_CLOSE_DAY,
      observations: getObservations("D")
    },
    {
      duration: "Weekly",
      curr_open: Response?.open_WEEK[0]?.OPEN_WEEK === 0 ? tickerResponse.openPrice : Response?.open_WEEK[0]?.OPEN_WEEK,
      curr_high: (tickerResponse ? (Response.weeklyLowHigh[0].high > tickerResponse.highPrice ? Response.weeklyLowHigh[0].high : tickerResponse.highPrice) : Response.weeklyLowHigh[0].high)?.toFixed(2),
      curr_low: (tickerResponse ? (Response.weeklyLowHigh[0].low < tickerResponse.lowPrice ? (Response.weeklyLowHigh[0].low == 0 ? tickerResponse.lowPrice : Response.weeklyLowHigh[0].low) : tickerResponse.lowPrice) : Response.weeklyLowHigh[0].low)?.toFixed(2),
      curr_range: (tickerResponse ? (Response.weeklyLowHigh[0].high > tickerResponse.highPrice ? Response.weeklyLowHigh[0].high : tickerResponse.highPrice) : Response.weeklyLowHigh[0].high)?.toFixed(2) - (tickerResponse ? (Response.weeklyLowHigh[0].low < tickerResponse.lowPrice ? (Response.weeklyLowHigh[0].low == 0 ? tickerResponse.lowPrice : Response.weeklyLowHigh[0].low) : tickerResponse.lowPrice) : Response.weeklyLowHigh[0].low)?.toFixed(2),
      prev_Range_perc: ((ticker - Response?.pre_weeklyLowHigh[0]?.pre_low) / (Response?.pre_weeklyLowHigh[0]?.pre_high - Response?.pre_weeklyLowHigh[0]?.pre_low)) * 100,
      prev_high: Response?.pre_weeklyLowHigh[0]?.pre_high,
      prev_low: Response?.pre_weeklyLowHigh[0]?.pre_low,
      prev_close: Response?.pre_CLOSE_WEEK[0]?.PRE_CLOSE_WEEK,
      observations: getObservations("W")
    },
    {
      duration: "Monthly",
      curr_open: Response?.open_MONTH[0]?.OPEN_MONTH === 0 ? tickerResponse.openPrice : Response?.open_MONTH[0]?.OPEN_MONTH,
      curr_high: (tickerResponse ? (Response.monthlyLowHigh[0].high > tickerResponse.highPrice ? Response.monthlyLowHigh[0].high : tickerResponse.highPrice) : Response.monthlyLowHigh[0].high)?.toFixed(2),
      curr_low: (tickerResponse ? (Response.monthlyLowHigh[0].low < tickerResponse.lowPrice ? (Response.monthlyLowHigh[0].low == 0 ? tickerResponse.lowPrice : Response.monthlyLowHigh[0].low) : tickerResponse.lowPrice) : Response.monthlyLowHigh[0].low)?.toFixed(2),
      curr_range: (tickerResponse ? (Response.monthlyLowHigh[0].high > tickerResponse.highPrice ? Response.monthlyLowHigh[0].high : tickerResponse.highPrice) : Response.monthlyLowHigh[0].high)?.toFixed(2) - (tickerResponse ? (Response.monthlyLowHigh[0].low < tickerResponse.lowPrice ? (Response.monthlyLowHigh[0].low == 0 ? tickerResponse.lowPrice : Response.monthlyLowHigh[0].low) : tickerResponse.lowPrice) : Response.monthlyLowHigh[0].low)?.toFixed(2),
      prev_Range_perc: ((ticker - Response?.pre_monthlyLowHigh[0]?.pre_low) / (Response?.pre_monthlyLowHigh[0]?.pre_high - Response?.pre_monthlyLowHigh[0]?.pre_low)) * 100,
      prev_high: Response?.pre_monthlyLowHigh[0]?.pre_high,
      prev_low: Response?.pre_monthlyLowHigh[0]?.pre_low,
      prev_close: Response?.pre_CLOSE_MONTH[0]?.PRE_CLOSE_MONTH,
      observations: getObservations("M")
    },
    {
      duration: "Quarterly",
      curr_open: Response?.open_QY[0]?.OPEN_QY === 0 ? tickerResponse.openPrice : Response?.open_QY[0]?.OPEN_QY,
      curr_high: (tickerResponse ? (Response.quarterlyLowHigh[0].high > tickerResponse.highPrice ? Response.quarterlyLowHigh[0].high : tickerResponse.highPrice) : Response.quarterlyLowHigh[0].high)?.toFixed(2),
      curr_low: (tickerResponse ? (Response.quarterlyLowHigh[0].low < tickerResponse.lowPrice ? (Response.quarterlyLowHigh[0].low == 0 ? tickerResponse.lowPrice : Response.quarterlyLowHigh[0].low) : tickerResponse.lowPrice) : Response.quarterlyLowHigh[0].low)?.toFixed(2),
      curr_range: (tickerResponse ? (Response.quarterlyLowHigh[0].high > tickerResponse.highPrice ? Response.quarterlyLowHigh[0].high : tickerResponse.highPrice) : Response.quarterlyLowHigh[0].high)?.toFixed(2) - (tickerResponse ? (Response.quarterlyLowHigh[0].low < tickerResponse.lowPrice ? (Response.quarterlyLowHigh[0].low == 0 ? tickerResponse.lowPrice : Response.quarterlyLowHigh[0].low) : tickerResponse.lowPrice) : Response.quarterlyLowHigh[0].low)?.toFixed(2),
      prev_Range_perc: ((ticker - Response?.pre_quarterlyLowHigh[0]?.pre_low) / (Response?.pre_quarterlyLowHigh[0]?.pre_high - Response?.pre_quarterlyLowHigh[0]?.pre_low)) * 100,
      prev_high: Response?.pre_quarterlyLowHigh[0]?.pre_high,
      prev_low: Response?.pre_quarterlyLowHigh[0]?.pre_low,
      prev_close: Response?.pre_CLOSE_QY[0]?.PRE_CLOSE_QY,
      observations: getObservations("Q")
    },
    {
      duration: "Half Yearly",
      curr_open: Response?.open_HY[0]?.OPEN_HY === 0 ? tickerResponse.openPrice : Response?.open_HY[0]?.OPEN_HY,
      curr_high: (tickerResponse ? (Response.halfYearlyLowHigh[0].high > tickerResponse.highPrice ? Response.halfYearlyLowHigh[0].high : tickerResponse.highPrice) : Response.halfYearlyLowHigh[0].high)?.toFixed(2),
      curr_low: (tickerResponse ? (Response.halfYearlyLowHigh[0].low < tickerResponse.lowPrice ? (Response.halfYearlyLowHigh[0].low == 0 ? tickerResponse.lowPrice : Response.halfYearlyLowHigh[0].low) : tickerResponse.lowPrice) : Response.halfYearlyLowHigh[0].low)?.toFixed(2),
      curr_range: (tickerResponse ? (Response.halfYearlyLowHigh[0].high > tickerResponse.highPrice ? Response.halfYearlyLowHigh[0].high : tickerResponse.highPrice) : Response.halfYearlyLowHigh[0].high)?.toFixed(2) - (tickerResponse ? (Response.halfYearlyLowHigh[0].low < tickerResponse.lowPrice ? (Response.halfYearlyLowHigh[0].low == 0 ? tickerResponse.lowPrice : Response.halfYearlyLowHigh[0].low) : tickerResponse.lowPrice) : Response.halfYearlyLowHigh[0].low)?.toFixed(2),
      prev_Range_perc: ((ticker - Response?.pre_halfYearlyLowHigh[0]?.pre_low) / (Response?.pre_halfYearlyLowHigh[0]?.pre_high - Response?.pre_halfYearlyLowHigh[0]?.pre_low)) * 100,
      prev_high: Response?.pre_halfYearlyLowHigh[0]?.pre_high,
      prev_low: Response?.pre_halfYearlyLowHigh[0]?.pre_low,
      prev_close: Response?.pre_CLOSE_HY[0]?.PRE_CLOSE_HY,
      observations: getObservations("H")
    },
    {
      duration: "Yearly",
      curr_open: Response?.open_Y[0]?.OPEN_YEAR === 0 ? tickerResponse.openPrice : Response?.open_Y[0]?.OPEN_YEAR,
      curr_high: (tickerResponse ? (Response.yearlyLowHigh[0].high > tickerResponse.highPrice ? Response.yearlyLowHigh[0].high : tickerResponse.highPrice) : Response.yearlyLowHigh[0].high)?.toFixed(2),
      curr_low: (tickerResponse ? (Response.yearlyLowHigh[0].low < tickerResponse.lowPrice ? (Response.yearlyLowHigh[0].low == 0 ? tickerResponse.lowPrice : Response.yearlyLowHigh[0].low) : tickerResponse.lowPrice) : Response.yearlyLowHigh[0].low)?.toFixed(2),
      curr_range: (tickerResponse ? (Response.yearlyLowHigh[0].high > tickerResponse.highPrice ? Response.yearlyLowHigh[0].high : tickerResponse.highPrice) : Response.yearlyLowHigh[0].high)?.toFixed(2) - (tickerResponse ? (Response.yearlyLowHigh[0].low < tickerResponse.lowPrice ? (Response.yearlyLowHigh[0].low == 0 ? tickerResponse.lowPrice : Response.yearlyLowHigh[0].low) : tickerResponse.lowPrice) : Response.yearlyLowHigh[0].low)?.toFixed(2),
      prev_Range_perc: ((ticker - Response?.pre_yearlyLowHigh[0]?.pre_low) / (Response?.pre_yearlyLowHigh[0]?.pre_high - Response?.pre_yearlyLowHigh[0]?.pre_low)) * 100,
      prev_high: Response?.pre_yearlyLowHigh[0]?.pre_high,
      prev_low: Response?.pre_yearlyLowHigh[0]?.pre_low,
      prev_close: Response?.pre_CLOSE_Y[0]?.PRE_CLOSE_Y,
      observations: getObservations("Y")
    },
  ]

  const getHold = (v) => {
    if (v <= 16.65) return "Strong Sell"
    else if (v <= 33.33) return "Sell"
    else if (v <= 66.66) return "Hold"
    else if (v <= 83.31) return "Buy"
    else if (v <= 100) return "Strong Buy"
}

  return (
    <>
      {/* <Grid className='trading-ticker'>
    <Grid className="tview-ticker">
        <Grid style={{ display: 'flex', alignItems: 'baseline' }}>
            <Grid className="tview-context">{label}</Grid>
            <Grid className="tviewpricing green">
                <p> {ticker ? ticker : 0}</p>
                <p style={{ fontSize: '11px', color: '#857d7d', marginLeft: '5px' }}>{ticker ? (ticker - closePrice)?.toFixed(2) : 0}</p>
                <p style={{ fontSize: '11px', color: '#857d7d', marginLeft: '5px' }}>{`(${curr_perc ? (curr_perc)?.toFixed(2) : 0}%)`}</p>
            </Grid>
        </Grid>
    </Grid>
    </Grid> */}
      <TableContainer className='etfs-table' style={{ borderRadius: "10px" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className='table-head'>
            <TableRow>
              {data.length && objHead?.map((e, i) => (
                <>
                  {/* <TableCell key={i}><Typography align='center'>{e.frame}</Typography></TableCell> */}
                  <TableCell key={i} className={classes.headerCell} style={{ padding: "0px" }}>
                    <Grid container direction="column">
                      <Grid className={classes.topContainer}>
                        <Tooltip title={getTooltip(e.duration)} arrow placement="top">
                          <Typography align='center' className={classes.timeDur}>
                            {e.duration}
                          </Typography>
                        </Tooltip>
                        <Grid className={classes.greenPerc}>
                          {getPercentage(i)}
                        </Grid>
                      </Grid>
                      <Grid>
                        <GaugeChartForDatatable value={e?.observations?.rest_data?.percentage?.toFixed(2)} hold={getHold(e?.observations?.rest_data?.percentage?.toFixed(2))} />
                      </Grid>
                      <Grid className='standard-observ-eq'>
                        {/* {ticker ? <SingleCandleStick open={e.curr_open ? e.curr_open : 0} high={e.curr_high ? e.curr_high : 0} low={e.curr_low ? e.curr_low : 0} close={ticker?.toFixed(2)} /> : ""} */}
                        {tickerResponse ? <MetricCandleStick open={e.curr_open ? e.curr_open : 0} high={e.curr_high ? e.curr_high : 0} low={e.curr_low ? e.curr_low : 0} close={tickerResponse?.lastTradedPrice?.toFixed(2)} bg="#dfeaf2"
                          poscount={e?.observations?.poscount} negcount={e?.observations?.negcount} arrow={e?.observations?.arrow} totalMetrics={e?.observations?.totalMetrics} prev_range={e?.observations?.rest_data?.pre_percentage}
                        /> : ""}
                      </Grid>
                      <Grid className='flex justify-center'>
                        <Grid className={classes.low}>
                        <Tooltip title='Previous Close' arrow placement="top">
                            <Typography align='center' className={classes.highFont}>P.C: {e?.prev_close ? e?.prev_close?.toFixed(2) : 0}</Typography>
                          </Tooltip>
                          {/* <Tooltip title='Previous Range Percentage' arrow placement="top">
                            <Typography align='center' className={classes.highFont} style={{ color: getColorPr(e?.prev_Range_perc) }}>P.R: {tickerResponse ? e?.prev_Range_perc.toFixed(2) : 0}%</Typography>
                          </Tooltip> */}
                        </Grid>
                        <Grid className={classes.high}>
                          <Tooltip title='Current open' arrow placement="top">
                            <Typography align='center' className={classes.highFont}>C.O: {e?.curr_open ? e?.curr_open?.toFixed(2) : 0}</Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid className='flex justify-center'>
                        <Grid className={classes.high}>
                          <Tooltip title='Previous High' arrow placement="top">
                            <Typography align='center' className={classes.highFont}>P.H: {e?.prev_high ? e?.prev_high?.toFixed(2) : 0}</Typography>
                          </Tooltip>
                        </Grid>
                        <Grid className={classes.high}>
                          <Tooltip title='Current High' arrow placement="top">
                            <Typography align='center' className={classes.highFont}>C.H: {e?.curr_high ? e?.curr_high : 0}</Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid className='flex justify-center'>
                        <Grid className={classes.low}>
                          <Tooltip title='Previous Low' arrow placement="top">
                            <Typography align='center' className={classes.lowFont}>P.L: {e?.prev_low ? e?.prev_low?.toFixed(2) : 0}</Typography>
                          </Tooltip>
                        </Grid>
                        <Grid className={classes.low}>
                          <Tooltip title='Current Low' arrow placement="top">
                            <Typography align='center' className={classes.lowFont}>C.L: {e?.curr_low ? e?.curr_low : 0}</Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <Grid className={classes.lastValuesInHeader}>
                        <Grid className={classes.high}>
                        <Tooltip title='Previous Range Percentage' arrow placement="top">
                            <Typography align='center' className={classes.highFont} >P.R: {e?.prev_low ? (e?.prev_high - e?.prev_low)?.toFixed(2) : 0}</Typography>
                          </Tooltip>
                          {/* <Tooltip title='Previous Close' arrow placement="top">
                            <Typography align='center' className={classes.highFont}>P.C: {e?.prev_close ? e?.prev_close?.toFixed(2) : 0}</Typography>
                          </Tooltip> */}
                        </Grid>
                        <Grid className={classes.high}>
                          <Tooltip title='Current Range' arrow placement="top">
                            <Typography align='center' className={classes.highFont}>C.R: {e?.curr_range ? e?.curr_range?.toFixed(2) : e?.curr_high ? (e?.curr_high - e?.curr_low)?.toFixed(2) : 0}</Typography>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TableCell>
                </>))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length && data.map((e, i) => {
              return (
                <TableRow className='table-row-etfs w-full'>
                  <TableCell className='w-1/6' style={{ color: getColor(e, i, keys, "dailyKeys") }}><Typography align='center'>{e.daily}</Typography></TableCell>
                  <TableCell className='w-1/6' style={{ color: getColor(e, i, keys, "weeklyKeys") }}><Typography align='center'>{e.weekly}</Typography></TableCell>
                  <TableCell className='w-1/6' style={{ color: getColor(e, i, keys, "monthlyKeys") }}><Typography align='center'>{e.monthly}</Typography></TableCell>
                  <TableCell className='w-1/6' style={{ color: getColor(e, i, keys, "quarterlyKeys") }}><Typography align='center'>{e.quarterly}</Typography></TableCell>
                  <TableCell className='w-1/6' style={{ color: getColor(e, i, keys, "halfYearlyKeys") }}><Typography align='center'>{e.halfyearly}</Typography></TableCell>
                  <TableCell className='w-1/6' style={{ color: getColor(e, i, keys, "yearlyKeys") }}><Typography align='center'>{e.yearly}</Typography></TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer></>
  )
}

export default EtfsStandartView