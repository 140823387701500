import React, { useEffect, useRef } from 'react';
import './BlogPage.css';
import image1 from '../../assets/images/n5.png';
import image2 from '../../assets/images/meter.png';
import image3 from '../../assets/images/daily.png';
import image4 from '../../assets/images/itmeter.png';
import image5 from '../../assets/images/banknifty.png';
import LandingHeader from './header';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import pdf from '../../assets/pdf/CreativeBriefDoc.pdf'
import pdf1 from '../../assets/pdf/BLOGS.pdf'
import { Viewer, Worker } from '@react-pdf-viewer/core';

const Day2Blog = () => {
    const history = useHistory();
    const handleNavigate = (path) => {
        history.push(path);
        window.scrollTo({ top: 0, behavior: 'smooth' });  // Scroll to top with smooth behavior
        // setShowSidebar(false);  // Optionally close the sidebar after navigation
        // setActiveTab(path);
      };
    return (
        <div>
            <div>
                {/* <LandingHeader /> */}
                <button onClick={() => handleNavigate("/blogs")} style={{
                    marginTop: '20px',
                    padding: '10px 20px',
                    backgroundColor: '#007bff',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    fontSize: '16px'
                }}>
                    Back to Today's Blog
                </button>

            </div>
            <div className="blog-page" style={{ position: 'absolute', top: '68px', width: '100%', fontFamily: 'Futura PT Book' }}>
                
                <div className='pdf'>
                    <p style={{ fontSize: '16px', fontWeight: '700' }}>11-09-2021 Blog</p>
                    <Worker style={{ width: '100%' }} workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                        <Viewer fileUrl={pdf1} />
                        <Viewer fileUrl={pdf} />
                    </Worker>
                </div>

            </div>
        </div>


    );
};

export default Day2Blog;
