import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';

const hregex = /H\d+/;
const lregex = /L\d+/;

const CandleStickChart = (props) => {
    const [darkMode, setDarkMode] = useState(false);
    const { yAxisValues, graphData , keys, data} = props;
    const containerRef = useRef(null);
    const getColor = (index, k_i) => {
        let color = hregex.test(k_i) ? "#339249" : lregex.test(k_i) ? "#DA2424" : "#4D8FCC"
        return color
    }

    function getValuesByPattern(data, pattern) {
        const result = Object.keys(data).filter(key => key.startsWith(pattern)).reduce((acc, key) => {
                acc[key] = data[key];
                return data[key];
            }, {});
        return result;
    }

    const getBorderWidth = (d,v) => {
        let r2 = Math.max(getValuesByPattern(d,"SPH1"),getValuesByPattern(d,"FPH2"))
        let r1 = Math.min(getValuesByPattern(d,"SPH1"),getValuesByPattern(d,"FPH2"))
        let s1 = Math.max(getValuesByPattern(d,"SPL1"),getValuesByPattern(d,"FPL2"))
        let s2 = Math.min(getValuesByPattern(d,"SPL1"),getValuesByPattern(d,"FPL2"))
        if(r2 == v || r1 == v || s1 == v || s2 == v) return "5px"
        return "2px"
    }
   
    const getYaxisArray = (a, k, d) => {
        const containerWidth = containerRef.current ? containerRef.current.offsetWidth : 250; // Default width if container is not measured
        const percentageOffsetX = 0.2 * containerWidth;
        
        return a?.map((value, i) => ({
            y: value,
            borderWidth: getBorderWidth(d,value),
            borderColor: getColor(i, k[i]),
            strokeDashArray: 0,
            label: {
                borderColor: '#00E396',
                borderWidth: "0px",
                style: {
                    display: "none",
                    fontSize: '10px',
                    color: getColor(i, k[i]),
                    background: 'transparent',
                },
                orientation: 'horizontal',
                offsetX: percentageOffsetX,
                offsetY: 7,
                text: value?.toFixed(1)
            }
        }));
    }

    const options = {
       
        chart: {
            type: 'candlestick',
            height: 400,
            background: darkMode ? '#1e1e1e' : '#000',  // Background color adjustment
            toolbar: {
                show: false,  // Keep the toolbar for zoom/pan
                tools: {
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true
                },
            },
            zoom: {
                enabled: true,  // Enable zooming on the chart
                type: 'x',
                autoScaleYaxis: true
            }
        },
        title: {
            text: '15 Mins',
            align: 'center',  // Center the title
            style: {
                fontSize: '18px',
                fontFamily : "Futura PT Book",
                color: darkMode ? '#fff' : '#fff',  // Adjust based on theme
            },
        },
        xaxis: {
            type: 'datetime',
            labels: {
                style: {
                    colors: darkMode ? '#fff' : '#fff',  // Label colors for dark/light mode
                    fontSize: '12px'
                },
                formatter: function (val) {
                    return dayjs(val).format('HH:mm');
                }
            },
            axisBorder: {
                color: darkMode ? '#777' : '#ddd'  // X-axis border color
            },
            axisTicks: {
                color: darkMode ? '#777' : '#ddd'  // X-axis ticks color
            }
        },
        yaxis: {
            labels: {
                show: false,
                style: {
                    colors: darkMode ? '#fff' : '#000', // X-axis label color
                },
            },
            axisBorder: {
                show: false  // Hide y-axis border
            },
            axisTicks: {
                show: false  // Hide y-axis ticks
            },
            tooltip: {
                enabled: true,
            },
            lines: {
                show: false
            },
        },
        grid: {
            show: false,
            padding: {
                left: -10, // Adjust left padding if needed
                right: 40 // Adjust right padding if needed
            }
        },
        annotations: {
            yaxis: getYaxisArray(yAxisValues,keys,data)  // Show only green solid lines for y-axis points
        },
        tooltip: {
            enabled: true,
            shared: true,
            intersect: false,
            followCursor: true,  // Disable follow cursor to allow longer hover effect
            theme: darkMode ? 'dark' : 'light',  // Tooltip color based on theme
            x: {
                show: true,
                formatter: function (val) {
                    return dayjs(val).format('DD MMM YYYY, HH:mm');  // Date format for tooltip
                }
            },
            y: {
                show: true,
                formatter: function (val) {
                    return val.toFixed(2);  // Format y-axis values to 2 decimals
                },
                title: {
                    formatter: () => 'Price: ',  // Customize tooltip label
                }
            },
            style: {
                fontSize: '12px'  // Adjusted tooltip font size
            }
        },
        plotOptions: {
            candlestick: {
                colors: {
                    upward: '#4CAF50',  // Green for upward candles
                    downward: '#F44336'  // Red for downward candles
                },
                wick: {
                    useFillColor: true  // Wicks match body color
                }
            }
        }
    };

    const series = [
        {
            data: graphData
        },
    ];

    return (
        <div>
            {graphData?.length > 0 &&
                <Chart options={options} series={series} type="candlestick" height={415} />
            }
        </div>
    );
};


export default CandleStickChart;