import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { CurrentCompanyData } from '../apiService/tickerApiService'
// import { isBetween9AMAnd340PM } from '../../utils'

function IndicesStrip(props) {
    const {token,IndiceLabel} = props
    const [indiceValues,setIndicevalues]= useState({})
    const [ticker, setTicker] = React.useState(0);
    const [tickerResponse, setTickerResponse] = React.useState(0);
    const [closePrice, setClosePrice] = React.useState(0);
    const [curr_perc, setCurr_perc] = React.useState(0);


    const currentMP = async (token) => {
        const CMPdata = await CurrentCompanyData(token);
        setTickerResponse(CMPdata?.data)
        setTicker(CMPdata?.data?.lastTradedPrice);
        setClosePrice(CMPdata?.data?.closePrice);
        setCurr_perc(CMPdata?.data?.change)
    }

    useEffect(() => {
        // const t = isBetween9AMAnd340PM()
        // let intervalId;
        // if(t){
        let  intervalId = setInterval(() => {
                if (token) {
                    currentMP(token)
                }
            }, 1000);
        // }else{
            // currentMP(token)
        // }
        return () => {clearInterval(intervalId)}
    },[token])

  return (
        <>
        <Grid className='indices-cmp'>
          <span>|</span>
        <Grid style={{ display: 'flex', alignItems: 'center' }}>
          <Grid className="details">
            <Grid className="details-context" style={{ fontSize: '14px' }}>
              {IndiceLabel}
            </Grid>
          </Grid>
          <Grid >
            <Grid className="details-context">
              <Grid className='pricing'>
                <Grid style={{ color: `${closePrice <= ticker ? 'rgb(51, 146, 73)' : 'rgb(218, 36, 36)'}`, display: "flex", alignItems: "baseline" }}>
                  <p className='mr-1' style={{ fontSize: '12px' }}>{ticker ? ticker?.toFixed(2) : 0}</p>
                  <p style={{ fontSize: '11px', color: '#857d7d' }}>{ticker ? (ticker - closePrice)?.toFixed(2) : 0}</p>
                  <p style={{ fontSize: '11px', color: '#857d7d' }}>{`(${curr_perc ? curr_perc?.toFixed(2) : 0}%)`}</p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </Grid>
        </>
  )
}

export default IndicesStrip