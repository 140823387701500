import { Grid, Typography, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import about1 from '../../assets/images/about1.png'
import about2 from '../../assets/images/about2.png'
import about3 from '../../assets/images/about3.png'
import img1 from '../../assets/images/aboutusImages/img1.jpeg'
import img2 from '../../assets/images/aboutusImages/img2.jpeg'
import img3 from '../../assets/images/aboutusImages/img3.jpeg'
import img4 from '../../assets/images/aboutusImages/img4.jpeg'
import img5 from '../../assets/images/aboutusImages/img5.jpeg'
import ETFS from '../../assets/images/aboutusImages/ETFS.jpg'
import HeatMaps from '../../assets/images/aboutusImages/HeatMaps.jpg'
import IndexMover from '../../assets/images/aboutusImages/IndexMover.jpg'
import Indices from '../../assets/images/aboutusImages/Indices.jpg'
import StandardView from '../../assets/images/aboutusImages/StandardView.jpg'
import Tradarview from '../../assets/images/aboutusImages/Tradarview.jpg'
import './about.css'
import Carousel from 'react-multi-carousel'

// const useStyels = makeStyles((theme) => ({
//   mainGrid : {
//     background : "#DFEBF4",
//     padding : "4rem"
//   },
//   firstImg : {
//     width : "70%",
//     height : "auto"
//   },
//   secondImg : {
//     width : "60%",
//     height : "auto",
//   },
//   linesImg : {
//     width : "30%",
//     height : "10%",
//     objectFit : "fill",
//     marginLeft : "-10px"
//   },
//   topDiv : {
//     display : "flex",
//     flexDirection : "row",
//   },
//   aboutContent : {
//     fontWeight : "600",
//     color : "#154A72",
//     fontSize : "16px",
//     fontFamily:"Futura PT Book"

//   },
//   approach : {
//     fontWeight : "bold",
//     color : "#26B44B",
//     fontSize : "18px",
//     fontFamily:"Futura PT Heavy"
//   },
//   approachContent : {
//     marginTop : "6px",
//     fontWeight : "300",
//     color : "#000000",
//     fontSize : "14px",
//     fontFamily:"Futura PT Book"
//   }
// }))

function About() {
  const images = [
    ETFS,
    HeatMaps,
    IndexMover,
    Indices,
    StandardView,
    Tradarview
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFade(false);
      }, 1000); // Duration of fade animation
    }, 4000); // Change image every 4 seconds

    return () => clearInterval(intervalId);
  }, [images.length]);

  // const classes = useStyels()

  // const Images = () => (
  //   <>
  //     <div>
  //       <div className={classes.topDiv}> 
  //           <img src={about1} className={classes.firstImg} />
  //           <img src={about3} className={classes.linesImg} style={{marginLeft : "-25px"}}/>
  //       </div>
  //       <div>
  //         <img src={about3} className={classes.linesImg} style={{marginTop : "-40px",marginLeft : "5vw"}}/>
  //         <img src={about2} className={classes.secondImg} style={{marginTop : "-35vh",marginLeft  :"12vw"}}/>
  //       </div>
  //     </div>
  //   </>
  // )
  // return (
  //   <Grid id="about" container className={classes.mainGrid}  >
  //     <Grid item xs={12} sm={12} md={4} className='ml-12'>
  //       <Images />
  //     </Grid>
  //     <Grid item xs={12} sm={12} md={6} className='ml-12'>
  //       <Grid>
  //         {/* <Typography className={classes.aboutContent}>“At Tradabulls, we are dedicated to providing the best stock market research and resources to help investors make informed decisions and achieve their financial goals."</Typography> */}
  //         <Typography className={classes.approach}>Our Vision</Typography>
  //         <Typography  className={classes.aboutContent}>To educate the individual investor to make better investment decisions through timely and with the help of quality data information. </Typography>
  //       </Grid>
  //       <Grid className='mt-4'>
  //         <Typography className={classes.approach}>Our Mission</Typography>
  //         {/* <Typography className={classes.approachContent}>We utilize a combination of fundamental and technical analysis to provide comprehensive stock market insights. Our fundamental analysis delves into financial statements, company performance, and industry trends to assess the intrinsic value of stocks. Meanwhile, our technical analysis examines chart patterns, price movements, and trading volumes to predict future market behavior. By integrating these methods, we deliver well-rounded, actionable insights that help investors make informed decisions.</Typography> */}
  //         <Typography className={classes.approachContent}>Tradabulls is dedicated to achieving their vision of providing high-quality information that benefits their stakeholders. This commitment likely involves ensuring that the information delivered is accurate, relevant, and valuable to those who rely on it. It reflects their goal of being a trusted source of information and aiming to positively impact the people and entities involved with our business.</Typography>
  //       </Grid>
  //     </Grid>
  //   </Grid>
  // )
  return (
  
    <div className="insight-container">
      <div className="insight-text">
        <h2>An insight into our Web Application</h2>
        <ul>
          <li>Index Movers is a tool used for identifying the contributing points (+ and - of the counters) along with the change percentage aggregating to the total index change points.</li>
          <li>Heat maps display the performance of the counters in the particular index, facilitating to take investment and trading decisions.</li>
          <li>Trader (Daily, Weekly, Monthly) and Investor (Quarterly, Half-yearly, Yearly) views provide an exclusive analysis of the data points.</li>
        </ul>
      </div>
      <div className="insight-images">
        <img
          id="slideshow"
          src={images[currentImageIndex]}
          alt={`Slide ${currentImageIndex + 1}`}
          className={fade ? 'fade insight-image ' : 'insight-image '}
        />
        <img
          id="slideshow"
          src={images[currentImageIndex]}
          alt={`Slide ${currentImageIndex + 1}`}
          className={fade ? 'fade insight-image ' : 'insight-image '}
        />
      </div>
    </div>
  )
}

export default About