import { Backdrop, CircularProgress, Grid } from "@material-ui/core";
import React from "react";

function Loader() {
    return (
        <Grid>
            <Backdrop
                style={{ zIndex: '1000000' }}
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 10000 }}
                open={true}
            >
                {/* <CircularProgress color="inherit" /> */}
                {/* <span class="loader"></span> */}
                <span class="lineloader"></span>
                {/* <span class="climbloader"></span> */}
            </Backdrop>
        </Grid>
    )
}

export default Loader;