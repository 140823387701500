import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { Typography } from '@material-ui/core';

const GaugeChartForDatatable = (props) => {
    const { value, hold ,bg} = props
    const chartRef = useRef(null);

    useEffect(() => {
        const dom = chartRef.current;
        dom.style.padding = "-20px 0px 0px 0px" ;
        const myChart = echarts.init(dom, null, {
            renderer: 'canvas',
            useDirtyRect: false
        });

        const gaugeData = [
            {
                value: value,
                name: hold,
                title: {
                    offsetCenter: ['-10%', '0%'],
                    fontSize: "14px",
                    fontFamily: "Futura PT Demi",
                    color: "#000000"
                },
                detail: {
                    offsetCenter: ['-10%', '10%'],
                    fontSize: "11px",
                    formatter: () => `${value ? value : 0}%`,  // Dynamic
                    fontFamily: "Futura PT Demi",
                },
                pointer: {
                    length: '50%',
                    width: 7,
                    itemStyle: {
                        color: 'auto',
                        borderWidth: 1,
                        borderColor: '#D3D3D3',
                    },
                },
            },
        ]

        const option = {
            series: [
                {
                    type: 'gauge',
                    axisLine: {
                        lineStyle: {
                            width: 10,
                            color: [
                                [0.33, '#da2424'],
                                [0.67, '#ffb200e3'],
                                [1, '#339249']
                            ]
                        }
                    },
                    pointer: {
                        length: '90%',
                        itemStyle: {
                            color: 'auto'
                        }
                    },
                    axisTick: {
                        distance: 0,
                        length: 0,
                        lineStyle: {
                            color: '#fff',
                            width: 2
                        }
                    },
                    splitLine: {
                        distance: 0,
                        length: 0,
                        lineStyle: {
                            color: '#fff',
                            width: 4
                        }
                    },
                    axisLabel: {
                        color: 'inherit',
                        distance: 32,
                        fontSize: 0
                    },
                    detail: {
                        valueAnimation: true,
                        formatter: `${props?.value ? props?.value : 0}%`,
                        color: 'inherit',
                        fontSize: 9
                    },
                    data: gaugeData
                }
            ]
        };

        if (option && typeof option === 'object') {
            myChart.setOption(option);
        }

        const interval = setInterval(() => {
            myChart.setOption({
                series: [
                    {
                        data: [
                            {
                                value: props?.value ? props?.value : 0
                            }
                        ]
                    }
                ]
            });
        }, 2000);

        window.addEventListener('resize', myChart.resize);

        // Cleanup on component unmount
        return () => {
            clearInterval(interval);
            window.removeEventListener('resize', myChart.resize);
            myChart.dispose();
        };
    }, [value]);

    const getHold = (v) => {
        if (v <= 16.65) return "Strong Sell"
        else if (v <= 33.33) return "Sell"
        else if (v <= 66.66) return "Hold"
        else if (v <= 83.31) return "Buy"
        else if (v <= 100) return "Strong Buy"
    }

    const getColor = (v) => {
        if (v <= 33.33) return '#da2424'
        else if (v >= 66.66) return '#339249'
        else return '#ffa100e3'
    }

    return (
        <div className='-pt-4'>
            <div ref={chartRef} className='-pt-4 -mb-10' style={{ width: '100%', height: '180px',backgroundColor :bg?bg : "#fff" }}></div>
            <Typography align='center' style={{ color: getColor(value), marginTop: "-4rem", fontWeight: "bold", fontSize: "12px" }}>{getHold(value)}</Typography>
        </div>
    );
};

export default GaugeChartForDatatable;
