import React, { useState } from 'react';
import './Reviews.css';

const reviews = [
    {
        id: 1,
        rating: 5,
        name:'Kishor',
        text: 'Trading involves the active buying and selling of financial assets, such as stocks.',
    },
    {
        id: 2,
        rating: 3,
        name:'Sunitha',
        text: 'Solid choice for stock market tracking. Provides good comprehensive market insights.',
    },
    {
        id: 3,
        rating: 4,
        name:'Venkat',
        text: 'I found the application incredibly helpful.  It’s a great tool for anyone looking to get started in the stock market.',
    },
    {
        id: 4,
        rating: 4,
        name:'Kalpana',
        text: 'I really like the functionality, especially the real-time alerts.',
    },
    {
        id: 5,
        rating: 5,
        name:'Anitha',
        text: 'It’s been a valuable resource for managing my investments.',
    },
    {
        id: 6,
        rating: 5,
        name:'Sujan',
        text: 'Heat maps are great to make our investment decisions. The color coding is really helpful to understand the pivot points and market momentum.',
    },
    // Add more reviews as needed
];

const Reviews = () => {
    const [startIndex, setStartIndex] = useState(0);
    const reviewsToShow = 3;
    const totalReviews = reviews.length;

    const handleNext = () => {
        setStartIndex((prevIndex) => (prevIndex + reviewsToShow) % totalReviews);
    };

    const handlePrev = () => {
        setStartIndex((prevIndex) => (prevIndex - reviewsToShow + totalReviews) % totalReviews);
    };

    return (
        <div className="reviews">
            <h2 className="reviews__title">Customer Reviews and Rating</h2>
            <div className="reviews__container">
                <button className="reviews__arrow reviews__arrow--left" onClick={handlePrev}>
                    &larr;
                </button>
                <div className="reviews__card-container">
                    {reviews.slice(startIndex, startIndex + reviewsToShow).map((review) => (
                        <div key={review.id} className="reviews__card">
                            <div style={{display:'flex',alignItems:'center',marginBottom:'10px'}}>
                                <div className="reviews__avatar" />
                                <div className="reviews__stars">
                                    {'★'.repeat(review.rating)}
                                    {'☆'.repeat(5 - review.rating)}
                                </div>
                            </div>
                            <div className='reviews__name'>{review.name}</div>
                            <div className="reviews__text">{review.text}</div>
                        </div>
                    ))}
                </div>
                <button className="reviews__arrow reviews__arrow--right" onClick={handleNext}>
                    &rarr;
                </button>
            </div>
        </div>
    );
};

export default Reviews;
