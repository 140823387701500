import { FormControlLabel, FormGroup, Grid, Switch } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import CandleStickChart from '../common/CandleGraph'
import { companyApiData, companyFibonacciData } from '../apiService/tradaApiService'
import dayjs from 'dayjs'
import { candleGraphFor15min, NiftyPlusPlus } from '../apiService/tickerApiService'
import { Typography } from '@mui/material'
import CandleStickChart30min from '../common/CGfor30min'
import CandleStickChart1hr from '../common/CGfor1hr'
import { ArrowDownward, ArrowUpward, Height } from '@material-ui/icons'
import FRECandleStickChart from '../common/FRECandleGraph'
import FRECandleStickChart30min from '../common/FRECandleFor30Min'
import FRECandleStickChart1hr from '../common/FRECandle1Hr'


function IndiceCandleGraphs(props) {
    const { value, dataFromChild } = props
    const { token, companySymbol } = dataFromChild
    const [data15min, setData15min] = useState([])
    const [data30min, setData30min] = useState([])
    const [data1hr, setData1hr] = useState([])
    const [dataYaxis15min_d, setDataYaxis15min_d] = useState([])
    const [dataYaxis30min_w, setDataYaxis30min_w] = useState([])
    const [dataYaxis_m, setDataYaxis_m] = useState([])
    const [keys_d, setKeys_d] = useState([])
    const [keys_w, setKeys_w] = useState([])
    const [keys_m, setKeys_m] = useState([])
    const [p_p_data, setP_p_data] = useState({})
    const [speedoMeterData, setSpeedoData] = useState([])
    const [view, setView] = useState(false)

    const [freData, setFreData] = useState({})
    const [freDataYaxis15min_d, setFreDataYaxis15min_d] = useState([])
    const [freDataYaxis30min_w, setFreDataYaxis30min_w] = useState([])
    const [freDataYaxis_m, setFreDataYaxis_m] = useState([])
    const [freKeys_d, setFreKeys_d] = useState([])
    const [freKeys_w, setFreKeys_w] = useState([])
    const [freKeys_m, setFreKeys_m] = useState([])

    const getCanldesData = async (tok, Symbol) => {
        try {
            const pp_data = await companyApiData(Symbol);
            // const fre_data = await companyFibonacciData(Symbol);
            // setFreData(fre_data?.data)
            const currentDate = dayjs().format('YYYY-MM-DD');
            const [candlesDataforGraph, candlesDataforGraph_30, candlesDataforGraph_60] = await Promise.all([
                candleGraphFor15min(tok, currentDate, currentDate, "15"),
                candleGraphFor15min(tok, currentDate, currentDate, "30"),
                candleGraphFor15min(tok, currentDate, currentDate, "60")
            ]);

            const timeStampData = candlesDataforGraph?.data?.map(e => ({
                x: new Date(e.x).getTime(),
                y: e.y
            })) || [];

            const timeStampData_30 = candlesDataforGraph_30?.data?.map(e => ({
                x: new Date(e.x).getTime(),
                y: e.y
            })) || [];

            const timeStampData_60 = candlesDataforGraph_60?.data?.map(e => ({
                x: new Date(e.x).getTime(),
                y: e.y
            })) || [];

            setP_p_data(pp_data?.data)
            setData15min(timeStampData);
            setData30min(timeStampData_30);
            setData1hr(timeStampData_60);
            setDataYaxis15min_d(Object.values(pp_data?.data?.dailyData || {}));
            setDataYaxis30min_w(Object.values(pp_data?.data?.weeklyData || {}));
            setDataYaxis_m(Object.values(pp_data?.data?.monthlyData || {}));
            setKeys_d(Object.keys(pp_data?.data?.dailyData || {}))
            setKeys_w(Object.keys(pp_data?.data?.weeklyData || {}))
            setKeys_m(Object.keys(pp_data?.data?.monthlyData || {}))

            // setFreDataYaxis15min_d(Object.values(fre_data?.data?.dailyData || {}));
            // setFreDataYaxis30min_w(Object.values(fre_data?.data?.weeklyData || {}));
            // setFreDataYaxis_m(Object.values(fre_data?.data?.monthlyData || {}));
            // setFreKeys_d(Object.keys(fre_data?.data?.dailyData || {}))
            // setFreKeys_w(Object.keys(fre_data?.data?.weeklyData || {}))
            // setFreKeys_m(Object.keys(fre_data?.data?.monthlyData || {}))
        } catch (error) {
            console.error("Error fetching or processing data:", error);
        }
    }

    const fetchDataForSpeedometer = async (token, companySymbol) => {
        const company_speedo_data = await NiftyPlusPlus(companySymbol, token)
        setSpeedoData(company_speedo_data?.data)
    }

    useEffect(() => {
        let intervalId;
        intervalId = setInterval(() => {

            if (token && companySymbol) {
                getCanldesData(token, companySymbol)
                fetchDataForSpeedometer(token, companySymbol)
            }
        }, 5000);
        return () => {
            clearInterval(intervalId)
            setDataYaxis15min_d([])
            setDataYaxis30min_w([])
            setDataYaxis_m([])
        }
    }, [token, companySymbol])

    useMemo(() => {
        if (token && companySymbol) {
            getCanldesData(token, companySymbol)
            fetchDataForSpeedometer(token, companySymbol)
        }
    }, [token, companySymbol])

    const getHold = (v) => {
        if (v <= 16.65) return "Strong Sell"
        else if (v <= 33.33) return "Sell"
        else if (v <= 66.66) return "Hold"
        else if (v <= 83.31) return "Buy"
        else if (v <= 100) return "Strong Buy"
    }
    const getArrow = (arrow) => {
        switch (arrow) {
            case "UP": return <ArrowUpward color="secondary" style={{ fontSize: "18px", color: "#339249" }} />
            case "DOWN": return <ArrowDownward style={{ fontSize: "18px", color: "#DA2424" }} />
            case "NORMAL": return <Height style={{ rotate: "90deg", color: " #b5812a" }} />
            default:
                break;
        }
    }
    const onViewClick = (e) => {
        setView(e.target.checked)
    }
    const getColor = (v) => {
        if (v <= 33.3) return '#da2424'
        else if (v >= 66.6) return '#339249'
        else return '#ffa100e3'
    }
    return (
        <>
            {/* <Grid style={{ margin: '2px 10px 2px 0px', display: 'flex', justifyContent: 'end' }}>
                <FormGroup className="switch-view" >
                    <FormControlLabel control={<Switch size="small" onClick={onViewClick} />} label="FRE Graph" />
                </FormGroup>
            </Grid> */}
            {dataYaxis_m.length > 0 ?
            <>
                <Grid className='w-full flex gap-1 h-full'>
                    <Grid className='h-full' style={{ width: "55%" }}>
                        <Grid style={{ background: '#000', display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ color: getColor(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.daily[0]?.percentage?.toFixed(2) : 0), marginLeft: "3px" }} className='font-futura'>
                                <span >  {getHold(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.daily[0]?.percentage : 0)}</span>
                                <span className='mr-1' style={{ fontSize: '13px' }}> ({Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.daily[0]?.percentage?.toFixed(2) : 0}%)</span>
                            </Typography>
                            <Typography noWrap align="center" style={{ color: '#fff' }} className='font-futura'>
                                <span className="text-xs font-black font-futura" style={{ color: "#DA2424" }}>
                                    {Object.values(speedoMeterData)?.length > 0 ? `(${speedoMeterData.daily[0]?.negcount}/${speedoMeterData.daily[0]?.totalMetrics})` : 0}
                                </span>
                                {getArrow(speedoMeterData.daily[0]?.arrow)}
                                <span className="text-xs font-black font-futura" style={{ color: "#339249" }}>
                                    {Object.values(speedoMeterData)?.length > 0 ? `(${speedoMeterData.daily[0]?.poscount}/${speedoMeterData.daily[0]?.totalMetrics})` : 0}
                                </span>
                            </Typography>
                        </Grid>
                        <CandleStickChart yAxisValues={dataYaxis15min_d} graphData={data15min} keys={keys_d} data={p_p_data?.dailyData} />
                    </Grid>
                    <Grid className='' style={{ width: "23%", marginRight: '5px' }}>
                        <Grid style={{ background: '#000', display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ color: getColor(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.weekly[0]?.percentage?.toFixed(2) : 0), marginLeft: "3px" }} className='font-futura'>
                                <span>  {getHold(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.weekly[0]?.percentage : 0)}</span>
                                <span className='mr-1' style={{ fontSize: '13px' }}>  ({Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.weekly[0]?.percentage?.toFixed(2) : 0}%)</span>
                            </Typography>
                            <Typography noWrap align="center" style={{ color: '#fff' }} className='font-futura'>
                                <span className="text-xs font-black font-futura" style={{ color: "#DA2424" }}>
                                    {Object.values(speedoMeterData)?.length > 0 ? `(${speedoMeterData.weekly[0]?.negcount}/${speedoMeterData.weekly[0]?.totalMetrics})` : 0}
                                </span>
                                {getArrow(speedoMeterData.weekly[0]?.arrow)}
                                <span className="text-xs font-black font-futura" style={{ color: "#339249" }}>
                                    {Object.values(speedoMeterData)?.length > 0 ? `(${speedoMeterData.weekly[0]?.poscount}/${speedoMeterData.weekly[0]?.totalMetrics})` : 0}
                                </span>
                            </Typography>
                        </Grid>
                        <CandleStickChart30min yAxisValues={dataYaxis30min_w} graphData={data30min} from30={true} keys={keys_w} data={p_p_data?.weeklyData} />
                    </Grid>
                    <Grid className='' style={{ width: "20%" }}>
                        <Grid style={{ background: '#000', display: 'flex', justifyContent: 'center', paddingTop: '3px',alignItems:'end' }}>
                            <Typography  style={{ color: getColor(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.monthly[0]?.percentage?.toFixed(2) : 0), marginLeft: "3px" }} className='font-futura'>
                                <span style={{fontSize:'14px'}} >  {getHold(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.monthly[0]?.percentage : 0)}</span>
                                <span className='mr-1' style={{ fontSize: '13px' }}>({Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.monthly[0]?.percentage?.toFixed(2) : 0}%)</span>
                            </Typography>
                            <Typography noWrap align="center" style={{ color: '#fff', fontSize: '11px' }} className='font-futura'>
                                <span className="text-xs font-black font-futura" style={{ color: "#DA2424" }}>
                                    {Object.values(speedoMeterData)?.length > 0 ? `(${speedoMeterData.monthly[0]?.negcount}/${speedoMeterData.monthly[0]?.totalMetrics})` : 0}
                                </span>
                                {getArrow(speedoMeterData.monthly[0]?.arrow)}
                                <span className="text-xs font-black font-futura" style={{ color: "#339249" }}>
                                    {Object.values(speedoMeterData)?.length > 0 ? `(${speedoMeterData.monthly[0]?.poscount}/${speedoMeterData.monthly[0]?.totalMetrics})` : 0}
                                </span>
                            </Typography>
                        </Grid>
                        <CandleStickChart1hr yAxisValues={dataYaxis_m} graphData={data1hr} keys={keys_m} data={p_p_data?.monthlyData} />
                    </Grid>
                </Grid>
                {/* <Grid className='w-full flex gap-1 h-full'>
                    <Grid className='h-full' style={{ width: "55%" }}>
                        <Grid style={{ background: '#000', display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ color: getColor(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.daily[0]?.percentage?.toFixed(2) : 0), marginLeft: "3px" }} className='font-futura'>
                                <span >  {getHold(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.daily[0]?.percentage : 0)}</span>
                                <span className='mr-1' style={{ fontSize: '13px' }}> ({Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.daily[0]?.percentage?.toFixed(2) : 0}%)</span>
                            </Typography>
                            <Typography noWrap align="center" style={{ color: '#fff' }} className='font-futura'>
                                <span className="text-xs font-black font-futura" style={{ color: "#DA2424" }}>
                                    {Object.values(speedoMeterData)?.length > 0 ? `(${speedoMeterData.daily[0]?.negcount}/${speedoMeterData.daily[0]?.totalMetrics})` : 0}
                                </span>
                                {getArrow(speedoMeterData.daily[0]?.arrow)}
                                <span className="text-xs font-black font-futura" style={{ color: "#339249" }}>
                                    {Object.values(speedoMeterData)?.length > 0 ? `(${speedoMeterData.daily[0]?.poscount}/${speedoMeterData.daily[0]?.totalMetrics})` : 0}
                                </span>
                            </Typography>
                        </Grid>
                        <FRECandleStickChart yAxisValues={freDataYaxis15min_d} graphData={data15min} keys={freKeys_d} data={freData?.dailyData} />
                    </Grid>
                    <Grid className='' style={{ width: "23%", marginRight: '5px' }}>
                        <Grid style={{ background: '#000', display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ color: getColor(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.weekly[0]?.percentage?.toFixed(2) : 0), marginLeft: "3px" }} className='font-futura'>
                                <span>  {getHold(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.weekly[0]?.percentage : 0)}</span>
                                <span className='mr-1' style={{ fontSize: '13px' }}>  ({Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.weekly[0]?.percentage?.toFixed(2) : 0}%)</span>
                            </Typography>
                            <Typography noWrap align="center" style={{ color: '#fff' }} className='font-futura'>
                                <span className="text-xs font-black font-futura" style={{ color: "#DA2424" }}>
                                    {Object.values(speedoMeterData)?.length > 0 ? `(${speedoMeterData.weekly[0]?.negcount}/${speedoMeterData.weekly[0]?.totalMetrics})` : 0}
                                </span>
                                {getArrow(speedoMeterData.weekly[0]?.arrow)}
                                <span className="text-xs font-black font-futura" style={{ color: "#339249" }}>
                                    {Object.values(speedoMeterData)?.length > 0 ? `(${speedoMeterData.weekly[0]?.poscount}/${speedoMeterData.weekly[0]?.totalMetrics})` : 0}
                                </span>
                            </Typography>
                        </Grid>
                        <FRECandleStickChart30min yAxisValues={freDataYaxis30min_w} graphData={data30min} from30={true} keys={freKeys_w} data={freData?.weeklyData} />
                    </Grid>
                    <Grid className='' style={{ width: "20%" }}>
                        <Grid style={{ background: '#000', display: 'flex', justifyContent: 'center', paddingTop: '3px',alignItems:'end' }}>
                            <Typography style={{ color: getColor(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.monthly[0]?.percentage?.toFixed(2) : 0), marginLeft: "3px" }} className='font-futura'>
                                <span style={{fontSize:'14px'}} >  {getHold(Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.monthly[0]?.percentage : 0)}</span>
                                <span className='mr-1' style={{ fontSize: '13px' }}>( {Object.values(speedoMeterData)?.length > 0 ? speedoMeterData.monthly[0]?.percentage?.toFixed(2) : 0}%)</span>

                            </Typography>
                            <Typography noWrap align="center" style={{ color: '#fff', fontSize: '11px' }} className='font-futura'>
                                <span className="text-xs font-black font-futura" style={{ color: "#DA2424" }}>
                                    {Object.values(speedoMeterData)?.length > 0 ? `(${speedoMeterData.monthly[0]?.negcount}/${speedoMeterData.monthly[0]?.totalMetrics})` : 0}
                                </span>
                                {getArrow(speedoMeterData.monthly[0]?.arrow)}
                                <span className="text-xs font-black font-futura" style={{ color: "#339249" }}>
                                    {Object.values(speedoMeterData)?.length > 0 ? `(${speedoMeterData.monthly[0]?.poscount}/${speedoMeterData.monthly[0]?.totalMetrics})` : 0}
                                </span>
                            </Typography>
                        </Grid>
                        <FRECandleStickChart1hr yAxisValues={freDataYaxis_m} graphData={data1hr} keys={freKeys_m} data={freData?.monthlyData} />
                    </Grid>
                </Grid> */}
                </> : <Typography>Loading...</Typography>}
        </>
    )
}

export default IndiceCandleGraphs