// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
//import { MuiPickersUtilsProvider } from '@material-ui/pickers';
//import DateFnsUtils from '@date-io/date-fns';
//import InernalErrorEventHandler from './pages/InernalErrorEventHandler';
import './assets/styles/main.css'
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { ThemeProvider } from '@material-ui/core/styles'
import Routes from './Routes';
import { globalTheme } from './components/Themes/Themeing';




function App() {
  return (
    <>
      <CssBaseline />
      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
        <ThemeProvider theme={globalTheme} >
          <Router>
            {/* <InernalErrorEventHandler /> */}
            <Routes />
          </Router>
        </ThemeProvider>
      {/* </MuiPickersUtilsProvider> */}
    </>
  );
}

export default App;
