import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { purple, teal, } from '@material-ui/core/colors';

const theme = createTheme({
    palette: {
        primary: {
            main: '#0155A7',
            light: '#1BB934'
            //purple[600],
        },
        secondary: {
            main: teal[500],
        },
    },
});


export const globalTheme = responsiveFontSizes(theme);