import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper, Typography } from '@mui/material';
// import visionVideo from "../../assets/videos/ab_1.mp4";
// import visionVideo1 from "../../assets/videos/ab_2.mp4";
import visionVideo2 from "../../assets/videos/videoMain.mp4";
import { Box } from '@material-ui/core';

const items = [
    {
        title: "Vision",
        description: "An in-depth analysis of stock markets with the trends, industry insights, and financial analysis from our team of dedicated and experienced Market Analysts and Researchers.",
        videoUrl: visionVideo2
    },
    {
        title: "Mission",
        description: "We strive to provide comprehensive insights to help investors make informed decisions.",
        videoUrl: visionVideo2
    },
    {
        title: "Philosophy",
        description: "Our philosophy revolves around transparency, dedication, and excellence.",
        videoUrl: visionVideo2
    }
];
const VideoCarousel = () => {
    return (
        <Carousel
            animation="fade"
            interval={5000}  // 10 seconds interval
            navButtonsAlwaysVisible
        >
            {items.map((item, index) => (
                <VideoSlide key={index} item={item} />
            ))}
        </Carousel>
    );
}

const VideoSlide = ({ item }) => {
    return (
        <div>
            <Paper sx={{
                backgroundColor:'#ffffff78',
                position: 'relative',
                overflow: 'hidden',
                height: '300px',
                borderRadius: '8px',
                boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
            }}>
                {/* Background video */}
                <video autoPlay loop muted src={item.videoUrl} style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    zIndex: -1,
                    filter: 'brightness(0.7)'

                }}/>
                   
                  {/* Dark overlay to improve text contrast */}
            <Box sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay
                zIndex: 0,
            }} />

                {/* Overlay content */}
                <Box sx={{
                    position: 'relative',
                    zIndex: 1,
                    color: '#fff',
                    textAlign: 'center',
                    padding: '20px',
                    background: 'rgba(0, 0, 0, 0.5)',
                    backdropFilter: 'blur(5px)',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100%',

                }} style={{display:'grid',alignItems:'center'}} >
                    <Typography variant="h3" component="h2" gutterBottom sx={{ fontWeight: 'bold', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)',color:'wheat' }}className='flex justify-center'>
                        {item.title}
                    </Typography>
                    <Typography variant="body1" sx={{ maxWidth: '600px', margin: '0 auto', lineHeight: '1.6', textShadow: '1px 1px 3px rgba(0, 0, 0, 0.7)', }}>
                        {item.description}
                    </Typography>
                </Box>
            </Paper>
        </div>
    );
}

export default VideoCarousel;