import MatCustomInput from './InputField';
import MatCheckbox from './CheckboxField';
import MatSelect  from './SelectBox';
import React from 'react';
import { Typography } from '@material-ui/core';
import MatRadioButton from './RadioField';

const GenerateForms = ({ argsMeta }) => {
  return (
    <>
      {argsMeta.map((data) => {
        const { componentNm, ...compProps } = data;
        if (componentNm === 'checkbox') {
          return (
            <MatCheckbox key={compProps.name} {...compProps} />
          );
        } else if (componentNm === 'select') {
          return <MatSelect key={compProps.name}  {...compProps} />;
        } else if (componentNm === 'textfield') {
          return <MatCustomInput key={compProps.name} {...compProps} />;
        } else if (componentNm === 'radio') {
          return <MatRadioButton key={compProps.name}  {...compProps} />;
        }
        return (
          <Typography className="text-red-700 text-lg">
            Something went wrong !
          </Typography>
        );
      })}
    </>
  );
};

export default React.memo(GenerateForms);
