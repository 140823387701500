import axios from "axios";
import { baseURL } from "../../utils/config";


const getToken = () => {
    return localStorage.getItem('token') || "";
}
export const ApiService = axios.create({
    baseURL: `${baseURL}`,
  });

ApiService.interceptors.request.use(
    config => {
        const token = getToken();
        if(token){
            config.headers['Authorization'] = 'Bearer ' + token
            config.headers['Content-Type'] = 'application/json'
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

ApiService.interceptors.response.use(
    response => {
        const res = {data : response.data,status : response.status}
        return res
    },
    error => {
        const errorRes = {data : error.response.data,status : error.response.status}
        return Promise.reject(errorRes)
    }
)

export const loginApi = async (payload) => {
  return await ApiService.post("/login", payload).then((res) => {return res}).catch((err) => {return err})
};

export const findAll = async() => {
    return ApiService.get('/findAll').then(res => {return res}).catch(err => {return err})
}

export const signUpApi = async (payload) => {
    return await ApiService.post("/signUp", payload).then((res) => {return res}).catch((err) => {return err})
};

export const logOutApi = async () => {
    return await ApiService.post("/logoutuser", ).then((res) => {return res}).catch((err) => {return err})
};