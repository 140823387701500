import React, { useState, useEffect } from 'react'; // Ensure useEffect is imported
import axios from 'axios';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: '#f0f4f8',
  },
  form: {
    backgroundColor: '#ffffff',
    borderRadius: '16px',
    boxShadow: '0 4px 16px rgba(0,0,0,0.1)',
    padding: '40px',
    width: '100%',
    maxWidth: '400px',
    boxSizing: 'border-box',
    textAlign: 'center',
  },
  logo: {
    marginBottom: '20px',
  },
  heading: {
    fontSize: '22px',
    color: '#333333',
    fontWeight: '600',
  },
  subheading: {
    fontSize: '14px',
    color: '#777777',
    marginBottom: '20px',
  },
  inputContainer: {
    position: 'relative',
    marginBottom: '15px',
  },
  input: {
    width: '100%',
    padding: '12px 45px 12px 15px',
    borderRadius: '8px',
    border: '1px solid #d0d0d0',
    boxSizing: 'border-box',
    fontSize: '14px',
  },
  icon: {
    position: 'absolute',
    right: '15px',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '18px',
    color: '#999',
    cursor: 'pointer',
  },
  button: {
    width: '100%',
    padding: '12px',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '8px',
    color: '#ffffff',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  buttonHover: {
    backgroundColor: '#0056b3',
  },
  message: {
    marginTop: '15px',
    color: 'rgb(41, 189, 106)',
    textAlign: 'center',
    fontSize: '14px',
  },
  redirectButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#ffffff',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '14px',
    marginTop: '15px',
  },
  redirectButtonHover: {
    backgroundColor: '#0056b3',
  },
  redirectLink: {
    textDecoration: 'none',
  },
};

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    // Retrieve the token from the URL query parameters
    setToken(new URLSearchParams(window.location.search).get('token') || '');
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    if (!token) {
      setMessage('Reset token is missing');
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post('https://dev-api.tradabulls.com/userService/reset-password', { token, password });
      setMessage(response.data.message || 'Password reset successful!');
    } catch (error) {
      setMessage(error.response?.data?.message || 'An error occurred while resetting the password.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.form}>
        {/* Logo */}
        <img src="/static/media/newtradabullslogo.bf98cdb904695a3ae346.png" alt="TradaBulls Logo" style={styles.logo} />

        {/* Heading */}
        <h2 style={styles.heading}>It's Okay! Reset your Password.</h2>
        <p style={styles.subheading}>Please enter your new password</p>

        {/* Form */}
        <form onSubmit={handleSubmit}>
          <div style={styles.inputContainer}>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={styles.input}
            />
            <span onClick={togglePasswordVisibility} style={styles.icon}>
              {showPassword ? '🙈' : '👁️'}
            </span>
          </div>

          <div style={styles.inputContainer}>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              style={styles.input}
            />
            <span onClick={togglePasswordVisibility} style={styles.icon}>
              {showPassword ? '🙈' : '👁️'}
            </span>
          </div>

          <button
            type="submit"
            disabled={loading}
            style={loading ? { ...styles.button, opacity: 0.7 } : styles.button}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor)}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = styles.button.backgroundColor)}
          >
            {loading ? 'Submitting...' : 'Change Password'}
          </button>
        </form>

        {message && <p style={styles.message}>{message}</p>}
        {message && (
          <a href="https://tradabulls.com" style={styles.redirectLink}>
            <button
              style={styles.redirectButton}
              onMouseOver={(e) => (e.currentTarget.style.backgroundColor = styles.redirectButtonHover.backgroundColor)}
              onMouseOut={(e) => (e.currentTarget.style.backgroundColor = styles.redirectButton.backgroundColor)}
            >
              Go to Login
            </button>
          </a>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
