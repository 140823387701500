
import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import axios from 'axios';

const IndexMoversPieChart = (props) => {
    const { stockData, loading } = props;
    const genPieData = (stockData) => {
        const totalValue = stockData?.reduce((sum, item) => sum + Math.abs(item?.contribution), 0);
        const legendData = [];
        const seriesData = stockData?.map((item) => {
            const companySymbol = item?.companyData.companySymbol;
            const value = Math.abs(item?.contribution);
            const percentage = (totalValue > 0 ? (value / totalValue) * 100 : 0).toFixed(2);
            legendData?.push(companySymbol);
            return {
                name: companySymbol,
                value: value?.toFixed(2),
                percentage: percentage,
                itemStyle: { color: item?.contribution > 0 ? '#4caf50' : '#f44336' } // Green for positive, red for negative
            };
        });

        return { legendData, seriesData };
    };

    const pieData = genPieData(stockData);

    const getOption = () => ({
        tooltip: {
            trigger: 'item',
            formatter: '{b}: {c} ({d}%)',
        },
        // legend: {
        //   type: 'scroll',
        //   orient: 'vertical',
        //   right: 10,
        //   top: 20,
        //   bottom: 20,
        //   data: pieData.legendData,
        // },
        series: [
            {
                name: 'Stock Contribution',
                type: 'pie',
                radius: '55%',
                center: ['45%', '50%'],
                data: pieData.seriesData,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 1)',
                    },
                },
                label: {
                    formatter: '{b}\n{d}%',
                },
                itemStyle: {
                    borderColor: '#fff', // White border between slices
                    borderWidth: 1, // Adjust the width of the border
                },
            },
        ],
    });

    return (
        <div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <ReactEcharts option={getOption()} style={{ height: '400px', width: '400px' }} />
            )}
        </div>
    );
};

export default IndexMoversPieChart;