export const MailIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M2 10V9C2 6.23858 4.23858 4 7 4H17C19.7614 4 22 6.23858 22 9V15C22 17.7614 19.7614 20 17 20H7C4.23858 20 2 17.7614 2 15V14" stroke="#154A72" stroke-width="1.5" stroke-linecap="round" />
        <path d="M6 9L10.8 12.6C11.5111 13.1333 12.4889 13.1333 13.2 12.6L18 9" stroke="#154A72" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

export const PasswordIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M4 13.5V13C4 10.7909 5.79086 9 8 9H16C18.2091 9 20 10.7909 20 13V17C20 19.2091 18.2091 21 16 21H8C5.79086 21 4 19.2091 4 17V16.5" stroke="#2B3F6C" stroke-width="1.5" stroke-linecap="round" />
        <path d="M12 16L12 14" stroke="#2B3F6C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16 9V7C16 4.79086 14.2091 3 12 3V3C9.79086 3 8 4.79086 8 7L8 9" stroke="#2B3F6C" stroke-width="1.5" />
    </svg>
)

export const UserSvgIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
        <path d="M16.9815 17.5V15.8333C16.9815 14.9493 16.6303 14.1014 16.0052 13.4763C15.3801 12.8512 14.5322 12.5 13.6482 12.5H6.98153C6.09747 12.5 5.24963 12.8512 4.6245 13.4763C3.99938 14.1014 3.64819 14.9493 3.64819 15.8333V17.5" stroke="#154A72" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M10.315 9.16667C12.156 9.16667 13.6484 7.67428 13.6484 5.83333C13.6484 3.99238 12.156 2.5 10.315 2.5C8.47407 2.5 6.98169 3.99238 6.98169 5.83333C6.98169 7.67428 8.47407 9.16667 10.315 9.16667Z" stroke="#154A72" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

export const PhoneSvgIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M18.5197 14.0999V16.5999C18.5207 16.832 18.4731 17.0617 18.3801 17.2744C18.2872 17.487 18.1508 17.6779 17.9798 17.8348C17.8088 17.9917 17.6069 18.1112 17.387 18.1855C17.1672 18.2599 16.9342 18.2875 16.7031 18.2666C14.1387 17.988 11.6756 17.1117 9.51139 15.7083C7.49791 14.4288 5.79083 12.7217 4.51139 10.7083C3.10303 8.53426 2.22659 6.05908 1.95305 3.48325C1.93223 3.25281 1.95962 3.02055 2.03347 2.80127C2.10732 2.58199 2.22603 2.38049 2.38202 2.2096C2.53802 2.03871 2.72789 1.90218 2.93955 1.80869C3.1512 1.7152 3.38 1.6668 3.61139 1.66658H6.11139C6.51581 1.6626 6.90788 1.80582 7.21452 2.06953C7.52116 2.33324 7.72145 2.69946 7.77805 3.09992C7.88357 3.89997 8.07926 4.68552 8.36139 5.44158C8.47351 5.73985 8.49777 6.06401 8.43131 6.37565C8.36485 6.68729 8.21044 6.97334 7.98639 7.19992L6.92805 8.25825C8.11435 10.3445 9.84176 12.072 11.9281 13.2583L12.9864 12.1999C13.213 11.9759 13.499 11.8215 13.8107 11.755C14.1223 11.6885 14.4464 11.7128 14.7447 11.8249C15.5008 12.107 16.2863 12.3027 17.0864 12.4083C17.4912 12.4654 17.8609 12.6693 18.1252 12.9812C18.3894 13.2931 18.5299 13.6912 18.5197 14.0999Z" stroke="#154A72" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

export const EyeSvgIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
        <g clip-path="url(#clip0_372_7299)">
            <path d="M1.7356 10.0002C1.7356 10.0002 5.06893 3.3335 10.9023 3.3335C16.7356 3.3335 20.0689 10.0002 20.0689 10.0002C20.0689 10.0002 16.7356 16.6668 10.9023 16.6668C5.06893 16.6668 1.7356 10.0002 1.7356 10.0002Z" stroke="#154A72" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.9023 12.5C12.2831 12.5 13.4023 11.3807 13.4023 10C13.4023 8.61929 12.2831 7.5 10.9023 7.5C9.52163 7.5 8.40234 8.61929 8.40234 10C8.40234 11.3807 9.52163 12.5 10.9023 12.5Z" stroke="#154A72" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_372_7299">
                <rect width="20" height="20" fill="white" transform="translate(0.902344)" />
            </clipPath>
        </defs>
    </svg>
)
export const CallIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M14.25 3.75L11.25 6.75M11.25 6.75V4.5M11.25 6.75H13.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.6671 10.9108L11.3255 11.2704C11.3255 11.2704 10.5136 12.1252 8.29734 9.79192C6.08109 7.45863 6.89303 6.60381 6.89303 6.60381L7.10814 6.37733C7.63805 5.81943 7.68801 4.92372 7.22568 4.26983L6.27994 2.93221C5.70771 2.12287 4.60197 2.01596 3.94609 2.70648L2.76889 3.94585C2.44367 4.28824 2.22574 4.73209 2.25217 5.22445C2.31978 6.48409 2.85804 9.19429 5.86152 12.3564C9.04661 15.7097 12.0351 15.8429 13.2572 15.7223C13.6438 15.6842 13.9799 15.4757 14.2508 15.1905L15.3163 14.0688C16.0354 13.3117 15.8327 12.0136 14.9125 11.484L13.4796 10.6592C12.8754 10.3115 12.1393 10.4136 11.6671 10.9108Z" fill="white" />
    </svg>
)
export const MessageIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.37868 0.87868C0.5 1.75736 0.5 3.17157 0.5 6C0.5 8.82843 0.5 10.2426 1.37868 11.1213C2.25736 12 3.67157 12 6.5 12H9.5C12.3284 12 13.7426 12 14.6213 11.1213C15.5 10.2426 15.5 8.82843 15.5 6C15.5 3.17157 15.5 1.75736 14.6213 0.87868C13.7426 0 12.3284 0 9.5 0H6.5C3.67157 0 2.25736 0 1.37868 0.87868ZM12.9321 2.6399C13.131 2.87855 13.0988 3.23324 12.8601 3.43212L11.2127 4.80492C10.548 5.35892 10.0092 5.80794 9.53362 6.11379C9.03824 6.43239 8.55581 6.63366 8 6.63366C7.44419 6.63366 6.96176 6.43239 6.46638 6.11379C5.99084 5.80794 5.45203 5.35892 4.78727 4.80493L3.1399 3.43212C2.90124 3.23324 2.869 2.87855 3.06788 2.6399C3.26676 2.40124 3.62145 2.369 3.8601 2.56788L5.47928 3.91719C6.179 4.50028 6.6648 4.90381 7.07494 5.1676C7.47196 5.42294 7.7412 5.50866 8 5.50866C8.2588 5.50866 8.52804 5.42294 8.92506 5.1676C9.3352 4.90381 9.821 4.50028 10.5207 3.91718L12.1399 2.56788C12.3786 2.369 12.7332 2.40124 12.9321 2.6399Z" fill="white" />
    </svg>
)
export const MailPlaceholder = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="18" viewBox="0 0 23 18" fill="none">
        <path d="M1.29787 7V6C1.29787 3.23858 3.53644 1 6.29787 1H16.2979C19.0593 1 21.2979 3.23858 21.2979 6V12C21.2979 14.7614 19.0593 17 16.2979 17H6.29787C3.53644 17 1.29787 14.7614 1.29787 12V11" stroke="#A6BAC9" stroke-width="1.5" stroke-linecap="round" />
        <path d="M6.63829 6L9.53921 9.00757C10.3257 9.82296 11.6318 9.82296 12.4182 9.00757L15.3191 6" stroke="#A6BAC9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)
export const Hide = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M8 11C8 9.34315 9.34315 8 11 8" stroke="#2B3F6C" stroke-width="1.5" stroke-linecap="round" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.75 3C7.23587 3 4.19529 5.7042 2.43057 7.99686L2.39874 8.0382C1.99964 8.55653 1.63206 9.03392 1.38269 9.5984C1.11564 10.2029 1 10.8617 1 11.75C1 12.6383 1.11564 13.2971 1.38269 13.9016C1.63206 14.4661 1.99964 14.9435 2.39875 15.4618L2.43057 15.5031C4.19529 17.7958 7.23587 20.5 11.75 20.5C16.2641 20.5 19.3047 17.7958 21.0694 15.5031L21.1012 15.4618C21.5004 14.9435 21.8679 14.4661 22.1173 13.9016C22.3844 13.2971 22.5 12.6383 22.5 11.75C22.5 10.8617 22.3844 10.2029 22.1173 9.5984C21.8679 9.03391 21.5004 8.55652 21.1012 8.03818L21.0694 7.99686C19.3047 5.7042 16.2641 3 11.75 3ZM3.61922 8.9118C5.24864 6.79492 7.90036 4.5 11.75 4.5C15.5996 4.5 18.2514 6.79492 19.8808 8.9118C20.3194 9.48159 20.5763 9.82206 20.7452 10.2045C20.9032 10.562 21 10.9989 21 11.75C21 12.5011 20.9032 12.938 20.7452 13.2955C20.5763 13.6779 20.3194 14.0184 19.8808 14.5882C18.2514 16.7051 15.5996 19 11.75 19C7.90036 19 5.24864 16.7051 3.61922 14.5882C3.18064 14.0184 2.92374 13.6779 2.75476 13.2955C2.59684 12.938 2.5 12.5011 2.5 11.75C2.5 10.9989 2.59684 10.562 2.75476 10.2045C2.92374 9.82206 3.18063 9.48159 3.61922 8.9118Z" fill="#2B3F6C" />
        <path d="M19.4645 4.46433L4.46448 19.4643" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M19.4645 4.46433L4.46448 19.4643" stroke="#2B3F6C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)
