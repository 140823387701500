import React from "react";
import './youtubevideo.css'

function YoutubeVideos() {

    return (
        <div className="youtube-section">
            <h6>YouTube</h6>
            <div className="youtube-videos">
                <div className="video-responsive">
                    <iframe
                        width="500"
                        height="290"
                        src={`https://www.youtube.com/embed/eNTGEDk8BG4`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <div className="video-responsive">
                    <iframe
                        width="500"
                        height="290"
                        src={`https://www.youtube.com/embed/qTOWQ0B91WU`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <div className="video-responsive">
                    <iframe
                        width="500"
                        height="290"
                        src={`https://www.youtube.com/embed/VENy7ugQz6M`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        </div>
    )
}
export default YoutubeVideos;