import React, { useState } from "react";
import './videobar.css'
import myvideo from "../../assets/videos/CB_final.mp4"
import YoutubeVideos from "./YoutubeVideos";
import About from "./about";
import ContactUs from "./ContactUs";
import Reviews from "./Reviews";
import SiteFooter from "./SiteFooter";
import LandingHeader from "./header";
import QR from "../../assets/images/Qrcode.jpeg"

function VideoBar() {
    const [isHidden, setIsHidden] = useState(false)
    return (
        <div className="main-width">
            <LandingHeader />
            <div className="video-bar">
                <div className="text-container">
                    {/* <div className="video-text"> Analyse. Invest. Grow<span> Using TradaBulls</span></div> */}
                    <div className="empover-text">Empower Your Investment Decisions
                        <span>
                            An Comprehensive Stock Market Research & Data Analysis
                        </span>
                    </div>
                </div>
                <div className="video-play">
                    <video
                        src={myvideo}
                        autoPlay
                        loop
                        muted
                    />
                </div>
            </div>
            <YoutubeVideos />
            <About />
            <ContactUs />
            <Reviews />
            <SiteFooter />
            <div>
                {!isHidden && (
                    <div className="qr-wrapper">
                        <img src={QR} alt="QR Code" className="qr-code" draggable="false" />
                        <button className="close-btn" onClick={() => setIsHidden(true)}>&times;</button>
                    </div>
                )}

                {isHidden && (
                    <button className="slider-btn" onClick={() => setIsHidden(false)}>
                        Show QR Code
                    </button>
                )}
            </div>
        </div>
    )
}
export default VideoBar;