import { Box, Grid, Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import React, { useState } from 'react'
import NiftyTen from './NiftyTen';
import BankTen from './BankTen';
import ItTen from './ItTen';
import MidCapSelectTen from './MidcapSelectTen';
import FinNiftyTen from './FInNiftyTen';

function TopTens(props) {
    const { tabsList } = props
    const [value, setValue] = useState('0');
    const [label, setLabel] = useState(tabsList[0].label);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        const selectedTab = tabsList.find(e => e.value === newValue);
        setLabel(selectedTab ? selectedTab.label : tabsList[0].label);
        // fetchData(filterToken(newValue), indexTime); // Fetch data on tab change
    };

    return (
        <Grid className="im-container">
            <TabContext value={value}>
                <Box className="im-tablist" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        {tabsList?.map((e, i) => (
                            <Tab label={e?.label} value={e?.value} style={{ minWidth: "fit-content" }} />
                        ))}
                    </TabList>
                </Box>
                <TabPanel value="0" className='px-3 w-full' style={{ paddingTop: "3.2rem" }}>
                    <NiftyTen />
                </TabPanel>
                <TabPanel value="1" className='px-4 w-full' style={{ paddingTop: "3.2rem" }}>
                    <BankTen />
                </TabPanel>
                <TabPanel value="2" className='px-4 w-full' style={{ paddingTop: "3.2rem" }}>
                    <ItTen />
                </TabPanel>
                <TabPanel value="3" className='px-4 w-full' style={{ paddingTop: "3.2rem" }}>
                    <MidCapSelectTen />
                </TabPanel>
                <TabPanel value="4" className='px-4 w-full' style={{ paddingTop: "3.2rem" }}>
                    <FinNiftyTen />
                </TabPanel>
            </TabContext>
        </Grid>
    )
}

export default TopTens