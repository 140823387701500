import React, { useState } from 'react';
import axios from 'axios';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '50vh',
    backgroundColor: '#e2e8f0', // Semi-transparent background
    padding: '20px',
  },
  form: {
    backgroundColor: '#ffffff',
    borderRadius: '16px',
    boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
    padding: '30px',
    width: '100%',
    maxWidth: '450px',
    boxSizing: 'border-box',
    border: '1px solid #e0e0e0',
    position: 'relative',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  },
  formHover: {
    transform: 'translateY(-10px)',
    boxShadow: '0 12px 24px rgba(0,0,0,0.2)',
  },
  closeButton: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    cursor: 'pointer',
    fontSize: '20px',
    color: '#999999',
  },
  logoContainer: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  logo: {
    //width: '100px', // Adjust the size of the logo here
    height: 'auto',
    margin: '0 auto',
  },
  heading: {
    marginBottom: '15px',
    fontSize: '24px',
    color: '#333333',
    textAlign: 'center',
    fontWeight: '700',
  },
  subHeading: {
    marginBottom: '25px',
    fontSize: '16px',
    color: '#666666',
    textAlign: 'center',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    borderRadius: '8px',
    border: '1px solid #d0d0d0',
    padding: '8px 12px',
  },
  inputIcon: {
    marginRight: '8px',
    fontSize: '18px',
    color: '#666666',
  },
  input: {
    width: '100%',
    border: 'none',
    fontSize: '16px',
    outline: 'none',
  },
  button: {
    width: '100%',
    padding: '14px',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '8px',
    color: '#ffffff',
    fontSize: '18px',
    cursor: 'pointer',
    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
    transition: 'background-color 0.3s ease, transform 0.3s ease',
  },
  buttonHover: {
    backgroundColor: '#0056b3',
    transform: 'scale(1.03)',
  },
  link: {
    marginTop: '20px',
    display: 'block',
    textAlign: 'center',
    color: '#007bff',
    textDecoration: 'none',
    fontSize: '16px',
  },
  messageContainer: {
    textAlign: 'center',
  },
  messageIcon: {
    fontSize: '50px',
  },
  successIcon: {
    color: '#28a745',
  },
  errorIcon: {
    color: '#dc3545',
  },
  messageText: {
    marginTop: '20px',
    fontSize: '20px',
  },
  successText: {
    color: '#28a745',
  },
  errorText: {
    color: '#dc3545',
  },
  loaderContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
  },
  loader: {
    display: 'inline-block',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    position: 'relative',
    animation: 'bounce 1.5s infinite ease-in-out',
  },
  dot: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    background: 'rgba(0, 0, 0, 0.1)',
    animation: 'bounceColor 3s infinite linear',
    transformOrigin: 'center',
  },
};

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false); // State to track success or error

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    try {
      await axios.post('https://dev-api.tradabulls.com/userService/forgetpassword', { email });
      setIsSuccess(true);
      setMessage('Email sent successfully!');
    } catch (error) {
      setIsSuccess(false);
      setMessage('An error occurred while sending the reset link.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      {message ? (
        <div style={styles.messageContainer}>
          <div
            style={{
              ...styles.messageIcon,
              ...(isSuccess ? styles.successIcon : styles.errorIcon),
            }}
          >
            {isSuccess ? '✔️' : '❌'}
          </div>
          <p
            style={{
              ...styles.messageText,
              ...(isSuccess ? styles.successText : styles.errorText),
            }}
          >
            {message}
          </p>
        </div>
      ) : (
        <div
          style={{ ...styles.form, ...(isHovered && styles.formHover) }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {loading && (
            <div style={styles.loaderContainer}>
              <div style={styles.loader}>
                <div style={styles.dot}></div>
              </div>
            </div>
          )}
          <div style={styles.closeButton}>&times;</div>
          
          {/* Logo Section */}
          <div style={styles.logoContainer}>
            <img src="/static/media/newtradabullslogo.bf98cdb904695a3ae346.png" alt="Logo" style={styles.logo} />
          </div>

          <h2 style={styles.heading}>It's Okay! Reset your Password.</h2>
          <p style={styles.subHeading}>Please enter your email address</p>
          <form onSubmit={handleSubmit}>
            <div style={styles.inputContainer}>
              <i style={styles.inputIcon} className="fas fa-envelope"></i>
              <input
                type="email"
                placeholder=" Email ID"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={styles.input}
              />
            </div>
            <button
              type="submit"
              style={styles.button}
              onMouseOver={(e) => (e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor)}
              onMouseOut={(e) => (e.currentTarget.style.backgroundColor = styles.button.backgroundColor)}
            >
              Submit
            </button>
          </form>
          <a href="https://tradabulls.com/" style={styles.link}>Back to Login</a>
        </div>
      )}
      <style>
        {`
          @keyframes bounce {
            0%, 20%, 50%, 80%, 100% {
              transform: translateY(0);
            }
            40% {
              transform: translateY(-30px);
            }
            60% {
              transform: translateY(-15px);
            }
          }

          @keyframes bounceColor {
            0% {
              background-color: red;
            }
            33% {
              background-color: blue;
            }
            66% {
              background-color: green;
            }
            100% {
              background-color: red;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ForgetPassword;
