import React, { useEffect } from "react";
import "./SingleCandleStick.css";
import { Tooltip, Typography } from "@material-ui/core";
import { ArrowDownward, ArrowUpward, Height } from "@material-ui/icons";
import { useState } from "react";

const SingleCandleStick = (props) => {
    const { open, high, low, close, name, prev_range, range_bound, arrow, poscount, negcount } = props
    const [high_v, setHigh_v] = useState(0)
    const [low_v, setLow_v] = useState(0)
    const color = close >= open ? "#339249" : "#DA2424"
    const h_l_diff = high_v - low_v
    const g_left_perc = ((open - low_v) / h_l_diff) * 100
    const g_right_perc = ((high_v - close) / h_l_diff) * 100
    const r_left_perc = ((close - low_v) / h_l_diff) * 100
    const r_right_perc = ((high_v - open) / h_l_diff) * 100
    const l_prec = ((close - low_v) / h_l_diff) * 100
    const h_prec = ((high_v - close) / h_l_diff) * 100
    const getRange = (r) => {
        if (r >= 61.8) {
            return <ArrowUpward color="secondary" style={{ fontSize: "18px" }} />
        } else if (r <= 38.2) {
            return <ArrowDownward color="error" style={{ fontSize: "18px" }} />
        } else {
            return <Height color="action" style={{ rotate: "90deg" }} />
        }
    }

    useEffect(() => {
        let h = Math.max(high, open, close)
        let l = Math.min(low, open, close)
        setHigh_v(h)
        setLow_v(l)
    }, [high, low])

    const getArrow = (str) => {
        switch (str) {
            case "UP": return <ArrowUpward color="secondary" style={{ fontSize: "18px", color:"#339249"}} />
            case "DOWN": return <ArrowDownward style={{ fontSize: "18px", color : "#DA2424" }} />
            case "NORMAL": return <Height color="action" style={{ rotate: "90deg" }}/>
            default:
                break;
        }
    }
    return (
        <div className={`slider-container ${props?.bg ? "" : "bg-white"} flex flex-col`} style={{ backgroundColor: `${props.bg ? props.bg : ""}`, paddingTop: "15px" }}>
            {props.name ? <div>
                <Typography className="font-black mb-1" style={{ color: "#154A72" }}>{name ? name : "--"}</Typography>
            </div> : ""}
            <div className="w-full flex">
                <div className="labels flex-col" style={{ marginTop: "-10px" }}>
                    <Tooltip title={`(Close - Low)`} arrow placement="top">
                        <span className="perc mr-1" style={{ color: "#339249", marginLeft: "3px" }}>{l_prec ? l_prec?.toFixed(2) : 0}%</span>
                    </Tooltip>
                    <Tooltip title={`Low:${low_v}`} arrow placement="top">
                        <div className="flex" style={{ marginRight: "30px" }}>
                            <span className="label" style={{ color: "#DA2424" }}>L</span>
                        </div>
                    </Tooltip>
                </div>
                <div className="slider-bar" style={{ margin: "12px -20px 10px -30px" }}>
                    {/* {prev_range &&
                        <div style={{ marginTop: "-1.45rem", marginLeft: "45%" }}>
                            <Tooltip title={`Previous Range ${prev_range}%`} arrow placement="top"><span>{prev_range ? getRange(prev_range) : "0"}</span></Tooltip>
                            <Tooltip title={`Current Range ${l_prec?.toFixed(2)}%`} arrow placement="top"><span>{l_prec ? getRange(l_prec?.toFixed(2)) : "0"}</span></Tooltip>
                            {range_bound?.length > 0  && <Tooltip title={`Range Bound`} arrow placement="top"><span>{getRB(range_bound,close)}</span></Tooltip>}
                        </div>
                    } */}
                    {/* {arrow !== "" && <div style={{ marginTop: "-1.45rem", marginLeft: "25%" }}>
                        <span className="text-xs font-black" style={{color: "#339249"}}>({poscount}/16)</span>
                        {getArrow(arrow)}
                        <span className="text-xs font-black" style={{color: "#DA2424"}}>({negcount}/16)</span>
                        </div>} */}
                    <div className="slider-progress" style={{ backgroundColor: `${color}`, left: color === "#339249" ? `${g_left_perc ? g_left_perc?.toFixed(2) : 0}%` : `${r_left_perc ? r_left_perc?.toFixed(2) : 0}%`, right: color === "#339249" ? `${g_right_perc ? g_right_perc?.toFixed(2) : 0}%` : `${r_right_perc ? r_right_perc?.toFixed(2) : 0}%` }}></div>
                    {color === "#339249" ?
                        <div className="points text-black flex" style={{ marginTop: "5px" }}>
                            <Tooltip title={`Open:${open}`} arrow placement="top">
                                <span className="point" style={{ marginLeft: `${g_left_perc ? g_left_perc?.toFixed(2) : 0}%` }}>O</span>
                            </Tooltip>
                            <Tooltip title={`Close:${close}`} arrow placement="top">
                                <span className="point" style={{ marginRight: `${g_right_perc ? g_right_perc?.toFixed(2) : 0}%` }}>C</span>
                            </Tooltip>
                        </div> :
                        <div className="points text-black flex" style={{ marginTop: "5px" }}>
                            <Tooltip title={`Close:${close}`} arrow placement="top">
                                <span className="point" style={{ marginLeft: `${r_left_perc ? r_left_perc?.toFixed(2) : 0}%` }}>C</span>
                            </Tooltip>
                            <Tooltip title={`Open:${open}`} arrow placement="top">
                                <span className="point" style={{ marginRight: `${r_right_perc ? r_right_perc?.toFixed(2) : 0}%` }}>O</span>
                            </Tooltip>
                        </div>}
                </div>
                <div className="labels flex-col" style={{ marginTop: "-10px" }}>
                    <Tooltip title={`(High - Close)`} arrow placement="top">
                        <span className="perc" style={{ color: "#DA2424", marginRight: "3px" }}>{h_prec ? h_prec?.toFixed(2) : 0}%</span>
                    </Tooltip>
                    <Tooltip title={`High:${high_v}`} arrow placement="top">
                        <div className="flex" style={{ marginLeft: "20px" }}>
                            <span className="label" style={{ color: "#339249" }}>H</span>
                        </div>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default SingleCandleStick;
