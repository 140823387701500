import axios from "axios";
import { tradaAPI } from "../../utils/config";

// let token = localStorage.getItem('token') ? localStorage.getItem('token') : ""

const getToken = () => {
    return localStorage.getItem('token') || "";
}

export const tradaApiService = axios.create({
    baseURL: `${tradaAPI}`,
});
 
tradaApiService.interceptors.request.use(
    async config => {
        const token = getToken();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token
            config.headers['Content-Type'] = 'application/json'
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)
 
tradaApiService.interceptors.response.use(
    response => {
        const res = { data: response.data, status: response.status }
        return res
    },
    error => {
        const errResponse = error?.response?.status
        return errResponse
        // return Promise.reject(error)
    }
)

export const companyApi = async (i,s) => {
    try{
        const response = await tradaApiService.get(`/filter?${i}=true&searchTerm=${s}`);
        return response
    }catch(error){
        throw error
    }
};
export const companyApiData = async (payload) => {
    try{
        if(payload){
            const response = await tradaApiService.get(`/data/${payload}`);
            return response
        }
    }catch(error){
        throw error
    }
};

export const companyZonesData = async (payload) => {
    try{
        if(payload){
            const response = await tradaApiService.get(`/zones/${payload}`);
            return response
        }
    }catch(error){
        throw error
    }
};

export const companyFibonacciData = async (payload) => {
    try{
        if(payload){
            const response = await tradaApiService.get(`/fibonacci/${payload}`);
            return response
        }
    }catch(error){
        throw error
    }
};

export const fetchFAndOData = async (payload) => {
    try{
        if(payload){
            const response = await tradaApiService.get(`/futures/${payload}`);
            return response
        }
    }catch(error){
        throw error
    }
};

export const fetchDataSelected = async (payload) => {
    try{
        if(payload){
            const response = await tradaApiService.get(`/data/${payload}`);
            return response
        }
    }catch(error){
        throw error
    }
};