import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, FormControl, Grid, List, ListItem, ListItemText, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { ExpandMore, KeyboardArrowRight, SearchOutlined } from '@material-ui/icons';
import './Homepage.css';
import { companyApi } from '../apiService/tradaApiService';
import withAuth from '../../utils/withAuth';
import Loader from '../common/Loader';
import { toast, ToastContainer } from 'react-toastify';

const Indexes = [
  {label:"NIFTY 50",value : "nifty50"},
  // {label:"NIFTY IT",value : "itNifty"},
  // {label:"FIN NIFTY",value : "financeNifty"},
  {label:"NEXT 50",value : "next50"},
  {label:"MIDCAP 50",value : "midcap50"},
  {label:"MIDCAP 100",value : "midcap100"},
  {label:"MIDCAP 150",value : "midcap150"},
  // {label:"All",value : "all"},
]

const notify = (sev, str) => toast[sev ? sev : 'info'](str);

const CustomerList = ({ sendDataToParent, loading, setLoading, setToken, ...props }) => {
  const [index, setIndex] = useState('nifty50');
  const [search, setSearch] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [companyList, setCompanyList] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);

  const handleChange = async (event) => {
    setSearch("");
    setIndex(event.target.value);
    await fetchAllCompanies(event.target.value);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearch(searchTerm);

    if (searchTerm !== "") {
      const filtered = companyList.filter(company =>
        company.companyName.toLowerCase().includes(searchTerm) ||
        company.companySymbol.toLowerCase().includes(searchTerm)
      ).sort((a, b) => {
        if (a.companyName.toLowerCase().startsWith(searchTerm) && !b.companyName.toLowerCase().startsWith(searchTerm)) {
          return -1;
        } else if (!a.companyName.toLowerCase().startsWith(searchTerm) && b.companyName.toLowerCase().startsWith(searchTerm)) {
          return 1;
        } else if (a.companySymbol.toLowerCase().startsWith(searchTerm) && !b.companySymbol.toLowerCase().startsWith(searchTerm)) {
          return -1;
        } else if (!a.companySymbol.toLowerCase().startsWith(searchTerm) && b.companySymbol.toLowerCase().startsWith(searchTerm)) {
          return 1;
        } else {
          return 0;
        }
      });
      setFilteredCompanies(filtered);
      setExpanded(true);
    } else {
      setFilteredCompanies(companyList);
      setExpanded(false);
    }
  };

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  const onListClick = async (e) => {
    setSelectedItem(e);
    setExpanded(!expanded);
    sendDataToParent(e);
  };

  const fetchAllCompanies = async function (index) {
    const company = await companyApi(index, "");
    if (company?.data?.success) {
      let _sortedList = company.data.data.sort((a, b) => {
        if (a.companySymbol < b.companySymbol) return -1;
        if (a.companySymbol > b.companySymbol) return 1;
        return 0;
      });
      setCompanyList(_sortedList);
      setFilteredCompanies(_sortedList);
      setSelectedItem(company.data.data[0]);
      props.setDataFromChild(company.data.data[0]);
    } else {
      setCompanyList([]);
      setFilteredCompanies([]);
      setSelectedItem({});
      props.setDataFromChild({});
    }
  };

  useEffect(() => {
    fetchAllCompanies(index);
  }, []);

  return (
    <Grid className='customer-box'>
      <Grid className='search-and-dropdown'>
        <Box>
          <FormControl className='select-dropdown'>
            <Select
              style={{ fontFamily: "Futura PT Book", fontWeight: "bold", display:'flex',width:'100%' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={index}
              onChange={handleChange}
            >
              {Indexes.map((e, i) => (
                <MenuItem
                  style={{ fontSize: "13px", fontFamily: "Futura PT Book", borderBottom: "1px solid lightgrey" }}
                  key={i}
                  value={e.value}
                >
                  {e.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <TextField
          value={search}
          className='list-search'
          id="outlined-search"
          type="search"
          onChange={handleSearch}
          placeholder='Search For Companies'
          InputProps={{
            startAdornment: <SearchOutlined />
          }}
        />
      </Grid>

      
        <List style={{ overflow: 'auto', padding: '0px', height: 'calc(100vh - 185px)' }}>
          {filteredCompanies.map((item) => (
            <ListItem className='listitem' button={true} key={item.companyId} onClick={() => onListClick(item)}>
              <Grid className='list-content'>
                <ListItemText className='list-csymbol' primary={item.companySymbol} />
                <ListItemText className='list-cname' primary={item.companyName} />
              </Grid>
              <KeyboardArrowRight />
            </ListItem>
          ))}
        </List>
      {/* ) : (
        <Accordion expanded={expanded} onChange={toggleAccordion}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography className='details-heading'>
              Select Stock to view the data
              <Grid className="details mt-2">
                <Grid className="details-context"> {selectedItem?.companyName}</Grid>
                <Grid className="details-heading mb-1">{selectedItem?.companySymbol}</Grid>
              </Grid>
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0px', borderTop: '1px solid lightgray' }}>
            <Typography className='accordian-list'>
              {filteredCompanies.map((item) => (
                <ListItem onClick={() => onListClick(item)} className='listitem' button={true} key={item.companyId}>
                  <Grid className='list-content'>
                    <ListItemText className='list-csymbol' primary={item.companySymbol} />
                    <ListItemText className='list-cname' primary={item.companyName} />
                  </Grid>
                  <KeyboardArrowRight />
                </ListItem>
              ))}
            </Typography>
          </AccordionDetails>
        </Accordion>
      )} */}
    </Grid>
  );
};

export default withAuth(CustomerList);
