import React, { useEffect, useMemo, useState } from 'react'
import { IndexMoversData, NiftyPlusData } from '../apiService/tickerApiService'
import { Button, ButtonGroup, FormControlLabel, FormGroup, Grid, Switch, Typography } from '@material-ui/core';
import PosstiveContributors from './PosstiveContributors';
import NegitiveContributors from './NegitiveContributors';
import IndexmoversChart from '../common/IndexmoversChart';
import IndexMoversPieChart from '../common/IndexMoversPieChart';
import { isBetween9AMAnd340PM } from '../../utils';
import MetricCandleStick from '../common/MetricCandleStick';
import "./Indexmovers.css"

const indexTimeFrames = [
    { value: 'D', label: 'D', name: "Daily" },
    { value: 'W', label: 'W', name: "Weekly" },
    { value: 'M', label: 'M', name: "Monthly" },
    { value: 'Q', label: 'Q', name: "Quarterly" },
    { value: 'H', label: 'HY', name: "Half Yearly" },
    { value: 'Y', label: 'Y', name: "Yearly" }
];

function GraphAndPieChart(props) {
    const { indice, label } = props
    const [indexTime, setIndexTime] = useState('D');
    const [candlesData, setCandlesData] = useState([])
    const [pc, setPc] = useState([]);
    const [nc, setNc] = useState([]);
    const [totalArrLength, setTotalArrLength] = useState(0);
    const [view, setView] = useState(false)
    const [stockData, setData] = useState([]);
    const [loading, setLoading] = useState(true);


    const [indexTimeLable, setIndexTimeLable] = useState('Daily');

    const onClickTimeFrame = (item) => {
        setIndexTime(item.value);
        setIndexTimeLable(item.name)
        // fetchData(_filterToken, item.value); // Fetch data on time frame change
    };


    const indiceDataForCandles = async (i, tf) => {
        let _c = await NiftyPlusData(tf)
        switch (i) {
            case "nifty50":
                const _candlesData = _c.data.filter((e, i) => e.companyData.companySymbol !== "FINNIFTY" && e.companyData.companySymbol !== "NEXT50" && e.companyData.companySymbol !== "MIDCAPSELECT")
                const order = ["NIFTY50", "BANKNIFTY", "ITNIFTY", "RELIANCE"];
                _candlesData?.sort((a, b) => {
                    return order.indexOf(a.companyData.companySymbol) - order.indexOf(b.companyData.companySymbol);
                })
                setCandlesData(_candlesData)
                fetchDataForComponent(indice, _candlesData[0]?.currentprice, indexTime)
                break
            case "banknifty":
                const _bankNiftyData = _c.data.filter((e, i) => e.companyData.companySymbol === "BANKNIFTY")
                setCandlesData(_bankNiftyData)
                fetchDataForComponent(indice, _bankNiftyData[0]?.currentprice, indexTime)
                break
            case "itnifty":
                const _itNiftyData = _c.data.filter((e, i) => e.companyData.companySymbol === "ITNIFTY")
                setCandlesData(_itNiftyData)
                fetchDataForComponent(indice, _itNiftyData[0]?.currentprice, indexTime)
                break
            case "next50":
                const _next50Data = _c.data.filter((e, i) => e.companyData.companySymbol === "NEXT50")
                setCandlesData(_next50Data)
                fetchDataForComponent(indice, _next50Data[0]?.currentprice, indexTime)
                break
            case "finnifty":
                const _finNiftyData = _c.data.filter((e, i) => e.companyData.companySymbol === "FINNIFTY")
                setCandlesData(_finNiftyData)
                fetchDataForComponent(indice, _finNiftyData[0]?.currentprice, indexTime)
                break
            case "midcapselect":
                const _midcap = _c.data.filter((e, i) => e.companyData.companySymbol === "MIDCAPSELECT")
                setCandlesData(_midcap)
                fetchDataForComponent(indice, _midcap[0]?.currentprice, indexTime)
                break
            default:
                setCandlesData([])
                break
        }
    }

    const getPerc = (cmp, pc) => {
        let a = ((cmp - pc) / pc) * 100
        return a?.toFixed(1)
    }

    const fetchDataForComponent = async (i, cmp, tf) => {
        const _IndexMoversRes = await IndexMoversData(i, cmp, tf);
        const positiveContributions = _IndexMoversRes?.data?.filter(item => item.contribution > 0);
        const negativeContributions = _IndexMoversRes?.data?.filter(item => item.contribution < 0);
        setPc(positiveContributions?.sort((a, b) => b.contribution - a.contribution) || []);
        setNc(negativeContributions?.sort((a, b) => a.contribution - b.contribution) || []);
        setData(_IndexMoversRes.data)
        setTotalArrLength(_IndexMoversRes?.data?.length)
        setLoading(false)
    }

    useEffect(() => {
        let tradinTime = isBetween9AMAnd340PM()
        let intervalId;
        if (indice && indexTime) {
            intervalId = setInterval(() => {
                indiceDataForCandles(indice, indexTime)
            }, 4000);
        }

        return () => {
            clearInterval(intervalId)
        }
    }, [indice, indexTime])

    const onViewClick = (e) => {
        setView(e.target.checked)
    }

    useMemo(() => {
        if (indice && indexTime) indiceDataForCandles(indice, indexTime)
    }, [indice, indexTime])

    return (
        <Grid className='w-full bg-white'>
            <Grid className="w-full flex justify-start">
                <Grid className="w-1/5 flex flex-col items-center " style={{ borderBottom: '10px' }}>
                    {totalArrLength && candlesData?.map((s, index) => {
                        return (
                            <Grid className='niftyplus flex w-full gap-1 -ml-2' style={{ borderBottom: index == candlesData?.length - 1 ? "2px solid gray" : "" }}>
                                <Grid className='w-full border-timeframe indice-border '>
                                    <Grid className='indice-border'>
                                        <Grid className="flex justify-center">
                                            <Typography variant='body2' className="font-semibold flex whitespace-no-wrap" align="center">{s?.companyData?.companySymbol}
                                                <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px', color: `${s?.currentprice > s?.pre_close ? "#154A72" : "#DA2424"}` }}>{s?.currentprice}</span>
                                                <span className="flex items-center" style={{ fontSize: '10px', color: `${s?.currentprice > s?.pre_close ? "#154A72" : "#DA2424"}` }}>({(s?.currentprice - s?.pre_close).toFixed(1)})({getPerc(s?.currentprice, s?.pre_close)}%)</span>
                                            </Typography>
                                        </Grid>
                                        <Grid className='im-ohlc flex w-full'>
                                            <Grid className="im-lc w-1/3">
                                                <Typography align="center" className="high-color">C.R: {((s?.current_high >= s?.ticker_high ? s?.current_high : s?.ticker_high) - (s?.current_low >= s?.ticker_low ? s?.ticker_low : s?.current_low))?.toFixed(1)}</Typography>
                                                <Typography align="center" className={(s?.pre_high - s?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {(s?.pre_high - s?.pre_low)?.toFixed(2)}</Typography>
                                            </Grid>
                                            <Grid className="im-oh w-1/3">
                                                <Typography align="center" className="high-color">O: {s?.current_open == 0 ? s?.ticker_open : s?.current_open?.toFixed(1)}</Typography>
                                                <Typography align="center" className="high-color">P.C: {s?.pre_close?.toFixed(1)}</Typography>
                                            </Grid>
                                            <Grid className="im-rp w-1/3">
                                                <Typography align="center" className="high-color">H: {s?.current_high >= s?.ticker_high ? s?.current_high?.toFixed(1) : s?.ticker_high?.toFixed(1)}</Typography>
                                                <Typography align="center" className="low-color">L: {s?.current_low >= s?.ticker_low ? s?.ticker_low?.toFixed(1) : s?.current_low == "0" ? s?.ticker_low?.toFixed(1) : s?.current_low?.toFixed(1)} </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid className="standard-observ-indices">
                                            <MetricCandleStick open={s?.current_open == 0 ? s?.ticker_open : s?.current_open} high={s?.current_high >= s?.ticker_high ? s?.current_high : s?.ticker_high} low={s?.current_low >= s?.ticker_low ? s?.ticker_low : s?.current_low == "0" ? s?.ticker_low : s?.current_low}
                                                close={s?.current_close} poscount={s?.poscount} negcount={s?.negcount} arrow={s?.arrow} totalMetrics={s?.totalMetrics} prev_range={s?.pre_percentage}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>)
                    })}
                </Grid>
                {candlesData?.length > 0 ? <Grid className="flex flex-col w-4/5" >
                    <Grid className="tp-header bg-white mt-1" align="center">
                        <Grid className="flex justify-center" >
                            <span className="label-header" style={{ fontFamily: "Futura PT Heavy" }}>{label} Contributors
                                <span style={{ color: `${candlesData[0]?.currentprice > candlesData[0]?.pre_close ? "#154A72" : "#DA2424"}`, fontSize: '15px', marginLeft: '5px' }}>
                                    {candlesData[0]?.currentprice} <span style={{ fontSize: "11px", fontWeight: "bold" }}>({(candlesData[0]?.currentprice - candlesData[0]?.pre_close).toFixed(1)})({getPerc(candlesData[0]?.currentprice, candlesData[0]?.pre_close)}%)</span>
                                    <span style={{ fontSize: '11px', margin: '0px 2px', color: '#595e62' }}>( {indexTimeLable} )</span>
                                </span>
                            </span>
                            <Grid className="tf-indexmovers">
                                <Grid>
                                    <ButtonGroup className="button-grp" aria-label="Basic button group">
                                        {indexTimeFrames.map((item) => (
                                            <Button value={item.value} onClick={() => onClickTimeFrame(item)} key={item.value} className={indexTime === item.value ? 'selected group-button' : 'group-button'}>
                                                {item.label}
                                            </Button>
                                        ))}
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                            <Grid style={{ marginLeft: '10px' }}>
                                <FormGroup className="switch-view" >
                                    <FormControlLabel control={<Switch size="small" onClick={onViewClick} />} label="Table View" />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                    {view ? <Grid className="contributors flex justify-center pl-4 w-full">
                        <Grid className="p-contributors w-1/2">
                            <PosstiveContributors value={indice} positive={pc} negative={nc} totalArrLength={totalArrLength} />
                        </Grid>
                        <Grid className="n-contributors w-1/2">
                            <NegitiveContributors value={indice} positive={pc} negative={nc} totalArrLength={totalArrLength} />
                        </Grid>
                    </Grid>
                        :
                        <Grid style={{ display: 'flex', width: '100%', marginTop: '5px' }}>
                            <Grid className='w-3/4'>
                                <IndexmoversChart label={indexTimeLable} _filterLabel={indice} cmp={candlesData[0]?.currentprice} tf={indexTime} stockData={stockData} loading={loading} />
                            </Grid>
                            <Grid className='1/4'>
                                <IndexMoversPieChart _filterLabel={indice} indexTime={indexTime} stockData={stockData} loading={loading} />
                            </Grid>
                        </Grid>
                    }
                </Grid> : <p>Loading...</p>}
            </Grid>

            {/* */}
        </Grid >
    )
}

export default GraphAndPieChart