import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { companyFibonacciData } from '../apiService/tradaApiService';
import FRECandleStickChart from '../common/FRECandleGraph';
import FRECandleStickChart30min from '../common/FRECandleFor30Min';
import FRECandleStickChart1hr from '../common/FRECandle1Hr';
import dayjs from 'dayjs';
import { candleGraphFor15min, NiftyPlusPlus } from '../apiService/tickerApiService';
import { ArrowDownward, ArrowUpward, Height } from '@material-ui/icons';

function FRECandleGraph(props) {
    const { value, dataFromChild } = props;
    const intervalId = useRef(null);
    const { token, companySymbol } = dataFromChild;
    const [data15min, setData15min] = useState([]);
    const [data30min, setData30min] = useState([]);
    const [data1hr, setData1hr] = useState([]);
    const [dataYaxis15min_d, setDataYaxis15min_d] = useState([]);
    const [dataYaxis30min_w, setDataYaxis30min_w] = useState([]);
    const [dataYaxis_m, setDataYaxis_m] = useState([]);
    const [keys_d, setKeys_d] = useState([]);
    const [keys_w, setKeys_w] = useState([]);
    const [keys_m, setKeys_m] = useState([]);
    const [p_p_data, setP_p_data] = useState({});
    const [speedoMeterData, setSpeedoData] = useState([]);
    const [view, setView] = useState(false);

    const getCanldesData = async (tok, Symbol) => {
        try {
            const currentDate = dayjs().format('YYYY-MM-DD');
            const [candlesDataforGraph, candlesDataforGraph_30, candlesDataforGraph_60] = await Promise.all([
                candleGraphFor15min(tok, currentDate, currentDate, "15"),
                candleGraphFor15min(tok, currentDate, currentDate, "30"),
                candleGraphFor15min(tok, currentDate, currentDate, "60")
            ]);

            const timeStampData = candlesDataforGraph?.data?.map(e => ({
                x: new Date(e.x).getTime(),
                y: e.y
            })) || [];

            const timeStampData_30 = candlesDataforGraph_30?.data?.map(e => ({
                x: new Date(e.x).getTime(),
                y: e.y
            })) || [];

            const timeStampData_60 = candlesDataforGraph_60?.data?.map(e => ({
                x: new Date(e.x).getTime(),
                y: e.y
            })) || [];
            setData15min(timeStampData);
            setData30min(timeStampData_30);
            setData1hr(timeStampData_60);
        } catch (error) {
            console.error("Error fetching or processing data:", error);
        }
    };

    const fetchDataForSpeedometer = async (token, companySymbol) => {
        const company_speedo_data = await NiftyPlusPlus(companySymbol, token);
        setSpeedoData(company_speedo_data?.data);
    };

    const getHold = (v) => {
        if (v <= 16.65) return "Strong Sell";
        else if (v <= 33.33) return "Sell";
        else if (v <= 66.66) return "Hold";
        else if (v <= 83.31) return "Buy";
        else if (v <= 100) return "Strong Buy";
    };

    const getArrow = (arrow) => {
        switch (arrow) {
            case "UP": return <ArrowUpward color="secondary" style={{ fontSize: "18px", color: "#339249" }} />;
            case "DOWN": return <ArrowDownward style={{ fontSize: "18px", color: "#DA2424" }} />;
            case "NORMAL": return <Height style={{ rotate: "90deg", color: "#b5812a" }} />;
            default: return null;
        }
    };

    const setPPoints = async (Symbol) => {
        const pp_data = await companyFibonacciData(Symbol);
        setP_p_data(pp_data?.data);
        setDataYaxis15min_d(Object.values(pp_data?.data?.dailyData || {}));
        setDataYaxis30min_w(Object.values(pp_data?.data?.weeklyData || {}));
        setDataYaxis_m(Object.values(pp_data?.data?.monthlyData || {}));
        setKeys_d(Object.keys(pp_data?.data?.dailyData || {}));
        setKeys_w(Object.keys(pp_data?.data?.weeklyData || {}));
        setKeys_m(Object.keys(pp_data?.data?.monthlyData || {}));
    };

    useEffect(() => {
        if (companySymbol) setPPoints(companySymbol);

        intervalId.current = setInterval(() => {
            if (token && companySymbol) {
                fetchDataForSpeedometer(token, companySymbol);
                getCanldesData(token, companySymbol);
            }
        }, 5000);

        return () => {
            clearInterval(intervalId.current);
        };
    }, [token, companySymbol]);

    useMemo(() => {
        if (token && companySymbol) {
            fetchDataForSpeedometer(token, companySymbol);
            getCanldesData(token, companySymbol);
        }
    }, [token, companySymbol]);

    const getColor = (v) => {
        if (v <= 33.3) return '#da2424'
        else if (v >= 66.6) return '#339249'
        else return '#ffa100e3'
    }

    return (
        <>
            {dataYaxis_m.length > 0 ?
                <Grid className='w-full flex gap-1 h-full'>
                    <Grid className='h-full' style={{ width: "55%" }}>
                        <Grid style={{ background: '#000', display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ color: getColor(speedoMeterData?.daily?.[0]?.percentage ?? 0), marginLeft: "3px" }} className='font-futura'>
                                <span>{getHold(speedoMeterData?.daily?.[0]?.percentage ?? 0)}</span>
                                <span className='mr-1' style={{ fontSize: '13px' }}> ({(speedoMeterData?.daily?.[0]?.percentage ?? 0).toFixed(2)}%)</span>
                            </Typography>
                            <Typography noWrap align="center" style={{ color: '#fff' }} className='font-futura'>
                                <span className="text-xs font-black font-futura" style={{ color: "#DA2424" }}>
                                    {`(${speedoMeterData?.daily?.[0]?.negcount ?? 0}/${speedoMeterData?.daily?.[0]?.totalMetrics ?? 0})`}
                                </span>
                                {getArrow(speedoMeterData?.daily?.[0]?.arrow)}
                                <span className="text-xs font-black font-futura" style={{ color: "#339249" }}>
                                    {`(${speedoMeterData?.daily?.[0]?.poscount ?? 0}/${speedoMeterData?.daily?.[0]?.totalMetrics ?? 0})`}
                                </span>
                            </Typography>

                        </Grid>
                        <FRECandleStickChart yAxisValues={dataYaxis15min_d} graphData={data15min} keys={keys_d} data={p_p_data?.dailyData} />
                    </Grid>
                    <Grid style={{ width: "23%", marginRight: '5px' }}>
                        <Grid style={{ background: '#000', display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ color: getColor(speedoMeterData?.weekly?.[0]?.percentage ?? 0), marginLeft: "3px" }} className='font-futura'>
                                <span>{getHold(speedoMeterData?.weekly?.[0]?.percentage ?? 0)}</span>
                                <span className='mr-1' style={{ fontSize: '13px' }}> ({(speedoMeterData?.weekly?.[0]?.percentage ?? 0).toFixed(2)}%)</span>
                            </Typography>
                            <Typography noWrap align="center" style={{ color: '#fff' }} className='font-futura'>
                                <span className="text-xs font-black font-futura" style={{ color: "#DA2424" }}>
                                    {`(${speedoMeterData?.weekly?.[0]?.negcount ?? 0}/${speedoMeterData?.weekly?.[0]?.totalMetrics ?? 0})`}
                                </span>
                                {getArrow(speedoMeterData?.weekly?.[0]?.arrow)}
                                <span className="text-xs font-black font-futura" style={{ color: "#339249" }}>
                                    {`(${speedoMeterData?.weekly?.[0]?.poscount ?? 0}/${speedoMeterData?.weekly?.[0]?.totalMetrics ?? 0})`}
                                </span>
                            </Typography>
                        </Grid>
                        <FRECandleStickChart30min yAxisValues={dataYaxis30min_w} graphData={data30min} from30={true} keys={keys_w} data={p_p_data?.weeklyData} />
                    </Grid>
                    <Grid style={{ width: "20%" }}>
                        <Grid style={{ background: '#000', display: 'flex', justifyContent: 'center', paddingTop: '3px' }}>
                            <Typography style={{ color: getColor(speedoMeterData?.monthly?.[0]?.percentage ?? 0), marginLeft: "3px" }} className='font-futura'>
                                <span>{getHold(speedoMeterData?.monthly?.[0]?.percentage ?? 0)}</span>
                                <span className='mr-1' style={{ fontSize: '13px' }}> ({(speedoMeterData?.monthly?.[0]?.percentage ?? 0).toFixed(2)}%)</span>
                            </Typography>
                            <Typography noWrap align="center" style={{ color: '#fff', fontSize: '11px' }} className='font-futura'>
                                <span className="text-xs font-black font-futura" style={{ color: "#DA2424" }}>
                                    {`(${speedoMeterData?.monthly?.[0]?.negcount ?? 0}/${speedoMeterData?.monthly?.[0]?.totalMetrics ?? 0})`}
                                </span>
                                {getArrow(speedoMeterData?.monthly?.[0]?.arrow)}
                                <span className="text-xs font-black font-futura" style={{ color: "#339249" }}>
                                    {`(${speedoMeterData?.monthly?.[0]?.poscount ?? 0}/${speedoMeterData?.monthly?.[0]?.totalMetrics ?? 0})`}
                                </span>
                            </Typography>
                        </Grid>
                        <FRECandleStickChart1hr yAxisValues={dataYaxis_m} graphData={data1hr} keys={keys_m} data={p_p_data?.monthlyData} />
                    </Grid>
                </Grid> : <Typography>Loading...</Typography>}
        </>
    );
}

export default FRECandleGraph;
