import { Box, FormControl, Grid, List, ListItem, ListItemText, MenuItem, Select, TextField } from '@material-ui/core'
import { KeyboardArrowRight, SearchOutlined } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react'
import AnalysisTables from '../HomePage/AnalysisTables';
import { companyFibonacciData, companyZonesData, fetchDataSelected, fetchFAndOData } from '../apiService/tradaApiService';
import { cam_key_s, cpr_key_s, fpp_key_s, fre_key_s, spp_key_s, z_key_s } from '../../utils';
import TimeFrameContainer from '../HomePage/TimeFrameContainer';
import { CurrentCompanyData, GetObservationsForCandle } from '../apiService/tickerApiService';
import IndicesStrip from '../common/IndicesStrip';

const FAndOMenu = [
    { label: "Index Futures", value: "idf" },
    { label: "Stock Futures", value: "stf" },
]

const timeFramesForDataTable = ["Daily", "Weekly", "Monthly"]

function FutureAndOptions() {
    const [futures, setFutures] = useState('idf');
    const [search, setSearch] = useState("");
    const [data, setData] = useState([]);
    const [selectedItem, setSelectedItem] = useState({});
    const [companyList, setCompanyList] = useState([]);
    const [filteredCompanies, setFilteredCompanies] = useState([]);

    const [tickerRes, setTickerRes] = useState({});
    const [closePrice, setClosePrice] = React.useState(0);
    const [curr_perc, setCurr_perc] = React.useState(0);

    const [camarillaData, setCamarilladata] = useState([]);
    const [standardData, setStandarddata] = useState([]);
    const [fibonacciData, setFibonacciData] = useState([]);
    const [cprData, setCprData] = useState([]);
    const [ppData, setPpData] = useState([]);

    const [zonesData, setZonesData] = useState([]);
    const [fibonacciRes, setFibonacciRes] = useState({});

    const [prevDC, setPrevDC] = React.useState(0);
    const [prevWC, setPrevWC] = React.useState(0);
    const [prevMC, setPrevMC] = React.useState(0);

    const [cprtlDaily, setCprtlDaily] = React.useState(0);
    const [cprtlWeekly, setCprtlWeekly] = React.useState(0);
    const [cprtlMonthly, setCprtlMonthly] = React.useState(0);

    const [cprblDaily, setCprblDaily] = React.useState(0);
    const [cprblWeekly, setCprblWeekly] = React.useState(0);
    const [cprblMonthly, setCprblMonthly] = React.useState(0);

    const [cam_keys, setCam_Keys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], });
    const [spp_keys, setSpp_Keys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], });
    const [fb_keys, setFb_Keys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], });
    const [cpr_keys, setCpr_Keys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], });
    const [pp_keys, setPp_Keys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], });
    const [z_keys, setZ_Keys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], });
    const [f_keys, setF_Keys] = useState({ dailyKeys: [], weeklyKeys: [], monthlyKeys: [], });

    const [obj_observation_d_w, setObj_Observations_d_w] = useState({
        observe_dailyData: [],
        observe_weeklyData: [],
        observe_monthlyData: [],
    })

    const _getObservations_d_w = async (companySymbol, t) => {
        let _dailyData = await GetObservationsForCandle("D", companySymbol, t)
        let _weeklyData = await GetObservationsForCandle("W", companySymbol, t)
        let _monthlyData = await GetObservationsForCandle("M", companySymbol, t)
        setObj_Observations_d_w({
            observe_dailyData: _dailyData?.data,
            observe_weeklyData: _weeklyData?.data,
            observe_monthlyData: _monthlyData?.data,
        })
    }

    const handleChange = async (event) => {
        setSearch("");
        setFutures(event.target.value);
    };

    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearch(searchTerm);
        if (searchTerm !== "") {
            const filtered = companyList.filter(company =>
                company.tickerSymbol.toLowerCase().includes(searchTerm) ||
                company.symbol.toLowerCase().includes(searchTerm)
            ).sort((a, b) => {
                if (a.tickerSymbol.toLowerCase().startsWith(searchTerm) && !b.tickerSymbol.toLowerCase().startsWith(searchTerm)) {
                    return -1;
                } else if (!a.tickerSymbol.toLowerCase().startsWith(searchTerm) && b.tickerSymbol.toLowerCase().startsWith(searchTerm)) {
                    return 1;
                } else if (a.symbol.toLowerCase().startsWith(searchTerm) && !b.symbol.toLowerCase().startsWith(searchTerm)) {
                    return -1;
                } else if (!a.symbol.toLowerCase().startsWith(searchTerm) && b.symbol.toLowerCase().startsWith(searchTerm)) {
                    return 1;
                } else {
                    return 0;
                }
            });
            setFilteredCompanies(filtered);
        } else {
            setFilteredCompanies(companyList);
        }
    }

    const onListClick = async (e) => {
        setSelectedItem(e);
    };

    const fetchFANdOCompanies = async (futures) => {
        const data = await fetchFAndOData(futures)
        if (data?.data?.success) {
            setCompanyList(data?.data?.data)
            setFilteredCompanies(data?.data?.data);
            setSelectedItem(data?.data?.data[0]);
        } else {
            setCompanyList([])
            setFilteredCompanies([]);
            setSelectedItem({});
        }
    }

    function filterData(obj, a, b) {
        const entries = Object.entries(obj);
        const filteredAndSorted = entries.filter(([key, value]) => key.startsWith(a) || key.startsWith(b))
            .sort((a, b) => b[1] - a[1]);

        const filteredAndSortedObj = Object.fromEntries(filteredAndSorted);
        return filteredAndSortedObj;
    }

    const fetchSelectedItem = async (obj) => {
        const fetchData = await fetchDataSelected(obj?.symbol)
        if (fetchData?.data?.success) {
            setData(fetchData?.data)
            const cam_data = []
            const spp_data = []
            const fibonacci_data = []
            const cpr_data = []
            const pivortPoints_data = []

            let data = fetchData?.data
            //   for camaralla 
            const camDailyData = filterData(data.dailyData, "CPH", "CPL");
            const dailyCamArr = Object.values(camDailyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _dailyCamKeys = Object.keys(camDailyData)

            const camWeeklyData = filterData(data.weeklyData, "CPH", "CPL");
            const weeklyCamArr = Object.values(camWeeklyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _weeklyCamKeys = Object.keys(camWeeklyData)

            const camMonthlyData = filterData(data.monthlyData, "CPH", "CPL");
            const monthlyCamArr = Object.values(camMonthlyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _monthlyCamKeys = Object.keys(camMonthlyData)

            //   for standard pp
            const sppDailyData = filterData(data.dailyData, "SPH", "SPL");
            const dailySppArr = Object.values(sppDailyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _dailySppKeys = Object.keys(sppDailyData)

            const sppWeeklyData = filterData(data.weeklyData, "SPH", "SPL");
            const weeklySppArr = Object.values(sppWeeklyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _weeklysppKeys = Object.keys(sppWeeklyData)

            const sppMonthlyData = filterData(data.monthlyData, "SPH", "SPL");
            const monthlySppArr = Object.values(sppMonthlyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _monthlySppKeys = Object.keys(sppMonthlyData)

            //  for fibonacci

            const fbDailyData = filterData(data.dailyData, "FPH", "FPL");
            const dailyFbArr = Object.values(fbDailyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _dailyFbKeys = Object.keys(fbDailyData)

            const fbWeeklyData = filterData(data.weeklyData, "FPH", "FPL");
            const weeklyFbArr = Object.values(fbWeeklyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _weeklyFbKeys = Object.keys(fbWeeklyData)

            const fbMonthlyData = filterData(data.monthlyData, "FPH", "FPL");
            const monthlyFbArr = Object.values(fbMonthlyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _monthlyFbKeys = Object.keys(fbMonthlyData)

            //  for CPR

            const CPRDailyData = filterData(data.dailyData, "CPR", "CPR");
            const dailyCPRArr = Object.values(CPRDailyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _dailyCPRKeys = Object.keys(CPRDailyData)

            const CPRWeeklyData = filterData(data.weeklyData, "CPR", "CPR");
            const weeklyCPRArr = Object.values(CPRWeeklyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _weeklyCPRKeys = Object.keys(CPRWeeklyData)

            const CPRMonthlyData = filterData(data.monthlyData, "CPR", "CPR");
            const monthlyCPRArr = Object.values(CPRMonthlyData).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            const _monthlyCPRKeys = Object.keys(CPRMonthlyData)

            // for all pivort points

            let dailyArr = Object.values(data.dailyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            let _dailyKeys = Object.keys(data.dailyData)

            let weeklyArr = Object.values(data.weeklyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            let _weeklyKeys = Object.keys(data.weeklyData)

            let monthlyArr = Object.values(data.monthlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item.toFixed(2))
            let _monthlyKeys = Object.keys(data.monthlyData)

            dailyArr.map((e, i) => {
                pivortPoints_data.push({ daily: e, weekly: weeklyArr[i], monthly: monthlyArr[i] })
            })

            dailyCamArr.map((e, i) => {
                cam_data.push({ daily: e, weekly: weeklyCamArr[i], monthly: monthlyCamArr[i] })
            })

            dailySppArr.map((e, i) => {
                spp_data.push({ daily: e, weekly: weeklySppArr[i], monthly: monthlySppArr[i] })
            })
            dailyFbArr.map((e, i) => {
                fibonacci_data.push({ daily: e, weekly: weeklyFbArr[i], monthly: monthlyFbArr[i] })
            })
            dailyCPRArr.map((e, i) => {
                cpr_data.push({ daily: e, weekly: weeklyCPRArr[i], monthly: monthlyCPRArr[i] })
            })

            setCamarilladata(cam_data)
            setStandarddata(spp_data)
            setFibonacciData(fibonacci_data)
            setCprData(cpr_data)
            setPpData(pivortPoints_data)

            setPrevDC(data?.pre_CLOSE_DAY[0]?.PRE_CLOSE_DAY)
            setPrevWC(data?.pre_CLOSE_WEEK[0]?.PRE_CLOSE_WEEK)
            setPrevMC(data?.pre_CLOSE_MONTH[0]?.PRE_CLOSE_MONTH)

            setCprtlDaily(data?.dailyData?.CPRTL_D)
            setCprtlWeekly(data?.weeklyData?.CPRTL_W)
            setCprtlMonthly(data?.monthlyData?.CPRTL_M)

            setCprblDaily(data?.dailyData?.CPRBL_D)
            setCprblWeekly(data?.weeklyData?.CPRBL_W)
            setCprblMonthly(data?.monthlyData?.CPRBL_M)

            setCam_Keys({ dailyKeys: _dailyCamKeys, weeklyKeys: _weeklyCamKeys, monthlyKeys: _monthlyCamKeys })
            setSpp_Keys({ dailyKeys: _dailySppKeys, weeklyKeys: _weeklysppKeys, monthlyKeys: _monthlySppKeys })
            setFb_Keys({ dailyKeys: _dailyFbKeys, weeklyKeys: _weeklyFbKeys, monthlyKeys: _monthlyFbKeys })
            setCpr_Keys({ dailyKeys: _dailyCPRKeys, weeklyKeys: _weeklyCPRKeys, monthlyKeys: _monthlyCPRKeys })
            setPp_Keys({ dailyKeys: _dailyKeys, weeklyKeys: _weeklyKeys, monthlyKeys: _monthlyKeys })
        } else {
            setCamarilladata([])
        }
    }

    const fetchZones = async (selectedItem) => {
        let zdata = [];
        const zonesres = await companyZonesData(selectedItem?.symbol)
        if (zonesres?.data?.success) {
            setZonesData(zonesres?.data?.data)

            let d_z_data = filterData(zonesres?.data?.data, "d", "d");
            const dailyZArr = Object.values(d_z_data).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
            const _dailyZKeys = Object.keys(d_z_data)

            let w_z_data = filterData(zonesres?.data?.data, "w", "w");
            const weeklyZArr = Object.values(w_z_data).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
            const _weeklyZKeys = Object.keys(w_z_data)

            let m_z_data = filterData(zonesres?.data?.data, "m", "m");
            const monthlyZArr = Object.values(m_z_data).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
            const _monthlyZKeys = Object.keys(m_z_data)

            dailyZArr.map((e, i) => {
                zdata.push({ daily: e, weekly: weeklyZArr[i], monthly: monthlyZArr[i] })
            })

            setZ_Keys({ dailyKeys: _dailyZKeys, weeklyKeys: _weeklyZKeys, monthlyKeys: _monthlyZKeys, })
            setZonesData(zdata)
        } else {
            setZonesData([])
        }
    }
    const fetchFibonacciData = async (selectedItem) => {
        let fibonacciData = []
        const fibonacciRes = await companyFibonacciData(selectedItem?.symbol)

        if (fibonacciRes?.data?.success) {

            let Fib_data = fibonacciRes?.data

            let Fib_D_dailyArr = Object.values(Fib_data?.dailyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
            let Fib_D_dailyKeys = Object.keys(Fib_data?.dailyData)

            let Fib_D_weeklyArr = Object.values(Fib_data?.weeklyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
            let Fib_D_weeklyKeys = Object.keys(Fib_data?.weeklyData)

            let Fib_D_monthlyArr = Object.values(Fib_data?.monthlyData).sort((a, b) => { return b - a }).map(item => item === 0 ? "NA" : item < 0 ? (0)?.toFixed(2) : item?.toFixed(2))
            let Fib_D_monthlyKeys = Object.keys(Fib_data?.monthlyData)

            Fib_D_dailyArr.map((e, i) => {
                fibonacciData.push({ daily: e, weekly: Fib_D_weeklyArr[i], monthly: Fib_D_monthlyArr[i] })
            })
            setF_Keys({ dailyKeys: Fib_D_dailyKeys, weeklyKeys: Fib_D_weeklyKeys, monthlyKeys: Fib_D_monthlyKeys, })
            setFibonacciRes(fibonacciData)
        }
    }

    const currentMP = async (token) => {
        const CMPdata = await CurrentCompanyData(token);
        setTickerRes(CMPdata?.data)
        setClosePrice(CMPdata?.data?.closePrice)
        setCurr_perc((CMPdata?.data?.change)?.toFixed(2))
    }

    useEffect(() => {
        fetchFANdOCompanies(futures)
    }, [futures])

    useEffect(() => {
        if (selectedItem?.instrument_token) {
            fetchSelectedItem(selectedItem)
            fetchFibonacciData(selectedItem)
            fetchZones(selectedItem)
            const observationsInterVal_d_w = setInterval(() => {
                if (selectedItem?.symbol && selectedItem?.instrument_token) {
                    _getObservations_d_w(selectedItem?.symbol, selectedItem?.instrument_token)
                }
            }, 30000);
            const intervalId = setInterval(() => {
                currentMP(selectedItem?.instrument_token)
            }, 2000)
            return () => {
                clearInterval(intervalId)
                clearInterval(observationsInterVal_d_w)
            }
        }
    }, [selectedItem])

    useMemo(() => {
        if (selectedItem?.symbol && selectedItem?.instrument_token) {
            _getObservations_d_w(selectedItem?.symbol, selectedItem?.instrument_token)
        }
    }, [selectedItem])

    const getPercentage = (i) => {
        switch (i) {
            case 0:
                let prec_D = ((tickerRes?.lastTradedPrice - prevDC) / prevDC) * 100
                let D_points = (tickerRes?.lastTradedPrice - closePrice)?.toFixed(2)
                return <>
                    <p style={{ fontSize: '11px', color: `${D_points >= 0 ? "#339249" : "#DA2424"}` }}>{D_points ? D_points : 0}</p>
                    <p style={{ fontSize: '11px', color: `${D_points >= 0 ? "#339249" : "#DA2424"}` }}>{`(${curr_perc ? curr_perc : 0}%)`}</p>
                </>
            case 1:
                let prec_W = ((tickerRes?.lastTradedPrice - prevWC) / prevWC) * 100
                let W_points = (tickerRes?.lastTradedPrice - prevWC)?.toFixed(2)
                return <>
                    <p style={{ fontSize: '11px', color: `${W_points >= 0 ? "#339249" : "#DA2424"}` }}>{W_points ? W_points : 0}</p>
                    <p style={{ fontSize: '11px', color: `${prec_W >= 0 ? "#339249" : "#DA2424"}` }}>{`(${prec_W ? prec_W.toFixed(2) : 0}%)`}</p>
                </>
            case 2:
                let prec_M = ((tickerRes?.lastTradedPrice - prevMC) / prevMC) * 100
                let M_points = (tickerRes?.lastTradedPrice - prevMC)?.toFixed(2)
                return <>
                    <p style={{ fontSize: '11px', color: `${M_points >= 0 ? "#339249" : "#DA2424"}` }}>{M_points ? M_points : 0}</p>
                    <p style={{ fontSize: '11px', color: `${prec_M >= 0 ? "#339249" : "#DA2424"}` }}>{`(${prec_M ? prec_M.toFixed(2) : 0}%)`}</p>
                </>
            default:
                return <p>0</p>
        }
    }

    const getObservations = (tf) => {
        switch (tf) {
            case "D":
                let d_obser = {
                    arrow: obj_observation_d_w?.observe_dailyData[0]?.arrow, poscount: obj_observation_d_w?.observe_dailyData[0]?.poscount, negcount: obj_observation_d_w?.observe_dailyData[0]?.negcount,
                    neutralcount: obj_observation_d_w?.observe_dailyData[0]?.neutralcount, totalMetrics: obj_observation_d_w?.observe_dailyData[0]?.totalMetrics,rest_data: obj_observation_d_w?.observe_dailyData[0]
                }
                return d_obser
            case "W":
                let w_obser = {
                    arrow: obj_observation_d_w?.observe_weeklyData[0]?.arrow, poscount: obj_observation_d_w?.observe_weeklyData[0]?.poscount, negcount: obj_observation_d_w?.observe_weeklyData[0]?.negcount,
                    neutralcount: obj_observation_d_w?.observe_weeklyData[0]?.neutralcount, totalMetrics: obj_observation_d_w?.observe_weeklyData[0]?.totalMetrics,rest_data: obj_observation_d_w?.observe_weeklyData[0]
                }
                return w_obser
            case "M":
                let m_obser = {
                    arrow: obj_observation_d_w?.observe_monthlyData[0]?.arrow, poscount: obj_observation_d_w?.observe_monthlyData[0]?.poscount, negcount: obj_observation_d_w?.observe_monthlyData[0]?.negcount,
                    neutralcount: obj_observation_d_w?.observe_monthlyData[0]?.neutralcount, totalMetrics: obj_observation_d_w?.observe_monthlyData[0]?.totalMetrics,rest_data: obj_observation_d_w?.observe_monthlyData[0]
                }
                return m_obser
            default:
                return {};
        }
    }

    const timeFrames = [
        {
            timeFrame: "Daily",
            curr_open: tickerRes?.openPrice?.toFixed(2),
            curr_high: tickerRes?.highPrice?.toFixed(2),
            curr_low: tickerRes?.lowPrice?.toFixed(2),
            curr_range: (tickerRes?.highPrice - tickerRes?.lowPrice)?.toFixed(2),
            prev_Range_perc: data?.dailyLowHigh?.length > 0 ? (((tickerRes?.lastTradedPrice - data?.dailyLowHigh[0]?.pre_low) / (data?.dailyLowHigh[0]?.pre_high - data?.dailyLowHigh[0]?.pre_low))?.toFixed(2) * 100)?.toFixed(2) : 0,
            prev_high: data?.dailyLowHigh?.length > 0 ? data?.dailyLowHigh[0]?.pre_high?.toFixed(2) : 0,
            prev_low: data?.dailyLowHigh?.length > 0 ? data?.dailyLowHigh[0]?.pre_low?.toFixed(2) : 0,
            prev_close: data?.pre_CLOSE_DAY?.length > 0 ? data?.pre_CLOSE_DAY[0]?.PRE_CLOSE_DAY?.toFixed(2) : 0,
            percentage: getPercentage(0),
            observations: getObservations("D")
        },
        {
            timeFrame: "Weekly",
            curr_open: data?.open_WEEK?.length > 0 ? data?.open_WEEK[0]?.OPEN_WEEK?.toFixed(2) : 0,
            curr_high: data.weeklyLowHigh?.length > 0 ? (tickerRes ? (data.weeklyLowHigh[0].high > tickerRes?.highPrice ? data.weeklyLowHigh[0].high : tickerRes?.highPrice) : data.weeklyLowHigh[0].high)?.toFixed(2) : 0,
            curr_low: data.weeklyLowHigh?.length > 0 ? (tickerRes ? (data.weeklyLowHigh[0].low < tickerRes?.lowPrice ? (data.weeklyLowHigh[0].low == 0 ? tickerRes?.lowPrice : data.weeklyLowHigh[0].low) : tickerRes?.lowPrice) : data.weeklyLowHigh[0].low)?.toFixed(2) : 0,
            // curr_range: data.weeklyLowHigh?.length > 0 ? (tickerRes ? (data.weeklyLowHigh[0].high > tickerRes?.highPrice ? data.weeklyLowHigh[0].high : tickerRes?.highPrice) : data.weeklyLowHigh[0].high)?.toFixed(2) - (tickerRes ? (data.weeklyLowHigh[0].low < tickerRes?.lowPrice ? (data.weeklyLowHigh[0].low == 0 ? tickerRes?.lowPrice : data.weeklyLowHigh[0].low) : tickerRes?.lowPrice) : data.weeklyLowHigh[0].low)?.toFixed(2) : 0,
            prev_Range_perc: data?.pre_weeklyLowHigh?.length > 0 ? ((((tickerRes?.lastTradedPrice - data?.pre_weeklyLowHigh[0]?.pre_low) / (data?.pre_weeklyLowHigh[0]?.pre_high - data?.pre_weeklyLowHigh[0]?.pre_low))?.toFixed(2)) * 100)?.toFixed(2) : 0,
            prev_high: data?.pre_weeklyLowHigh?.length > 0 ? data?.pre_weeklyLowHigh[0]?.pre_high?.toFixed(2) : 0,
            prev_low: data?.pre_weeklyLowHigh?.length > 0 ? data?.pre_weeklyLowHigh[0]?.pre_low?.toFixed(2) : 0,
            prev_close: data?.pre_CLOSE_WEEK?.length > 0 ? data?.pre_CLOSE_WEEK[0]?.PRE_CLOSE_WEEK?.toFixed(2) : 0,
            percentage: getPercentage(1),
            observations: getObservations("W")
        },
        {
            timeFrame: "Monthly",
            curr_open: data?.open_MONTH?.length > 0 ? data?.open_MONTH[0]?.OPEN_MONTH?.toFixed(2) : 0,
            curr_high: data.monthlyLowHigh?.length > 0 ? (tickerRes ? (data.monthlyLowHigh[0].high > tickerRes.highPrice ? data.monthlyLowHigh[0].high : tickerRes.highPrice) : data.monthlyLowHigh[0].high)?.toFixed(2) : 0,
            curr_low: data.monthlyLowHigh?.length > 0 ? (tickerRes ? (data.monthlyLowHigh[0].low < tickerRes.lowPrice ? (data.monthlyLowHigh[0].low == 0 ? tickerRes.lowPrice : data.monthlyLowHigh[0].low) : tickerRes.lowPrice) : data.monthlyLowHigh[0].low)?.toFixed(2) : 0,
            // curr_range: data.monthlyLowHigh?.length > 0 ? (tickerRes ? (data.monthlyLowHigh[0].high > tickerRes.highPrice ? data.monthlyLowHigh[0].high : tickerRes.highPrice) : data.monthlyLowHigh[0].high)?.toFixed(2) - (tickerRes ? (data.monthlyLowHigh[0].low < tickerRes.lowPrice ? (data.monthlyLowHigh[0].low == 0 ? tickerRes.lowPrice : data.monthlyLowHigh[0].low) : tickerRes.lowPrice) : data.monthlyLowHigh[0].low)?.toFixed(2) : 0,
            prev_Range_perc: data?.pre_monthlyLowHigh?.length > 0 ? (((tickerRes?.lastTradedPrice - data?.pre_monthlyLowHigh[0]?.pre_low) / (data?.pre_monthlyLowHigh[0]?.pre_high - data?.pre_monthlyLowHigh[0]?.pre_low))?.toFixed(2) * 100)?.toFixed(2) : 0,
            prev_high: data?.pre_monthlyLowHigh?.length > 0 ? data?.pre_monthlyLowHigh[0]?.pre_high?.toFixed(2) : 0,
            prev_low: data?.pre_monthlyLowHigh?.length > 0 ? data?.pre_monthlyLowHigh[0]?.pre_low?.toFixed(2) : 0,
            prev_close: data?.pre_CLOSE_MONTH?.length > 0 ? data?.pre_CLOSE_MONTH[0]?.PRE_CLOSE_MONTH?.toFixed(2) : 0,
            percentage: getPercentage(2),
            observations: getObservations("M")
        },
    ]

    return (<>
        <Box className='w-2/12'>
            <FormControl className='select-dropdown' >
                <Select value={futures} onChange={handleChange} style={{ fontFamily: "Futura PT Book", fontWeight: "bold", display: 'flex', width: '100%' }} labelId="demo-simple-select-label" id="demo-simple-select">
                    {FAndOMenu.map((e, i) => (
                        <MenuItem value={e.value} key={i} style={{ fontSize: "13px", fontFamily: "Futura PT Book", borderBottom: "1px solid lightgrey" }}>{e.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField value={search} onChange={handleSearch} className='list-search' id="outlined-search" type="search" placeholder='Search For Companies' InputProps={{ startAdornment: <SearchOutlined /> }} />
            <List style={{ overflow: 'auto', padding: '0px', }}>
                {filteredCompanies.map((item, i) => (
                    <ListItem className='listitem' style={{ backgroundColor: `${selectedItem?.symbol === item?.symbol ? "#b2d5f1" : "#fff"}` }} button={true} key={i} onClick={() => onListClick(item)}>
                        <Grid className='list-content'>
                            <ListItemText className='list-csymbol' primary={item.symbol} />
                            <ListItemText className='list-cname' primary={item.tickerSymbol} />
                        </Grid>
                        <KeyboardArrowRight />
                    </ListItem>
                ))}
            </List>
        </Box>
        <Box className='w-10/12'>
            <Grid className='trading-ticker'>
                <Grid className="table-ticker">
                    <Grid className='company-cmp'>
                        <Grid className="details">
                            <Grid className="details-context px-2 py-1"
                                style={{ color: '#fff', marginLeft: '0px' }}>
                                {selectedItem?.symbol}
                            </Grid>
                        </Grid>
                        <Grid >
                            <Grid className='pricing m-2'>
                                <Grid style={{ color: `${closePrice <= tickerRes?.lastTradedPrice ? 'rgb(51, 146, 73)' : 'rgb(218, 36, 36)'}`, display: "flex", alignItems: "center", backgroundColor: '#fff', padding: '0px 13px', borderRadius: '4px', }}>
                                    <p className='mr-1'>{tickerRes?.lastTradedPrice ? tickerRes?.lastTradedPrice : 0}</p>
                                    <p style={{ fontSize: '11px', color: '#857d7d' }}>{tickerRes ? (tickerRes?.lastTradedPrice - tickerRes?.closePrice)?.toFixed(2) : 0}</p>
                                    <p style={{ fontSize: '11px', color: '#857d7d' }}>{`(${tickerRes.change ? (tickerRes.change)?.toFixed(2) : 0}%)`}</p>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid className='flex'>
                        <IndicesStrip token="256265" IndiceLabel="NIFTY 50" />
                        <IndicesStrip token="260105" IndiceLabel="BANK NIFTY" />
                        <IndicesStrip token="259849" IndiceLabel="IT NIFTY" />
                    </Grid>
                </Grid>
                <TimeFrameContainer timeFrames={timeFrames} tickerData={tickerRes} />
            </Grid>
            <Grid className="invest-tables" style={{ height: 'calc(100vh - 200px)', overflow: 'auto' }}>
                <Grid className='etf-col'>
                    <AnalysisTables value={2} heading="Camarilla Pivot Point Analysis" timeFramesForDataTable={timeFramesForDataTable} data={camarillaData} keys={cam_keys} h_l_keys={cam_key_s} />
                    <AnalysisTables value={2} heading="Standard Pivot Point Analysis" timeFramesForDataTable={timeFramesForDataTable} data={standardData} keys={spp_keys} h_l_keys={spp_key_s} />
                </Grid>
                <Grid className='etf-col'>
                    <AnalysisTables value={2} heading="Zones Analysis" timeFramesForDataTable={timeFramesForDataTable} data={zonesData} keys={z_keys} zones={true} h_l_keys={z_key_s} />
                    <AnalysisTables value={2} heading="Fibonacci Pivot Point Analysis" timeFramesForDataTable={timeFramesForDataTable} data={fibonacciData} keys={fb_keys} h_l_keys={fpp_key_s} />
                    <AnalysisTables value={2} heading="Central Pivot Range (CPR) Analysis" timeFramesForDataTable={timeFramesForDataTable} data={cprData} keys={cpr_keys} h_l_keys={cpr_key_s} />
                </Grid>
                <Grid className='etf-col'>
                    <AnalysisTables value={2} heading="All Pivot Points Analysis" timeFramesForDataTable={timeFramesForDataTable} data={ppData} keys={pp_keys} />
                </Grid>
                <Grid className='etf-col'>
                    <AnalysisTables value={2} heading="Fibonacci Retracement & Extension Analysis" timeFramesForDataTable={timeFramesForDataTable} data={fibonacciRes} keys={f_keys} h_l_keys={fre_key_s} fibonacci={true} />
                </Grid>
            </Grid>
        </Box>
    </>
    )
}

export default FutureAndOptions