import { Grid, Typography } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { NiftyPlusPlus } from '../apiService/tickerApiService'
import MetricCandleStick from '../common/MetricCandleStick'
import IndicesStrip from '../common/IndicesStrip'
import GaugeChartNV from '../common/GaugeChartNV'

function GraphView(props) {
    const { value, dataFromChild, showTop } = props
    const { token, companySymbol } = dataFromChild
    const [speedoMeterData, setSpeedoData] = useState([])

    const fetchDataForSpeedometer = async (token, companySymbol) => {
        const company_speedo_data = await NiftyPlusPlus(companySymbol, token)
        setSpeedoData(company_speedo_data?.data)
    }

    useEffect(() => {
        let intervalId
        if (token && companySymbol) {
            intervalId = setInterval(() => {
                fetchDataForSpeedometer(token, companySymbol)
            }, 4000)
        }
        return () => {
            if (intervalId) {
                setSpeedoData([])
                clearInterval(intervalId)
            }
        }
    }, [token, companySymbol])

    useMemo(() => {
        if (token && companySymbol) fetchDataForSpeedometer(token, companySymbol)
    }, [])

    const getPerc = (cmp, pc) => {
        let a = ((cmp - pc) / pc) * 100
        return a?.toFixed(1)
    }
    return (
        <>
            {showTop && <Grid className="table-ticker">
                <Grid className='company-cmp'>
                    <Grid className="details">
                        <Grid className="details-context px-2 py-1"
                            style={{
                                color: '#fff',
                                marginLeft: '0px'
                            }}
                        >
                            {dataFromChild?.companySymbol}
                        </Grid>
                    </Grid>
                    <Grid >
                        <Grid className='pricing m-2'>
                            {speedoMeterData?.daily?.length > 0 &&
                                <Grid
                                    style={{
                                        color: `${speedoMeterData?.daily[0]?.pre_close <= speedoMeterData?.daily[0]?.currentprice ? 'rgb(51, 146, 73)' : 'rgb(218, 36, 36)'}`,
                                        display: "flex",
                                        alignItems: "center",
                                        backgroundColor: '#fff',
                                        padding: '0px 13px',
                                        borderRadius: '4px',
                                        //  height:'15px'
                                    }}>
                                    <p className='mr-1'>{speedoMeterData?.daily?.length > 0 ? speedoMeterData?.daily[0]?.currentprice : 0}</p>
                                    <p style={{ fontSize: '11px', color: '#857d7d' }}>{speedoMeterData?.daily?.length > 0 ? (speedoMeterData?.daily[0]?.currentprice - speedoMeterData?.daily[0]?.pre_close)?.toFixed(1) : 0}</p>
                                    <p style={{ fontSize: '11px', color: '#857d7d' }}>{`(${speedoMeterData?.daily?.length > 0 ? (((speedoMeterData?.daily[0]?.currentprice - speedoMeterData?.daily[0]?.pre_close) / speedoMeterData?.daily[0]?.pre_close) * 100)?.toFixed(1) : 0}%)`}</p>
                                    {/* ((ticker - prevDC) / prevDC) * 100 */}
                                </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className='flex'>
                    <IndicesStrip token="256265" IndiceLabel="NIFTY 50" />
                    <IndicesStrip token="260105" IndiceLabel="BANK NIFTY" />
                    <IndicesStrip token="259849" IndiceLabel="IT NIFTY" />
                </Grid>
            </Grid>}
            <Grid className='w-11/12 mt-4 mx-2 pb-8'>

                {Object.values(speedoMeterData)?.length > 0 ? [speedoMeterData].map((s, i) => {
                    const traderViewPercentages = [s?.daily?.length > 0 ? s?.daily[0]?.percentage?.toFixed(2) : 0, s?.weekly.length > 0 ? s?.weekly[0]?.percentage.toFixed(2) : 0, s?.monthly?.length > 0 ? s?.monthly[0]?.percentage.toFixed(2) : 0]
                    const investorViewPercentages = [s?.quarterly?.length > 0 ? s?.quarterly[0]?.percentage.toFixed(2) : 0, s?.halfYearly.length > 0 ? s?.halfYearly[0]?.percentage.toFixed(2) : 0, s?.yearly?.length > 0 ? s?.yearly[0]?.percentage.toFixed(2) : 0]
                    return (
                        <>
                            <Grid className='w-full flex gap-4'>
                                <Grid className='w-1/3 border-timeframe plusplus-border p-0 bg-white'>
                                    <Grid className='header-timeframe'>
                                        <Typography align='center' className='font-semibold font-futura' style={{ fontSize: '14px' }}>{companySymbol}</Typography>
                                    </Grid>
                                    <Grid className=''>
                                        <GaugeChartNV tf={["Daily", "Weekly", "Monthly"]} viewPercentages={traderViewPercentages} mean={((Number(traderViewPercentages[0]) + Number(traderViewPercentages[1]) + Number(traderViewPercentages[2])) / 3)?.toFixed(2)} />
                                        <Grid className='indice-candle-nsePlusePluse' style={{ backgroundColor: 'lightblue' }}>
                                            <Grid className="flex justify-center -mb-1">
                                                <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ fontSize: '12px', color: `#000066` }}>(D) - {companySymbol}
                                                    <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.daily[0]?.currentprice}</span>
                                                    <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.daily[0]?.currentprice - s?.daily[0]?.pre_close).toFixed(1)})({getPerc(s?.daily[0]?.currentprice, s?.daily[0]?.pre_close)}%)</span>
                                                </Typography>
                                            </Grid>
                                            <Grid style={{ marginTop: '4px' }}>
                                                <MetricCandleStick open={s?.daily[0]?.current_open ? s?.daily[0]?.current_open : s?.daily[0]?.ticker_open}
                                                    high={s?.daily[0]?.current_high >= s?.daily[0]?.ticker_high ? s?.daily[0]?.current_high : s?.daily[0]?.ticker_high}
                                                    low={s?.daily[0]?.current_low >= s?.daily[0]?.ticker_low ? s?.daily[0]?.ticker_low : s?.daily[0]?.current_low == 0 ? s?.daily[0]?.ticker_low : s?.daily[0]?.current_low}
                                                    close={s?.daily[0]?.current_close} poscount={s?.daily[0]?.poscount} negcount={s?.daily[0]?.negcount} arrow={s?.daily[0]?.arrow} totalMetrics={s?.daily[0]?.totalMetrics} prev_range={s?.daily[0]?.pre_percentage}
                                                />
                                            </Grid>
                                            <Grid className='im-ohlc flex w-full' >
                                                <Grid className="im-lc w-1/3">
                                                    <Typography align="center" className="high-color">C.R: {((s?.daily[0]?.current_high >= s?.daily[0]?.ticker_high ? s?.daily[0]?.current_high : s?.daily[0]?.ticker_high) - (s?.daily[0]?.current_low >= s?.daily[0]?.ticker_low ? s?.daily[0]?.ticker_low : s?.daily[0]?.current_low == 0 ? s?.daily[0]?.ticker_low : s?.daily[0]?.current_low))?.toFixed(1)}</Typography>
                                                    <Typography align="center" className={(s?.daily[0]?.pre_high - s?.daily[0]?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.daily[0]?.pre_low) ? (s?.daily[0]?.pre_high - s?.daily[0]?.pre_low)?.toFixed(1) : 0}</Typography>
                                                </Grid>
                                                <Grid className="im-oh w-1/3">
                                                    <Typography align="center" className="high-color">O: {s?.daily[0]?.current_open == 0 ? s?.daily[0]?.ticker_open : s?.daily[0]?.current_open?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="high-color">P.C: {s?.daily[0]?.pre_close?.toFixed(1)}</Typography>
                                                </Grid>
                                                <Grid className="im-rp w-1/3">
                                                    <Typography align="center" className="high-color">H: {s?.daily[0]?.current_high >= s?.daily[0]?.ticker_high ? s?.daily[0]?.current_high?.toFixed(1) : s?.daily[0]?.ticker_high?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="low-color">L: {s?.daily[0]?.current_low >= s?.daily[0]?.ticker_low ? s?.daily[0]?.ticker_low?.toFixed(1) : s?.daily[0]?.current_low == "0" ? s?.daily[0]?.ticker_low?.toFixed(1) : s?.daily[0]?.current_low?.toFixed(1)} </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid className='indice-candle-nsePlusePluse' style={{ backgroundColor: 'lightblue' }}>
                                            <Grid className="flex justify-center -mb-1">
                                                <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ fontSize: '12px', color: `#000066` }}>(W) - {companySymbol}
                                                    <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.weekly[0]?.currentprice}</span>
                                                    <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.weekly[0]?.currentprice - s?.weekly[0]?.pre_close).toFixed(1)})({getPerc(s?.weekly[0]?.currentprice, s?.weekly[0]?.pre_close)}%)</span>
                                                </Typography>
                                            </Grid>
                                            <Grid style={{ marginTop: '4px' }}>
                                                <MetricCandleStick open={s?.weekly[0]?.current_open ? s?.weekly[0]?.current_open : s?.weekly[0]?.ticker_open}
                                                    high={s?.weekly[0]?.current_high >= s?.weekly[0]?.ticker_high ? s?.weekly[0]?.current_high : s?.weekly[0]?.ticker_high}
                                                    low={s?.weekly[0]?.current_low >= s?.weekly[0]?.ticker_low ? s?.weekly[0]?.ticker_low : s?.weekly[0]?.current_low == 0 ? s?.weekly[0]?.ticker_low : s?.weekly[0]?.current_low}
                                                    close={s?.weekly[0]?.current_close} poscount={s?.weekly[0]?.poscount} negcount={s?.weekly[0]?.negcount} arrow={s?.weekly[0]?.arrow} totalMetrics={s?.weekly[0]?.totalMetrics} prev_range={s?.weekly[0]?.pre_percentage}
                                                />
                                            </Grid>
                                            <Grid className='im-ohlc flex w-full'>
                                                <Grid className="im-lc w-1/3">
                                                    <Typography align="center" className="high-color">C.R: {((s?.weekly[0]?.current_high >= s?.weekly[0]?.ticker_high ? s?.weekly[0]?.current_high : s?.weekly[0]?.ticker_high) - (s?.weekly[0]?.current_low >= s?.weekly[0]?.ticker_low ? s?.weekly[0]?.ticker_low : s?.weekly[0]?.current_low == 0 ? s?.weekly[0]?.ticker_low : s?.weekly[0]?.current_low))?.toFixed(1)}</Typography>
                                                    <Typography align="center" className={(s?.weekly[0]?.pre_high - s?.weekly[0]?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.weekly[0]?.pre_low) ? (s?.weekly[0]?.pre_high - s?.weekly[0]?.pre_low)?.toFixed(1) : 0}</Typography>
                                                </Grid>
                                                <Grid className="im-oh w-1/3">
                                                    <Typography align="center" className="high-color">O: {s?.weekly[0]?.current_open == 0 ? s?.weekly[0]?.ticker_open : s?.weekly[0]?.current_open?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="high-color">P.C: {s?.weekly[0]?.pre_close?.toFixed(1)}</Typography>
                                                </Grid>
                                                <Grid className="im-rp w-1/3">
                                                    <Typography align="center" className="high-color">H: {s?.weekly[0]?.current_high >= s?.weekly[0]?.ticker_high ? s?.weekly[0]?.current_high?.toFixed(1) : s?.weekly[0]?.ticker_high?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="low-color">L: {s?.weekly[0]?.current_low >= s?.weekly[0]?.ticker_low ? s?.weekly[0]?.ticker_low?.toFixed(1) : s?.weekly[0]?.current_low == "0" ? s?.weekly[0]?.ticker_low?.toFixed(1) : s?.weekly[0]?.current_low?.toFixed(1)} </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid className='indice-candle-nsePlusePluse' style={{ backgroundColor: 'lightblue' }}>
                                            <Grid className="flex justify-center -mb-1">
                                                <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ fontSize: '12px', color: `#000066` }}>(M) - {companySymbol}
                                                    <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.monthly[0]?.currentprice}</span>
                                                    <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.monthly[0]?.currentprice - s?.monthly[0]?.pre_close).toFixed(1)})({getPerc(s?.monthly[0]?.currentprice, s?.monthly[0]?.pre_close)}%)</span>
                                                </Typography>
                                            </Grid>
                                            <Grid  style={{ marginTop: '4px' }}>
                                                <MetricCandleStick open={s?.monthly[0]?.current_open ? s?.monthly[0]?.current_open : s?.monthly[0]?.ticker_open}
                                                    high={s?.monthly[0]?.current_high >= s?.monthly[0]?.ticker_high ? s?.monthly[0]?.current_high : s?.monthly[0]?.ticker_high}
                                                    low={s?.monthly[0]?.current_low >= s?.monthly[0]?.ticker_low ? s?.monthly[0]?.ticker_low : s?.monthly[0]?.current_low == 0 ? s?.monthly[0]?.ticker_low : s?.monthly[0]?.current_low}
                                                    close={s?.monthly[0]?.current_close} poscount={s?.monthly[0]?.poscount} negcount={s?.monthly[0]?.negcount} arrow={s?.monthly[0]?.arrow} totalMetrics={s?.monthly[0]?.totalMetrics} prev_range={s?.monthly[0]?.pre_percentage}
                                                />
                                            </Grid>
                                            <Grid className='im-ohlc flex w-full'>
                                                <Grid className="im-lc w-1/3">
                                                    <Typography align="center" className="high-color">C.R: {((s?.monthly[0]?.current_high >= s?.monthly[0]?.ticker_high ? s?.monthly[0]?.current_high : s?.monthly[0]?.ticker_high) - (s?.monthly[0]?.current_low >= s?.monthly[0]?.ticker_low ? s?.monthly[0]?.ticker_low : s?.monthly[0]?.current_low == 0 ? s?.monthly[0]?.ticker_low : s?.monthly[0]?.current_low))?.toFixed(1)}</Typography>
                                                    <Typography align="center" className={(s?.monthly[0]?.pre_high - s?.monthly[0]?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.monthly[0]?.pre_low) ? (s?.monthly[0]?.pre_high - s?.monthly[0]?.pre_low)?.toFixed(1) : 0}</Typography>
                                                </Grid>
                                                <Grid className="im-oh w-1/3">
                                                    <Typography align="center" className="high-color">O: {s?.monthly[0]?.current_open == 0 ? s?.monthly[0]?.ticker_open : s?.monthly[0]?.current_open?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="high-color">P.C: {s?.monthly[0]?.pre_close?.toFixed(1)}</Typography>
                                                </Grid>
                                                <Grid className="im-rp w-1/3">
                                                    <Typography align="center" className="high-color">H: {s?.monthly[0]?.current_high >= s?.monthly[0]?.ticker_high ? s?.monthly[0]?.current_high?.toFixed(1) : s?.monthly[0]?.ticker_high?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="low-color">L: {s?.monthly[0]?.current_low >= s?.monthly[0]?.ticker_low ? s?.monthly[0]?.ticker_low?.toFixed(1) : s?.monthly[0]?.current_low == "0" ? s?.monthly[0]?.ticker_low?.toFixed(1) : s?.monthly[0]?.current_low?.toFixed(1)} </Typography>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid className='w-1/3 border-timeframe plusplus-border p-0 bg-white'>
                                    <Grid className='header-timeframe'>
                                        <Typography align='center' className='font-semibold font-futura' style={{ fontSize: '14px' }}>{companySymbol}</Typography>
                                    </Grid>
                                    <Grid className=''>
                                        <GaugeChartNV tf={["Quarterly", "HalfYearly", "Yearly"]} viewPercentages={investorViewPercentages} mean={((Number(investorViewPercentages[0]) + Number(investorViewPercentages[1]) + Number(investorViewPercentages[2])) / 3)?.toFixed(2)} />
                                        <Grid className='indice-candle-nsePlusePluse' style={{ backgroundColor: 'lightblue' }}>
                                            <Grid className="flex justify-center -mb-1">
                                                <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ fontSize: '12px', color: `#000066` }}>(Q) - {companySymbol}
                                                    <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.quarterly[0]?.currentprice}</span>
                                                    <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.quarterly[0]?.currentprice - s?.quarterly[0]?.pre_close).toFixed(1)})({getPerc(s?.quarterly[0]?.currentprice, s?.quarterly[0]?.pre_close)}%)</span>
                                                </Typography>
                                            </Grid>
                                            <Grid  style={{ marginTop: '4px' }}>
                                                <MetricCandleStick open={s?.quarterly[0]?.current_open ? s?.quarterly[0]?.current_open : s?.quarterly[0]?.ticker_open}
                                                    high={s?.quarterly[0]?.current_high >= s?.quarterly[0]?.ticker_high ? s?.quarterly[0]?.current_high : s?.quarterly[0]?.ticker_high}
                                                    low={s?.quarterly[0]?.current_low >= s?.quarterly[0]?.ticker_low ? s?.quarterly[0]?.ticker_low : s?.quarterly[0]?.current_low == 0 ? s?.quarterly[0]?.ticker_low : s?.quarterly[0]?.current_low}
                                                    close={s?.quarterly[0]?.current_close} poscount={s?.quarterly[0]?.poscount} negcount={s?.quarterly[0]?.negcount} arrow={s?.quarterly[0]?.arrow} totalMetrics={s?.quarterly[0]?.totalMetrics} prev_range={s?.quarterly[0]?.pre_percentage}
                                                />
                                            </Grid>
                                            <Grid className='im-ohlc flex w-full'>
                                                <Grid className="im-lc w-1/3">
                                                    <Typography align="center" className="high-color">C.R: {((s?.quarterly[0]?.current_high >= s?.quarterly[0]?.ticker_high ? s?.quarterly[0]?.current_high : s?.quarterly[0]?.ticker_high) - (s?.quarterly[0]?.current_low >= s?.quarterly[0]?.ticker_low ? s?.quarterly[0]?.ticker_low : s?.quarterly[0]?.current_low == 0 ? s?.quarterly[0]?.ticker_low : s?.quarterly[0]?.current_low))?.toFixed(1)}</Typography>
                                                    <Typography align="center" className={(s?.quarterly[0]?.pre_high - s?.quarterly[0]?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.quarterly[0]?.pre_low) ? (s?.quarterly[0]?.pre_high - s?.quarterly[0]?.pre_low)?.toFixed(1) : 0}</Typography>
                                                </Grid>
                                                <Grid className="im-oh w-1/3">
                                                    <Typography align="center" className="high-color">O: {s?.quarterly[0]?.current_open == 0 ? s?.quarterly[0]?.ticker_open : s?.quarterly[0]?.current_open?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="high-color">P.C: {s?.quarterly[0]?.pre_close?.toFixed(1)}</Typography>
                                                </Grid>
                                                <Grid className="im-rp w-1/3">
                                                    <Typography align="center" className="high-color">H: {s?.quarterly[0]?.current_high >= s?.quarterly[0]?.ticker_high ? s?.quarterly[0]?.current_high?.toFixed(1) : s?.quarterly[0]?.ticker_high?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="low-color">L: {s?.quarterly[0]?.current_low >= s?.quarterly[0]?.ticker_low ? s?.quarterly[0]?.ticker_low?.toFixed(1) : s?.quarterly[0]?.current_low == "0" ? s?.quarterly[0]?.ticker_low?.toFixed(1) : s?.quarterly[0]?.current_low?.toFixed(1)} </Typography>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                        <Grid className='indice-candle-nsePlusePluse' style={{ backgroundColor: 'lightblue' }}>
                                            <Grid className="flex justify-center -mb-1">
                                                <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ fontSize: '12px', color: `#000066` }}>(HY) - {companySymbol}
                                                    <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.halfYearly[0]?.currentprice}</span>
                                                    <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.halfYearly[0]?.currentprice - s?.halfYearly[0]?.pre_close).toFixed(1)})({getPerc(s?.halfYearly[0]?.currentprice, s?.halfYearly[0]?.pre_close)}%)</span>
                                                </Typography>
                                            </Grid>
                                            <Grid  style={{ marginTop: '4px' }}>
                                                <MetricCandleStick open={s?.halfYearly[0]?.current_open ? s?.halfYearly[0]?.current_open : s?.halfYearly[0]?.ticker_open}
                                                    high={s?.halfYearly[0]?.current_high >= s?.halfYearly[0]?.ticker_high ? s?.halfYearly[0]?.current_high : s?.halfYearly[0]?.ticker_high}
                                                    low={s?.halfYearly[0]?.current_low >= s?.halfYearly[0]?.ticker_low ? s?.halfYearly[0]?.ticker_low : s?.halfYearly[0]?.current_low == 0 ? s?.halfYearly[0]?.ticker_low : s?.halfYearly[0]?.current_low}
                                                    close={s?.halfYearly[0]?.current_close} poscount={s?.halfYearly[0]?.poscount} negcount={s?.halfYearly[0]?.negcount} arrow={s?.halfYearly[0]?.arrow} totalMetrics={s?.halfYearly[0]?.totalMetrics} prev_range={s?.halfYearly[0]?.pre_percentage}
                                                />
                                            </Grid>
                                            <Grid className='im-ohlc flex w-full'>
                                                <Grid className="im-lc w-1/3">
                                                    <Typography align="center" className="high-color">C.R: {((s?.halfYearly[0]?.current_high >= s?.halfYearly[0]?.ticker_high ? s?.halfYearly[0]?.current_high : s?.halfYearly[0]?.ticker_high) - (s?.halfYearly[0]?.current_low >= s?.halfYearly[0]?.ticker_low ? s?.halfYearly[0]?.ticker_low : s?.halfYearly[0]?.current_low == 0 ? s?.halfYearly[0]?.ticker_low : s?.halfYearly[0]?.current_low))?.toFixed(1)}</Typography>
                                                    <Typography align="center" className={(s?.halfYearly[0]?.pre_high - s?.halfYearly[0]?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.halfYearly[0]?.pre_low) ? (s?.halfYearly[0]?.pre_high - s?.halfYearly[0]?.pre_low)?.toFixed(1) : 0}</Typography>
                                                </Grid>
                                                <Grid className="im-oh w-1/3">
                                                    <Typography align="center" className="high-color">O: {s?.halfYearly[0]?.current_open == 0 ? s?.halfYearly[0]?.ticker_open : s?.halfYearly[0]?.current_open?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="high-color">P.C: {s?.halfYearly[0]?.pre_close?.toFixed(1)}</Typography>
                                                </Grid>
                                                <Grid className="im-rp w-1/3">
                                                    <Typography align="center" className="high-color">H: {s?.halfYearly[0]?.current_high >= s?.halfYearly[0]?.ticker_high ? s?.halfYearly[0]?.current_high?.toFixed(1) : s?.halfYearly[0]?.ticker_high?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="low-color">L: {s?.halfYearly[0]?.current_low >= s?.halfYearly[0]?.ticker_low ? s?.halfYearly[0]?.ticker_low?.toFixed(1) : s?.halfYearly[0]?.current_low == "0" ? s?.halfYearly[0]?.ticker_low?.toFixed(1) : s?.halfYearly[0]?.current_low?.toFixed(1)} </Typography>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                        <Grid className='indice-candle-nsePlusePluse' style={{ backgroundColor: 'lightblue' }}>
                                            <Grid className="flex justify-center -mb-1">
                                                <Typography variant='body2' className="font-black font-futura flex whitespace-no-wrap" align="center" style={{ fontSize: '12px', color: `#000066` }}>(Y) - {companySymbol}
                                                    <span className="flex items-center" style={{ fontSize: '12px', marginLeft: '3px' }}>{s?.yearly[0]?.currentprice}</span>
                                                    <span className="flex items-center" style={{ fontSize: '10px' }}>({(s?.yearly[0]?.currentprice - s?.yearly[0]?.pre_close).toFixed(1)})({getPerc(s?.yearly[0]?.currentprice, s?.yearly[0]?.pre_close)}%)</span>
                                                </Typography>
                                            </Grid>
                                            <Grid  style={{ marginTop: '4px' }}>
                                                <MetricCandleStick open={s?.yearly[0]?.current_open ? s?.yearly[0]?.current_open : s?.yearly[0]?.ticker_open}
                                                    high={s?.yearly[0]?.current_high >= s?.yearly[0]?.ticker_high ? s?.yearly[0]?.current_high : s?.yearly[0]?.ticker_high}
                                                    low={s?.yearly[0]?.current_low >= s?.yearly[0]?.ticker_low ? s?.yearly[0]?.ticker_low : s?.yearly[0]?.current_low == 0 ? s?.yearly[0]?.ticker_low : s?.yearly[0]?.current_low}
                                                    close={s?.yearly[0]?.current_close} poscount={s?.yearly[0]?.poscount} negcount={s?.yearly[0]?.negcount} arrow={s?.yearly[0]?.arrow} totalMetrics={s?.yearly[0]?.totalMetrics} prev_range={s?.yearly[0]?.pre_percentage}
                                                />
                                            </Grid>
                                            <Grid className='im-ohlc flex w-full'>
                                                <Grid className="im-lc w-1/3">
                                                    <Typography align="center" className="high-color">C.R: {((s?.yearly[0]?.current_high >= s?.yearly[0]?.ticker_high ? s?.yearly[0]?.current_high : s?.yearly[0]?.ticker_high) - (s?.yearly[0]?.current_low >= s?.yearly[0]?.ticker_low ? s?.yearly[0]?.ticker_low : s?.yearly[0]?.current_low == 0 ? s?.yearly[0]?.ticker_low : s?.yearly[0]?.current_low))?.toFixed(1)}</Typography>
                                                    <Typography align="center" className={(s?.yearly[0]?.pre_high - s?.yearly[0]?.pre_low) > 0 ? "high-color" : "low-color"}>P.R: {Number(s?.yearly[0]?.pre_low) ? (s?.yearly[0]?.pre_high - s?.yearly[0]?.pre_low)?.toFixed(1) : 0}</Typography>
                                                </Grid>
                                                <Grid className="im-oh w-1/3">
                                                    <Typography align="center" className="high-color">O: {s?.yearly[0]?.current_open == 0 ? s?.yearly[0]?.ticker_open : s?.yearly[0]?.current_open?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="high-color">P.C: {s?.yearly[0]?.pre_close?.toFixed(1)}</Typography>
                                                </Grid>
                                                <Grid className="im-rp w-1/3">
                                                    <Typography align="center" className="high-color">H: {s?.yearly[0]?.current_high >= s?.yearly[0]?.ticker_high ? s?.yearly[0]?.current_high?.toFixed(1) : s?.yearly[0]?.ticker_high?.toFixed(1)}</Typography>
                                                    <Typography align="center" className="low-color">L: {s?.yearly[0]?.current_low >= s?.yearly[0]?.ticker_low ? s?.yearly[0]?.ticker_low?.toFixed(1) : s?.yearly[0]?.current_low == "0" ? s?.yearly[0]?.ticker_low?.toFixed(1) : s?.yearly[0]?.current_low?.toFixed(1)} </Typography>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )
                }) : <Typography align='center' className='font-semibold font-futura'>Loading...</Typography>}
            </Grid>
        </>
    )
}

export default GraphView