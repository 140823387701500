import React, { useState } from 'react';
import service from '../../assets/images/service1.png'
import marketAnalysis from '../../assets/images/marketAnalysis.png'
import research from '../../assets/images/research.png'
import './ServicePage.css'; // Create this CSS file to style your component
import SiteFooter from './SiteFooter';
import LandingHeader from './header';
import QR from "../../assets/images/Qrcode.jpeg"

const ServicePage = () => {
  const [isHidden, setIsHidden] = useState(false);
  return (
    <div >
      <LandingHeader />
      <div className="sp-container main-width" style={{ position: 'absolute', top: '68px',}}>
        {/* Header Section */}
        <div className="header-container">
          <div className="header-text">
            <h2>
              From <span className="highlight">our experts</span> for <span className="highlight">your expert</span> journey in navigating the stock markets.
            </h2>
          </div>
          <div className="header-image">
            {/* Replace 'chart-image.jpg' with the actual path to your image */}
            <img src={service} alt="Stock Market Chart" />
          </div>
        </div>

        {/* Market Analysis Section */}
        <div className="section">
          <div className="image-section">

            <img src={marketAnalysis} alt="Market Analysis" />
          </div>
          <div className="text-section">
            <h3>Market Analysis</h3>
            <p>An in-depth analysis of stock markets with the trends, industry insights and financial news, from our team of dedicated and experienced Market Analysts and Researchers.</p>
          </div>
        </div>

        {/* Research Reports Section */}
        <div className="section">
          <div className="text-section">
            <h3>Research Reports</h3>
            <p>Comprehensive research reports based on historical as well as real-time data, detailing market performances and risk/return profiles while highlighting your potential investing opportunities.</p>
          </div>
          <div className="image-section">
            <img src={research} alt="Research Reports" />
          </div>
        </div>

        {/* Trading Journal Section */}
        <div className="trading-journal">
          <h3>Trading Journal</h3>
          <div className="journal-cards">
            {/* Add your trading strategy cards here */}
            <div className="j-card">
              <h4>Camarilla</h4>
              <p>Camarilla Pivot Points are advanced tools that identify hidden support and resistance levels, offering precise calculations for key price levels. They help traders find optimal entry and exit points, making them essential for day trading and planning profitable trades.</p>
            </div>
            <div className="j-card">
              <h4>Standard</h4>
              <p>Pivot points are key for intraday trading, helping traders gauge potential price levels, support, and resistance. They use past values to assess volatility and set targets, with timeframes like DAY, WEEK, MONTH, and 12-MONTH for different trading needs.</p>
            </div>
            <div className="j-card">
              <h4>Fibonacci</h4>
              <p>Fibonacci numbers, discovered by Leonardo Fibonacci, predict price retracements and extensions. When paired with Pivot Points, Fibonacci ratios (0.382, 0.500, 0.618, 1.000) identify key support and resistance levels, creating confluence zones that improve the likelihood of profitable trades.</p>
            </div>
            <div className="j-card">
              <h4>Zones</h4>
              <p>In market analysis, time zones are divided into +2 to -2, +1 to -1, and zero (CPR). Markets often trade within +1 to -1, reflecting moderate volatility. During high volatility, they can reach +2 to -2, with +2 and +1 as resistance, and -2 and -1 as support. This helps predict market behavior based on volatility and patterns..</p>
            </div>
            <div className="j-card">
              <h4>Camarilla Pivot Points</h4>
              <p>A bullish signal occurs if the CMP is above the TC, and a bearish signal if the CMP is below the BC. Support and resistance are calculated to determine the stock's price range, and tomorrow's CPR will be influenced by today's levels.</p>
            </div>
            <div className="j-card">
              <h4>Retracement and Extension</h4>
              <p>The Fibonacci sequence (0, 1, 1, 2, 3, 5, 8, 13) is key in technical analysis. The golden ratio (about 1:1.61) helps estimate price movements and reversals. Fibonacci ratios (38.2%, 50%, 62.8%) guide traders in identifying retracement levels, extensions, and key support and resistance points.</p>
            </div>
          </div>
        </div>
        <SiteFooter />
        <div>
                        {!isHidden && (
                            <div className="qr-wrapper">
                                <img src={QR} alt="QR Code" className="qr-code" draggable="false"/>
                                <button className="close-btn" onClick={() => setIsHidden(true)}>&times;</button>
                            </div>
                        )}

                        {isHidden && (
                            <button className="slider-btn" onClick={() => setIsHidden(false)}>
                                Show QR Code
                            </button>
                        )}
                    </div>
      </div>

    </div>
  );
};

export default ServicePage;
