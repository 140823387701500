import React from 'react';
import './BestCard.css'

const BestCard = ({ imageUrl, title, description }) => {
  return (
    // <div>
    //   <article className="card">
    //     <header className="card__thumb">
    //       <a href="#">
    //         <img
    //           src="https://images.unsplash.com/photo-1501443762994-82bd5dace89a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
    //           alt="Ice cream sundae"
    //         />
    //       </a>
    //     </header>
    //     <div className="card__body">

    //       <h2 className="card__title">
    //         <a href="#">an ice cream sundae party！</a>
    //       </h2>
    //       <div className="card__subtitle">an ice cream sundae party！</div>
    //       <p className="card__description">
    //         5. Pour the mixture into a non-stick container and then freeze
    //         overnight. Take treating your dog a step further by turning it into
    //         an ice cream sundae party!
    //       </p>
    //     </div>        
    //   </article>
    // </div>
    <div className="card">
      <header className="card__thumb">
        <a href="#">
          <img src={imageUrl} alt={title} />
        </a>
      </header>
      <div className="card__body">
        <h2 className="card__title">
          <a href="#">{title}</a>
        </h2>
        <div className="card__subtitle">{title}</div>
        <p className="card__description">{description}</p>
      </div>
    </div>
  );
};

export default BestCard;
