import { useState, useEffect } from "react";

export const useCustomForm = (initialFormValues, callback, validate) => {
    const [values, setValues] = useState(initialFormValues);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    const handleSubmit = (event) => {
        setErrors(validate(values));
        setIsSubmitting(true);
    };

    useEffect(() => {
        if (Object.keys(errors).length && Object.keys(errors).every(key => !errors[key]['message']) && isSubmitting) {
            callback();
        }
    }, [errors]);

    return {
        handleChange,
        handleSubmit,
        values,
        errors,
        setValues,
        setErrors,
        setIsSubmitting
    };
};

