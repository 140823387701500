import { Grid } from "@material-ui/core";
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FAQimage } from "../icons/AppIcon";
import './FAQs.css'

export const FAQSections = (props = {}) => {

  const FAQS = [{
    heading: '1. What is Tradabulls.com?',
    content: ' Tradabulls.com is a website that provides stock market research and analysis to help its users make informed decisions about the stock market. We provide both fundamental and technical analysis to help our users make informed decision.',
    disabled: false
  }, {
    heading: '2. What services does Tradabulls.com provide?',
    content: 'Tradabulls.com provides both fundamental and technical analysis of the stock markets. We also provide tools and resources such as stock screener, portfolio manager, and watchlist to help our users make the most of their investments. Additionally, we provide real-time market data and news to keep our users up-to-date on the latest stock market information.',
    disabled: false
  }, {
    heading: '3.  Where is Tradabulls.com located?',
    content: ' Tradabulls.com is located in Hyderabad, Telangana, India.',
    disabled: false
  }]
  return (

    <Grid className="faq-container" >
      <Grid className="img-container"><FAQimage /></Grid>
      <SimpleAccordion accordionData={FAQS} />
    </Grid>
  )
}



const useStyles = makeStyles((theme) => ({
  root: {
    width: '70%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function SimpleAccordion(props = {}) {
  const accordionData = props.accordionData ?? [];

  const classes = useStyles();

  return (
    // <div className={classes.root} >
    <div className='faq-accordians'>
      <div className="faq-info">
        <span style={{}}>FAQ's</span>
        <span style={{ color: 'rgba(38, 180, 75, 1)', fontSize: '20px', fontWeight: "bolder" }}>Looking For Something Else?</span>
        <span style={{}}>Here are some of the most-requested sources for information.</span>
      </div>
      <div style={{display:"grid"}}>
        {accordionData.map((data, index) => {
          return <Accordion key={index} disabled={data.disabled} className="main-accordian">
            <AccordionSummary
            className="accordian-summary"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`panel${index}a-header`}
            >
              <Typography className={classes.heading}>{data.heading}</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordian-details">
              <Typography style={{fontSize:'13px'}}>
                {data.content}
              </Typography>
            </AccordionDetails>
          </Accordion>;

        })}
      </div>
    </div>
  );
}
